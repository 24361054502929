var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.chave,"size":"xl","title":("Editando | " + _vm.chave),"no-close-on-backdrop":"","scrollable":true,"busy":"busy"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary","size":"md"},on:{"click":_vm.save}},[_vm._v(" OK ")])]},proxy:true}])},[_c('b-row',[_c('ClicToolbar',{attrs:{"cp":_vm.getToolbarProperties,"idTela":"clicCodeStudio"},on:{"clicCodeStudioSaveValue":_vm.save}}),_c('ClicCol',{attrs:{"cp":{
                id:'reparticaoScript',
                props:{
                    md:'12',
                    style:'padding: 0; height: calc(100vh - 35px); width: 100%;'
                }
            },"idTela":"clicCodeStudio","childrens":_vm.getCodeEditorProperties}}),_c('ClicCol',{attrs:{"cp":{
                id:'reparticaoPresets',
                props:{
                    md:'3',
                    style:'padding: 0; height: 100vh; width: 100%;'
                }
            },"idTela":"clicCodeStudio","childrens":_vm.getTreeViewProperties}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }