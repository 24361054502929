import ClicColInterno from '../../clicGridLayout/ClicColInternoModel'
import ClicIconInterno from '../../clicIcon/ClicIconInternoModel';
import ClicHintInterno from '../../clicHint/ClicHintInternoModel';

import ClicInputCurrencyMaskDocs from './ClicInputCurrencyMaskDocs';

export default {
    ClicInputCurrencyMask : {
        config      : {icon: "md-currencyexchange-twotone", documentation: ClicInputCurrencyMaskDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            label                   : { type: 'string',     default: undefined, component: { name : 'input'  } },
            required                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            fieldState              : { type: 'boolean',    default: undefined, component: { name : 'switch'} },
            jsonId                  : { type: 'string',     default: undefined, component: { name : 'input' } },
            name                    : { type: 'string',     default: undefined, component: { name : 'input'  } },
            readonly                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            autocomplete            : { type: 'string',     default: 'off',     component: { name : 'input'  } },
            invalidFeedbackMessage  : { type: 'string',     default: undefined, component: { name : 'input'  } },
            validateScript          : { type: 'string',     default: undefined, component: { name : 'code'   } },
            style                   : { type: 'string',     default: undefined, component: { name : 'input'  } },
            
            disabled                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            value                   : { type: 'multiple',   default: undefined, component: { name : 'input'  } },
            maskOptions             : { type: 'string',     default: {"decimal":",","thousands":".","prefix":"R$ ","suffix":"","precision":2,"masked":false},        
                                        component: { name : 'code'   } 
                                    },
            
            ...ClicColInterno,
            ...ClicIconInterno,
            ...ClicHintInterno,

        },
        events : {
            blur    : {type: 'string', component: { name : 'code' } },
            change  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
            keyup   : {type: 'string', component: { name : 'code' } },
        },
    }
}
