<template>
  <div style="display: contents;">
    <component 
      v-for="children in childrens || []" 
      :key="$utils.getComponentID(children.id)"
      v-bind:is="children.component" 
      :childrens="children.childrens || []"
      :cp="children"
      :parent="parent"
      :idTela="idTela"
      :state="state"
    >
    </component>
  </div>
</template>

<script>

  export default {
    name: 'MotorS',

    props: {
      cp: Object,
      childrens: Array,
      parent: Object,
      state: Object,
      idTela: String
    },

    created() {
      if  (this.cp && Object.keys(this.cp).length && this.idTela) {
        this.register();
        /*
        this.$store.commit(`${this.idTela}/setPropriedades`, {[`${this.$utils.getComponentID(this.cp.name || this.cp.id)}`]: {
            ...this.cp
          }
        });
        */
      }
    },

    mounted() {
      if  (this.cp && this.cp.events && this.cp.events.mounted){
        eval(this.cp.events.mounted);
      }
    },

    methods: {
      
      register() {
        //this.$emit("register", this);
        if (!this.$store.hasModule(this.$route.name)){
          this.$store.registerModule(`${this.$route.name}`, {
            namespaced: true,
            state(cp) {
              return {
                propriedades: {},
              }
            },
            getters: {
              getPropriedades(state) {
                return state.propriedades
              },
            },
            mutations: {
              setPropriedades(state, propriedades) {
                if(propriedades){
                  state.propriedades = {...state.propriedades, ...propriedades};
                }
              },
            },
          })
        }
      }

    }
  };
</script>