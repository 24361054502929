import ClicCollapseDocs from './ClicCollapseDocs'
import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';
import ClicIconInterno from '../clicIcon/ClicIconInternoModel';
import ClicBadgeInterno from '../clicBadge/ClicBadgeInternoModel';

export default {
    ClicCollapse : {
        config      : {icon:"md-insertchart-twotone", isContainer: true, documentation: ClicCollapseDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            startOpenedContainer    : { type: 'boolean',     default: false,         component: { name : 'switch' } },
            // tagLabel                : { type: 'string',      default: "h5",          component: { name : 'input' } },
            label                   : { type: 'string',      default: undefined,     component: { name : 'input' } },
            subLabel                : { type: 'string',      default: undefined,     component: { name : 'input' } },
            disabled                : { type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            variant                 : { type: 'string',      default: undefined,     component: { name : 'select', values: 
                ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'] }
            },
            // variantOnOpen          : { type: 'string',      default: undefined,     component: { name : 'select', values: 
            //     ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'] }
            // },
            ...ClicBadgeInterno,
            ...ClicIconInterno,
            ...ClicColInterno,
            ...ClicHintInterno
        },
        events : {
            click           : {type: 'string', component: { name : 'code' } },
            clickPromise    : {type: 'string', component: { name : 'code' } },
        }
    }
}