import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicCalendarDocs from './ClicCalendarDocs';

export default {
    ClicCalendar : {
        config      : {isContainer: true, icon:"md-calendarmonth-twotone", documentation: ClicCalendarDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            
            hideCalendar                : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            showList                    : {type: 'boolean',     default: true,          component: { name : 'switch' } },
            mdColumnList                : {type: 'number',      default: 4,             component: { name : 'input' } },
            showModalCalendar           : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            showEventIcon               : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            situations                  : {type: 'array',       default: [{ value: 'GANHO', text: 'Ganho', icon: "md-thumbupoffalt" }, { value: 'PERDA', text: 'Perda', icon: "md-thumbdownoffalt" }, { value: 'PENDENTE', text: 'Pendente', icon: "md-historytoggleoff" }, { value: 'CANCELADO', text: 'Cancelado', icon: "md-cancel-outlined" }, { value: 'CONCLUIDO', text: 'Concluido', icon: "md-clouddone" }],     component: { name : 'code' } },
            situationsOfConcludedEvents : {type: 'array',       default: ["CANCELADO","GANHO","PERDA","CONCLUIDO"],     component: { name : 'code' } },
            reasonsForLoss              : {type: 'array',       default: [{ value: 'CNPJ_BAIXADO', text: 'CNPJ Baixado' }, { value: 'PRECO', text: 'Preço' }, { value: 'PRAZO_ENTREGA', text: 'Prazo de entrega' }, { value: 'QUALIDADE', text: 'Qualidade do produto' }, { value: 'FORNECEDOR', text: 'Trocou de fornecedor' }, { value: 'NAO_ATENDE', text: 'Cliente não atende' }],     component: { name : 'code' } },
            registerPerPageList         : {type: 'number',      default: 5,             component: { name : 'input' } },
            activeView                  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            allDayBarHeight             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            cellClickHold               : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            cellContextmenu             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            clickToNavigate             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dblclickToNavigate          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            disableDatePrototypes       : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            disableDays                 : {type: 'array',       default: undefined,     component: { name : 'input' } },
            disableViews                : {type: 'array',       default: ['years', 'year'],     component: { name : 'input' } },
            dragToCreateEvent           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dragToCreateThreshold       : {type: 'number',      default: undefined,     component: { name : 'input' } },
            // editableEvents           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            // events                   : {type: 'array',       default: undefined,     component: { name : 'code' } },
            eventsCountOnYearView       : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            eventsOnMonthView           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            hideBody                    : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            hideTitleBar                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            hideViewSelector            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            hideWeekdays                : {type: 'array',       default: undefined,     component: { name : 'input' } },
            hideWeekends                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            locale                      : {type: 'string',      default: undefined,     component: { name : 'input' } },
            maxDate                     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            minCellWidth                : {type: 'number',      default: undefined,     component: { name : 'input' } },
            minDate                     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            minEventWidth               : {type: 'number',      default: undefined,     component: { name : 'input' } },
            minSplitWidth               : {type: 'number',      default: undefined,     component: { name : 'input' } },
            overlapsPerTimeStep         : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },  
            resizeX                     : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            selectedDate                : {type: 'string',      default: undefined,     component: { name : 'input' } },
            showAllDayEvents            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            showTimeInCells             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            showWeekNumbers             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            small                       : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            snapToTime                  : {type: 'number',      default: undefined,     component: { name : 'input' } },
            specialHours                : {type: 'string',      default: undefined,     component: { name : 'input' } },
            splitDays                   : {type: 'array',       default: undefined,     component: { name : 'input' } },
            startWeekOnSunday           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            stickySplitLabels           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            time                        : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            timeCellHeight              : {type: 'number',      default: undefined,     component: { name : 'input' } },
            timeFormat                  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            timeFrom                    : {type: 'number',      default: undefined,     component: { name : 'input' } },
            timeStep                    : {type: 'number',      default: undefined,     component: { name : 'input' } },
            timeTo                      : {type: 'number',      default: undefined,     component: { name : 'input' } },
            todayButton                 : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            transitions                 : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            twelveHour                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            xsmall                      : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            watchRealTime               : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },

            ...ClicColInterno,

        },
        events : {
            onEventClick       : {type: 'string', component: { name : 'code' } },
            onEventCreate      : {type: 'string', component: { name : 'code' } },
            onEventDblclick    : {type: 'string', component: { name : 'code' } },
            onEventDragCreate  : {type: 'string', component: { name : 'code' } },
            onEventDelete      : {type: 'string', component: { name : 'code' } },
            onEventFocus       : {type: 'string', component: { name : 'code' } },
        }
    }
}









