<template>
    <!-- Torna o assistente visível ou não com base :
        na rota (showAssistant definido no array), 
        no tenant (tenantAssistantVisibility
        e na situação do usuário (userSituation)
    -->
    <div v-show="showAssistant && tenantAssistantVisibility && userSituation == 'A'" class="floating-icon-copilot">

        <!-- Avatar -->
        <b-button v-b-toggle.sidebar-right class="button-copilot">
            <img class="custom-icon-copilot" :src='assistantExpressions(selectedExpression)' alt="Chat Icon">
        </b-button>

        <!-- Chat -->
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow>
            <template #default="{ hide }">
                <div class=" py-2" style="overflow-y: hidden;background-color: white;">
                    <div class="chat-container">
                        <div class="button-back-chat-container">
                            <div style="display: flex;">
                                <img class="custom-icon-copilot-chat" :src='assistantExpressions("gestoMaosParaBaixo")'
                                    alt="Chat Icon">
                                <div class="container-titles">
                                    <p class="title-chat">Clara</p>
                                    <p class="status-chat">Online</p>
                                </div>
                            </div>
                            <div>
                                <b-button class="button-help-chat" block @click="newSystemMessage(helpMessage)">
                                    <ClicIconInterno iconName="fa-regular-question-circle"></ClicIconInterno>
                                </b-button>
                                <b-button class="button-back-chat" block @click="hide">
                                    <ClicIconInterno iconName="fa-times"></ClicIconInterno>
                                </b-button>
                            </div>
                        </div>
                        <div class="message-list" id="message-list" v-on:scroll.passive="checkScroll()"
                            @touchmove="checkScroll()">
                            <div class="button-more-messages-container">
                                <button class="button-more-messages"
                                    @click="buscarMensagens({ skip: (returnedMessages.length) })">
                                    + Mais mensagens
                                </button>
                            </div>
                            <div v-for="(message, index) in chatMessages" :key="index" class="message"
                                :class="message.sender">
                                <div v-if="message.sender === 'date'">
                                    <div class="message-new-date-container">
                                        <div class="message-new-date">{{ message.message }}</div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-html="message.message"></div>
                                    <div class="message-date">
                                        {{ message.date }}
                                    </div>
                                </div>
                            </div>

                            <div class="message" :class="'system'" v-show="showTextingMessage" id="pulsate">
                                <div>
                                    <ClicIconInterno iconName="fa-spinner" />Digitando...
                                </div>
                            </div>


                        </div>

                        <div class="message-input">
                            <input v-model="newMessage" @keyup.enter="newChatMessage(newMessage, 'user')"
                                placeholder="Digite sua mensgem" />
                            <b-button class="button-send-message" @click="newChatMessage(newMessage, 'user')">
                                <ClicIconInterno style="color:#a4a3a3" iconName="bi-send-fill" />
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-sidebar>

        <!-- PopUp  -->
        <div v-if="showPopup" class="popUp-container">
            <div class="popUp">
                <button @click="showPopup = false" class="close-button">
                    <i class="fa fa-times"></i>
                </button>
                <div v-html="message"></div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import bubbleSound from '../../assets/theme/sounds/bubble.mp3';
import insights from './insights';
import assistantExpressions from './assistantExpressions';

export default {
    name: 'ClicCopilot',
    components: {},
    data() {
        return {
            insightsList: insights,

            tenantAssistantVisibility: false,
            showTextingMessage: false,
            showTestInsights: false,
            showAssistant: false,
            userSituation: false,
            showPopup: false,

            selectedExpression: 'normal',
            helpMessage: '',
            newMessage: '',
            message: '',
            userId: '',
            locale: '',

            assistantRoutesIds: [],
            returnedMessages: [],
            externalInsights: [],
            chatMessages: [],

            subdomain: this.$host.subdominio,

        };
    },
    mounted() {
        Vue.prototype.$copilot = this;
        this.initialize();
    },
    watch: {
        '$route'(to, from) {
            if (this.assistantRoutesIds.includes(to.name) || this.assistantRoutesIds.includes(to.path)) {
                this.showAssistant = true;
            } else {
                this.showAssistant = false;
            }
        }
    },
    computed: {},
    methods: {
        getLocale() {
            const frameLanguage = this.$storage.getIdioma().toLowerCase();
            switch (frameLanguage) {
                case "pt": return "pt-br";
                default: return frameLanguage
            }
        },
        getTimezone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },

        async initialize() {
            this.locale = this.getLocale()
            this.timeZone = this.getTimezone()
            this.verifyUserSituation();
            await this.getSubdomainPreferences();

            if (this.showTestInsights) { this.initializeDemonstrationMode() }
        },


        verifyUserSituation() {
            let userObj = this.$storage.getUserObj();
            this.userId = userObj?.id

            if (userObj.situacaoClara) {
                this.userSituation = userObj.situacaoClara;
                if (this.userSituation == "A") { this.buscarMensagens({ skip: this.returnedMessages.length }) }

            } else {
                setTimeout(() => { this.verifyUserSituation() }, 1000);
            }
        },

        async getSubdomainPreferences() {
            var options = {
                method: 'GET',
                url: `${process.env.VUE_APP_BACKEND_URL}/api/configuracaocopilot`,
                headers: { "content-type": "application/json" },
                params: { subdominio: this.subdomain }
            };

            try {
                let response = await axios.request(options);
                let { assistantRoutesIds = [], externalInsights = [], showTestInsights = false, tenantAssistantVisibility = false, helpMessage } = response.data.dados;

                this.assistantRoutesIds = assistantRoutesIds;
                this.externalInsights = externalInsights;
                this.showTestInsights = showTestInsights;
                this.tenantAssistantVisibility = tenantAssistantVisibility;
                this.helpMessage = helpMessage;

                if (assistantRoutesIds.includes(this.$route.path)) { this.showAssistant = true }

                return response

            } catch (e) {
                return { error: e }
            }
        },


        assistantExpressions(expression) {
            let newAssistantIcon = assistantExpressions[expression];

            if (newAssistantIcon) { return newAssistantIcon }

            return assistantExpressions["normal"];
        },


        newPopUp(message, sendMessage = true, expression = "feliz", time = 20000) {
            this.showPopup = true;
            this.message = message;
            this.setAssistantExpression(expression, time);

            if (sendMessage) { this.newChatMessage(message, 'system') }
        },

        closePopUp() {
            this.showPopup = false;
        },

        newChatMessage(message, sender) {
            if (message != "") {
                this.playMessageSound();

                let body = {
                    message: message,
                    language: this.$storage.getIdioma(),
                    date: new Date(),
                    sender: sender
                }

                this.processMessage(body);
                this.returnedMessages.push(body);
                this.reloadChatMessages();
                this.newMessage = "";
            }
        },

        newSystemMessage(messages) {

            if (!Array.isArray(messages)) {
                messages = [messages];
            }

            for (let message of messages) {
                let body = {
                    message: message,
                    sender: "system",
                    date: new Date()
                }
                this.returnedMessages.push(body)
            }

            this.playMessageSound();
            this.reloadChatMessages();

            let objDiv = document.getElementById("message-list");
            objDiv.scrollTop = objDiv.scrollHeight;
        },


        reloadChatMessages(messages = []) {

            const formatHour = (date) => {
                if (date) {
                    date = new Date(date).toLocaleString(this.locale, { timeZone: this.timeZone }).split(',')[1].slice(0, -3)
                }
                return date
            }

            const formatDate = (date) => {
                if (date) {
                    date = new Date(date).toLocaleString(this.locale, { timeZone: this.timeZone }).split(',')[0]
                }
                return date
            }

            const formatDateMessage = (messageDate) => {
                const [day, month, year] = messageDate.split("/");
                const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
                return ({ message: `${day} de  ${monthNames[parseInt(month) - 1]}, ${year}`, sender: "date" });
            }


            let formattedMessages = []
            let messageDate = "";
            let lastMessageDate = "";

            if (messages.length > 0) {
                messages.map((obj) => { this.returnedMessages.unshift(obj) })
            }

            this.returnedMessages.map((obj, i) => {

                if (i > 0) { lastMessageDate = messageDate }

                messageDate = formatDate(obj.date);

                if (messageDate !== lastMessageDate) {
                    let dateObj = formatDateMessage(messageDate);
                    formattedMessages.push(dateObj);
                }

                formattedMessages.push(obj);
            })

            this.chatMessages = formattedMessages.map((obj) => {
                return { sender: obj.sender, message: obj.message, date: formatHour(obj.date) }
            })
        },


        async buscarMensagens(params) {
            params.subdominio = this.subdomain;
            params.id = this.userId;

            var options = {
                method: 'GET',
                url: `${process.env.VUE_APP_COPILOT_URL}/copilot/mensagens`,
                headers: { "content-type": "application/json" },
                params: params
            };

            try {
                let response = await axios.request(options);
                let responseData = response.data.dados;
                this.reloadChatMessages(responseData)
                return response
            } catch (e) {
                return { error: e }
            }
        },

        async processMessage(body) {

            this.showTextingMessage = true;
            body.subdominio = this.subdomain
            body.id = this.userId

            var options = {
                method: 'POST',
                url: `${process.env.VUE_APP_COPILOT_URL}/copilot/nlp/processarMensagem`,
                headers: { "content-type": "application/json" },
                params: null,
                data: body
            };

            try {
                let response = await axios.request(options);
                let responseData = response.data.dados;

                if (responseData?.reornoMensagensFrontend?.length > 0) {
                    let messagesToAdd = []
                    for (let returnnedMessage of responseData?.reornoMensagensFrontend) {
                        messagesToAdd.push(returnnedMessage.mensagem.message)
                    }
                    this.newSystemMessage(messagesToAdd)
                }

                this.showTextingMessage = false;
                return response
            } catch (e) {
                this.showTextingMessage = false;
                return { error: e }
            }
        },


        checkScroll() {
            var messageList = document.getElementById('message-list');

            if (messageList.scrollTop === 0) {
                const actualSize = messageList.scrollHeight
                this.buscarMensagens({ skip: (this.returnedMessages.length) }).then(() => {
                    messageList.scrollTop = messageList.scrollHeight - actualSize;
                })


            }
        },

        setAssistantExpression(expression, time = 10000) {
            this.selectedExpression = expression;
            setTimeout(() => {
                this.closePopUp()
                this.selectedExpression = "normal";
            }, time);
        },

        playMessageSound() {
            if (this.showAssistant && this.tenantControl) {
                const audio = new Audio(bubbleSound);
                audio.play();
            }
        },

        initializeDemonstrationMode() {
            setTimeout(() => {
                if (this.showTestInsights == true) {

                    let randomInsight = "";

                    if (this.externalInsights.length > 0) {
                        randomInsight = this.externalInsights[Math.floor(Math.random() * this.externalInsights.length)];

                    } else {
                        randomInsight = this.insightsList[Math.floor(Math.random() * this.insightsList.length)];
                    }

                    this.newPopUp(randomInsight, false)
                    const expressionKeys = Object.keys(assistantExpressions);
                    const randomExpressionKey = expressionKeys[Math.floor(Math.random() * expressionKeys.length)];
                    this.setAssistantExpression(randomExpressionKey, 20000)

                }
                this.initializeDemonstrationMode();
            }, Math.floor(Math.random() * (30000 - 20000 + 1)) + 20000);
        },



    }


};

</script>


<style>
.floating-icon-copilot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 997;

    @media screen and (max-width: 1100px) {
        bottom: 10px;
        right: 10px;
    }

    @media screen and (max-width: 400px) {
        bottom: 5px;
        right: 5px;
    }
}

/* Estilos para o ícone personalizado */
.custom-icon-copilot {
    width: 100px;
    height: 100px;
    background-color: transparent !important;
    scale: 1.2;

    @media screen and (max-width: 1100px) {
        width: 60px;
        height: 60px;
    }

    @media screen and (max-width: 400px) {
        width: 50px;
        height: 50px;
    }
}

.custom-icon-copilot-chat {
    height: 50px;
    margin: 2px;
    border-radius: 25px;
    background-color: #f2f2f2;
}

.title-chat {
    color: white;
    margin-bottom: -7px;
    font-size: larger;
}

.status-chat {
    color: #e4e4e4e8;
}

.container-titles {
    margin-left: 5px;
    align-content: center;
}

.button-copilot {
    background: linear-gradient(to right, #ffa23d, #ff6000e8);
    border: 6px solid white !important;
    padding: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 2px 45px;
    border-radius: 50% !important;
    scale: 0.9;

}

.popUp-container {
    position: fixed;
    bottom: 110px;
    right: 120px;
    z-index: 998;

    @media screen and (max-width: 1100px) {
        bottom: 50px;
        right: 80px;
    }

    @media screen and (max-width: 400px) {
        bottom: 40px;
        right: 55px;
    }
}

.popUp {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px 10px 0px 10px;
    max-width: 300px;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 2px 45px;

    @media screen and (max-width: 400px) {
        padding: 7.5px;

    }
}

.close-button {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ffffff;
    border: none;
    z-index: 999;

    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1px 2px 21px
}

.close-button i {
    color: #888;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: end;
}

.message-list {
    height: auto;
    min-height: 77%;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.button-more-messages-container {
    display: flex;
    justify-content: center;
    margin: 0px 80px;
}

.button-more-messages {
    /* display: none; Remova essa linha para exibir o botão */
    background-color: #f0f0f0;
    border: none;
    font-size: smaller;
    color: #a0a0a0;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
}

.message {
    padding: 8px;
    margin: 4px 0;
    border-radius: 4px;
}

.message.user {
    color: white;
    background-color: var(--cor-principal);
    border-radius: 10px 20px 0px 10px;
    margin: 4px;
    margin-bottom: 20px;
    width: max-content;
    min-width: 50%;
    max-width: 90%;
    line-break: auto;
    padding: 10px;
    margin-left: auto !important;
    box-shadow: rgba(0, 0, 0, 5%) 1px 2px 50px;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    text-align: start;
}

.message.system {
    color: #ffffff;
    background-color: #898888c4;
    border-radius: 20px 10px 10px 0px;
    margin: 4px;
    margin-bottom: 20px;
    width: -moz-max-content;
    width: max-content;
    min-width: 50%;
    max-width: 90%;
    line-break: auto;
    padding: 10px;
    text-align: start;
    box-shadow: rgb(0 0 0 / 12%) 1px 1px 46px;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
}

#pulsate {
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    25% {
        transform: scale(0.75);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    50% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    75% {
        transform: scale(0.75);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.message-date {
    font-size: smaller;
    justify-content: end;
    display: flex;
    color: #fffefec4;
}

.message-new-date {
    justify-content: center;
    display: flex;
    background-color: #f6f6f6;
    border-radius: 25px;
    margin: 15px 0px 10px 0px;
    font-size: smaller;
    color: #a5a4a2;
    width: max-content;
    padding: 10px 20px 10px 20px;
}

.message-new-date-container {
    display: flex;
    justify-content: center;
}


.message-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 7px 0px 5px;
}

.message-input input {
    flex: 1;
    margin-right: 10px;
    border-radius: 15px;
    border: none;
    padding: 8px;
    height: 6vh;
    box-shadow: rgba(0, 0, 0, 0.15) 39px 24px 60px;
}

.button-send-message {
    z-index: 999 !important;
    border-radius: 50% !important;
    height: 40px !important;
    width: 40px !important;
    background-color: white !important;
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.15) 1px 2px 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-back-chat {
    background-color: transparent !important;
    border: none !important;
    width: auto !important;
    border-radius: 50% !important;
    padding: 0px !important;
    z-index: 1000;
    margin-left: 5px;
    margin-bottom: 7px;
}


.button-help-chat {
    background-color: transparent !important;
    border: none !important;
    width: auto !important;
    border-radius: 50% !important;
    padding: 0px !important;
    z-index: 1000;
    margin-left: 5px;
}

.button-back-chat-container {
    margin-top: 30px;
    height: auto;
    background-color: var(--cor-principal);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.4) 3px 1px 31px;
}

#sidebar-right {
    overflow-y: hidden !important;
}

.b-sidebar-body {
    overflow-y: hidden !important;
    justify-content: end;
    display: flex;

}
</style>
