import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';

import ClicDatePickerDocs from './ClicDatePickerDocs';

export default {
    ClicDatePicker : {
        config      : {icon:"md-calendarmonth-outlined",  documentation: ClicDatePickerDocs.documentation }, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            label                   : { type: 'string',     default: undefined, component: { name : 'input' } },
            invalidFeedbackMessage  : { type: 'string',     default: undefined, component: { name : 'input' } },
            validateScript          : { type: 'string',     default: undefined, component: { name : 'code'  } },
            fieldState              : { type: 'boolean',    default: undefined, component: { name : 'switch'} },
            jsonId                  : { type: 'string',     default: undefined, component: { name : 'input' } },

            ariaControls        : {type: 'string',      default: undefined,     component: { name : 'input' } },
            boundary            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            buttonOnly          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            buttonVariant       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            calendarWidth       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            closeButton         : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            closeButtonVariant  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            dark                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dateDisabledFn      : {type: 'function',    default: undefined,     component: { name : 'code' } },
            dateFormatOptions   : {type: 'object',      default: undefined,     component: { name : 'code' } },
            direction           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            disabled            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dropleft            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dropright           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dropup              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            form                : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerTag           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            hideHeader          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            initialDate         : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            max                 : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            menuClass           : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            min                 : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            name                : {type: 'string',      default: undefined,     component: { name : 'input' } }, 
            navButtonVariant    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            noCloseOnSelect     : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            noFlip              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            noHighlightToday    : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            offset              : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            placeholder         : {type: 'string',      default: undefined,     component: { name : 'input' } },
            popperOpts          : {type: 'object',      default: undefined,     component: { name : 'code' } },
            readonly            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            required            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            resetButton         : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            resetButtonVariant  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            resetValue          : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            right               : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },     
            selectedVariant     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            showDecadeNav       : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            size                : {type: 'string',      default: undefined,     component: { name : 'input' } },
            startWeekday        : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            todayButton         : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            todayButtonVariant  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            todayVariant        : {type: 'string',      default: undefined,     component: { name : 'input' } },
            value               : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            valueAsDate         : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            weekdayHeaderFormat : {type: 'string',      default: undefined,     component: { name : 'input' } },

            ...ClicColInterno,
            ...ClicHintInterno   
        },
        events : {
            context : {type: 'string', component: { name : 'code' } },
            hidden  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
            shown   : {type: 'string', component: { name : 'code' } },
        },
    }
}