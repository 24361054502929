import ClicCatalogDocs from './ClicCatalogDocs'

export default {
    ClicCatalog : {
        config      : {icon:"md-insertchart-twotone", isContainer: true, documentation: ClicCatalogDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            cols                    : {type: 'string',  default: undefined, component: { name : 'input' } },
            sm                      : {type: 'string',  default: undefined, component: { name : 'input' } },
            lg                      : {type: 'string',  default: undefined, component: { name : 'input' } },
            md                      : {type: 'string',  default: undefined, component: { name : 'input' } },
            xl                      : {type: 'string',  default: undefined, component: { name : 'input' } },
            configAxiosContent      : {type: 'string',  default: undefined, component: { name : 'code'  } },
            jsonData                : {type: 'array',   default: [],        component: { name : 'code'  } },
            itemsPerPage            : {type: 'number',  default: 10,        component: { name : 'input' } },
            hidePagination          : {type: 'boolean', default: undefined, component: { name : 'switch'} },
            pathItemsCols           : {type: 'string',  default: undefined, component: { name : 'input' } },
            pathItemsColsCounter    : {type: 'string',  default: undefined, component: { name : 'input' } },
            sortBy                  : {type: 'string',  default: undefined, component: { name : 'input' } },
            sortDesc                : {type: 'boolean', default: true,      component: { name : 'switch'} },
            
        },
        events : {
        }
    }
}