<template>
  <ClicColInterno :cp="cp" :key="componentKey" :id="componentKey">
    <template>
      <div>

        <div @click="click" class="mt-1">

          <div class="card bg-default" style="cursor: pointer" pointer>
            <div class="card-header bg-transparent">
              <div class="row align-items-center">

                <div class="col">
                  <h6 class="mb-0" :variant="variant">
                    <ClicIconInterno v-if="iconName" :cp="cp" />
                    {{ $storage.getTextoTraduzido(label) }}
                    <ClicBadgeInterno :cp="cp" />


                  </h6>
                  <h6 v-if="subLabel" class="text-light text-uppercase ls-1 mb-1">{{
    $storage.getTextoTraduzido(subLabel)
  }}</h6>
                </div>
                <div class="col-auto">
                  <ul class="nav nav-pills justify-content-end">
                    <li class="nav-item mr-2 mr-md-0" style="cursor: pointer;">
                      <a target="_self" class="nav-link py-2 px-3">
                        <span class="d-none d-md-block">{{ aberto ? 'Ocultar' : 'Exibir' }}</span>
                        <v-icon :name="aberto ? 'fa-angle-up' : 'fa-angle-down'" scale="1.1" class="ml-2"
                          animation="float" :hover="true" />
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </template>

    <b-collapse :visible="aberto">
      <div class="bg-white p-2 pt-0">
        <MotorS :cp="cp" :childrens="childrens" :idTela="idTela" :state="state" />
      </div>
    </b-collapse>

  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicCollapse',
  mixins: [ComponentMixin],

  data() {
    return {
      public: {
        toggleContainer: this.exibirContainer,
        isOpened: this.verificarContainerAberto
      },

      defaultVariant: "",
      aberto: false

    }
  },


  mounted() {
    if (this.startOpenedContainer) {
      this.exibirContainer();
    };

    this.defaultVariant = this.variant
  },

  methods: {
    exibirContainer() {
      this.aberto = !this.aberto;
    },

    verificarContainerAberto() {
      return this.aberto;
    },

    click(event) {
      this.exibirContainer();


      if (this.lodash.has(this.cp, 'events.click')) {
        try {
          eval(this.cp.events.click);
        } catch (e) {
          console.error(`[ClicCollapse] Falha ao executar evendo 'click'. Detalhes: ${e}`);
        }

      } else if (this.lodash.has(this.cp, 'events.clickPromise')) {
        this.isLoading = true;
        let click = this.cp.events.clickPromise;
        let script = `
          new Promise((resolve, reject) => {
            ${click}
          });
        `;
        let execucao = eval(script);
        execucao.then(() => {
          this.isLoading = false;
        });
      }
    }

  }

};
</script>
