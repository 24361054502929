import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicChartDocs from './ClicChartDocs'

export default {
    ClicChart : {
        config      : {icon:"md-insertchart-twotone", documentation: ClicChartDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            seriesType      : {type: 'string',      default: 'BarSeries',   component: { name: 'select', values: ['BarSeries', 'LineSeries', 'BubbleSeries', 'DoughnutSeries', 'PieSeries', 'PolarAreaSeries', 'RadarSeries', 'ScatterSeries' ]} },

            chartOptions    : {type: 'object',      default: undefined,     component: { name : 'input' } },
            chartData       : {type: 'object',      default: {},            component: { name : 'input' } },
            chartId         : {type: 'string',      default: undefined,     component: { name : 'input' } },
            datasetIdKey    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            plugins         : {type: 'array',       default: undefined,     component: { name : 'input' } },
            cssClasses      : {type: 'string',      default: undefined,     component: { name : 'input' } },
            styles          : {type: 'string',      default: undefined,     component: { name : 'input' } },
            width           : {type: 'string',      default: 10,            component: { name : 'input' } },
            height          : {type: 'string',      default: 10,            component: { name : 'input' } },
            
            ...ClicColInterno   
        },
        events : {
        }
    }
}