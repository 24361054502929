import ClicIframeDocs from "./ClicIframeDocs"

export default {
    ClicIFrame : {
        config      : {icon: "md-insertpagebreak-sharp", documentation: ClicIframeDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            type        : {type: 'string', default: 'iframe',   component: { name : 'input' } },
            aspect      : {type: 'string', default: '4by3',     component: { name : 'input' } },
            src         : {type: 'string', default: '',  component: { name : 'input' } },
            srcMobile   : {type: 'string', default: undefined,  component: { name : 'input' } },

        },
        events : { 
        }
    }
}