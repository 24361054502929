<template>
  <ClicColInterno
    alignSelf="end"
    :cp="cp"
    :key="componentKey"
  >
    <div class="form-group">    
      <div class="form-control-wrap">   

        <div v-if="alignSelf == 'end'">&nbsp;</div>
        
        <b-button
          :active="active"
          :active-class="activeClass"
          :append="append"
          :block="block"
          :disabled="disabled || isLoading"
          :exact="exact"
          :exact-active-class="exactActiveClass"
          :exact-path="exactPath"
          :exact-path-active-class="exactPathActiveClass"
          :href="href"
          :no-prefetch="noPrefetch"
          :pill="pill"
          :prefetch="prefetch"
          :pressed="pressed"
          :rel="rel"
          :replace="replace"
          :router-component-name="routerComponentName"
          :size="size"
          :squared="squared"
          :tag="tag"
          :target="target"
          :to="to"
         
          :variant="variant"
          :class="this.class"
          :style="this.style"
          @click="click"
          ref="button"
        >  
          <template v-if="isLoading">
            <b-spinner small></b-spinner>
            <span class="sr-only"></span>
          </template>

          <ClicImageInterno
            v-if="imageSrc" 
            :cp="cp"
          />

          <ClicIconInterno
            v-if="iconName"
            :cp="cp"
          />

          <p v-if="imageSrc || iconName">&nbsp;</p>

          {{ $storage.getTextoTraduzido(label) }}
        </b-button>
      </div>
    </div>
  </ClicColInterno>
</template>
<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicButton',
  mixins: [ComponentMixin],
  data() {
    return {
      isLoading : false
    };
  },
  
  methods: {
    click(event) {
      if  (this.lodash.has(this.cp, 'events.click')) {
        try {
          eval(this.cp.events.click);
        } catch (e) {
          console.error(`[ClicButton] Falha ao executar evendo 'click'. Detalhes: ${e}`);
        }
        
      } else if (this.lodash.has(this.cp, 'events.clickPromise')) {
        this.isLoading = true;
        let click = this.cp.events.clickPromise;
        let script = `
          new Promise((resolve, reject) => {
            ${click}
          });
        `;
        let execucao = eval(script);
        execucao.then(() => {
          this.isLoading = false;
        });
      }
    }
  },
};
</script>
