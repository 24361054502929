export default {
    documentation : {
        // iconScale        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconAnimation    :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconSpeed        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconHover        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconFlip         :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconFill         :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconLabel        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconTitle        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconInverse      :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconText         :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
    }   
}   