<template>
    <b-modal
        :aria-label="ariaLabel"
        :auto-focus-button="autoFocusButton"
        :body-bg-variant="bodyBgVariant"
        :body-class="bodyClass"
        :body-text-variant="bodyTextVariant"
        :busy="busy"
        :button-size="buttonSize"
        :cancel-disabled="cancelDisabled"
        :cancel-title="$storage.getTextoTraduzido(cancelTitle)"
        :cancel-title-html="cancelTitleHtml"
        :cancel-variant="cancelVariant"
        :centered="centered"
        :content-class="contentClass"
        :dialog-class="dialogClass"
        :footer-bg-variant="footerBgVariant"
        :footer-border-variant="footerBorderVariant"
        :footer-class="footerClass"
        :footer-tag="footerTag"
        :footer-text-variant="footerTextVariant"
        :header-bg-variant="headerBgVariant"
        :header-border-variant="headerBorderVariant"
        :header-class="headerClass"
        :header-close-content="headerCloseContent"
        :header-close-label="headerCloseLabel"
        :header-close-variant="headerCloseVariant"
        :header-tag="headerTag"
        :header-text-variant="headerTextVariant"
        :hide-backdrop="hideBackdrop"
        :hide-footer="hideFooter"
        :hide-header="hideHeader"
        :hide-header-close="hideHeaderClose"
        :ignore-enforce-focus-selector="ignoreEnforceFocusSelector"
        :lazy="lazy"
        :modal-class="modalClass"
        :no-close-on-backdrop="noCloseOnBackdrop"
        :no-close-on-esc="noCloseOnEsc"
        :no-enforce-focus="noEnforceFocus"
        :no-fade="noFade"
        :no-stacking="noStacking"
        :ok-disabled="okDisabled"
        :ok-only="okOnly"
        :ok-title="$storage.getTextoTraduzido(okTitle)"
        :ok-title-html="okTitleHtml"
        :ok-variant="okVariant"
        :return-focus="returnFocus"
        :scrollable="scrollable"
        :size="size"
        :static="this.static === undefined ? true : this.static"
        :title="$storage.getTextoTraduzido(title)"
        :title-class="titleClass"
        :title-html="titleHtml"
        :title-sr-only="titleSrOnly"
        :title-tag="titleTag"
        :visible="visible"
        ref="modal"
        v-model="modalShow"
    >

        <MotorS 
            :cp="cp" 
            :childrens="getBodyComponents()"
            :idTela="idTela"
        />

        <template 
            v-slot:modal-footer
            v-if="getBodyComponents().length > 0"
        >
            <ClicModalFooter 
                :cp="{component:'ClicModalFooter', id:'modal-footer'}" 
                :childrens="getComponentsFooterSlot()"
                :idTela="idTela"
            />
        </template>

    </b-modal>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicModal',
    mixins: [ComponentMixin],

    data() {
        return {
            public : {
                showModal   : this.showModal,
                hideModal   : this.hideModal,
                data        : {},
                populate    : this.populate,
                rootChildrens : this.rootChildrens
            },
            modalShow   : false,
        };
    },

    methods: {
        getBodyComponents(){
            try{
                return this.childrens && this.childrens.filter((item)=>{return item.component != 'ClicModalFooter'});
            }catch(e){
                console.error(`['ClicModal'] Ocorreu um erro no método 'renderBody'. \n\n\ Detalhes: ${e}`)
            }
        },

        getComponentsFooterSlot() {
            try {
                return this.childrens && this.childrens.filter((item)=>{return item.component == 'ClicModalFooter'});
                /*
                if  (this.childrens) {
                    const filtroChildrens = this.childrens.filter((item)=>{return item.component == 'ClicModalFooter'});
                    return (filtroChildrens && filtroChildrens.length && filtroChildrens[0].childrens) || [];
                } else {
                    return [];
                }
                */
            } catch(e) {
                console.error(`['ClicModal'] Ocorreu um erro no método 'renderFooterSlot'. \n\n\ Detalhes: ${e}`)
            }
        },

        populate(path = 'this.childrens', data = null){
            try{
                data = data != null ? data : this.getData();

                eval(path).map((component)=>{
                    let comp = this.$getPropriedades(this);
                    comp[component.id].setPropValue('value', data[component.id]);
                });
            }catch(e){
                console.error(`['ClicModal'] Ocorreu um erro no método 'populate'. Detalhes: ${e} \n\n\ Por favor verifique os parametros informados. \n\n data - Objeto \n\n path - String`)
            }
            
        },

        rootChildrens(){
            let rootModalComponents = [];
            this.childrens.map((mdChildren)=>{
                rootModalComponents.push(
                    {
                        [mdChildren.id] : this.$getPropriedades(this)[mdChildren.id]
                    }
                )
            });

           return Object.assign({}, ...rootModalComponents);
        },

        getData(){
            try{
                let comp        = this.$getPropriedades(this);
                let modalData   = comp[this.cp.id]?.data ? comp[this.cp.id].data : {};
                
                return modalData;
            }catch(err){
                console.error(`['ClicModal'] Ocorreu um erro no método 'getData'. Detalhes: ${e}`)
            }
           
        },

        showModal() {
            this.modalShow = true;
            if  (this.lodash.has(this.cp, 'events.onShow')) {
                try {
                    eval(this.cp.events.onShow);
                } catch (e) {
                    console.error(`[ClicModal] Falha ao executar evendo 'onShow'. Detalhes: ${e}`);
                }
                
            }
        },
        
        hideModal() {
            this.modalShow = false;
            if  (this.lodash.has(this.cp, 'events.onHide')) {
                try {
                    eval(this.cp.events.onHide);
                } catch (e) {
                    console.error(`[ClicModal] Falha ao executar evendo 'onHide'. Detalhes: ${e}`);
                }
            }
        },

        getIdTela(){
            return JSON.parse(JSON.stringify(this.$route.name));
        },
    },

};
</script>

<style>
    .modal-body-class {
        padding: 0px !important;
    }
    .modal-footer-class {
        padding: 0px 10px 0px 10px !important;
    }

    @media (min-width: 900px){
        .modal-xl {
            max-width: 90vw !important;
        }

        .modal-lg {
            max-width: 55vw !important;
        }
    }

    /* 
    habilita rolagem no body do modal
    
    .modal-body {
        overflow-y: auto;
        max-height: 70vh;
    }

    */    

</style>