import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // label                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // invalidFeedbackMessage   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // validateScript           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // fieldState               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // jsonId                   :{
        //     hintTitle: "",
        //     hintText:""
        // },

        // ariaInvalid              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // autocomplete             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // autofocus                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // debounce                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // disabled                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // form                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // formatter                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // lazy                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // lazyFormatter            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // list                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // max                      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // min                      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // name                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noWheel                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // number                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // placeholder              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // plaintext                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // readonly                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // required                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // size                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // step                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // trim                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // type                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // autocapitalize           :{
        //     hintTitle: "",
        //     hintText:""
        // },

        // blur    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // change  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // input   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // update  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // keyup   :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}
