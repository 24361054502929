<template>
    <b-badge :variant="getProp('badgeVariant')" v-if="getProp('badgeText')" style="scale: 1.1;">
        {{ getProp('badgeText') }}
    </b-badge>
</template>

<script>

import ComponentInternalMixin from '../../core/ComponentInternalMixin';

export default {
    name: 'ClicBadgeInterno',
    mixins: [ComponentInternalMixin],
}

</script>
