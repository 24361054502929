<template>
    <b-modal 
        :id="chave"
        size="xl" 
        :title="`Editando | ${chave}`"
        no-close-on-backdrop
        :scrollable="true"
        busy="busy"
    >
        <b-row>
            <ClicToolbar
                :cp="getToolbarProperties"
                idTela="clicCodeStudio"
                v-on:clicCodeStudioSaveValue="save"
            />

            <ClicCol
                :cp="{
                    id:'reparticaoScript',
                    props:{
                        md:'12',
                        style:'padding: 0; height: calc(100vh - 35px); width: 100%;'
                    }
                }"
                idTela="clicCodeStudio"
                :childrens="getCodeEditorProperties"
            />
                        
            <ClicCol
                :cp="{
                    id:'reparticaoPresets',
                    props:{
                        md:'3',
                        style:'padding: 0; height: 100vh; width: 100%;'
                    }
                }"
                idTela="clicCodeStudio"
                :childrens="getTreeViewProperties"
            >
            </ClicCol> 
   
        </b-row>
      
        <template #modal-footer>
     
                <b-button
                variant="outline-primary"
                size="md"
                class="float-right"
                @click="save"
            >
                OK
            </b-button>

        </template> 
    </b-modal>
</template>

<script>

import MonacoEditor from 'monaco-editor-vue';

export default {
    name: 'CodeEditor',
    components: {
        MonacoEditor,    
    },
    data(){
        return {
            options : {
                theme : "vs-dark",
                formatOnPaste   : true,
                formatOnType    : true,
                tabCompletion   : true,
                automaticLayout : true,
                wordWrap        : false,
                minimap         : { enabled: true },
                autoIndent      : true,

            },
            codigo      : '',
            editedScript: '',
            diffCode    : false,

            treeViewProperties : {},
            codeEditorProperties : {},
            toolbarProperties : {},
        }
    },

    mounted(){
        this.inicializacao();
    },

    computed:{
        
        getTreeViewProperties(){
            const urlApiPresets = `${process.env.VUE_APP_BACKEND_URL}/api/presets`;
            return [
                {
                    "id": "arvorePresets",
                    "component": "ClicTreeView",
                    "props": {
                        "md": 12,
                        "containerStyle": "padding: 0; height: 100%; width: 100%;",
                        "label": "Presets",
                        "atributeId": "label",
                        "atributeLabel": "label",
                        "atributeChildren": "children",
                        "responsePath": "dados",
                        "axios": {
                            "method": "GET",
                            "url": urlApiPresets
                        }
                    },
                    "events": {
                        "doubleClick": "let comp = this.$getPropriedades(this); if (!node.children) { comp.editorScript.insertTextOnMouseCursor(node.data.script);} "
                    },
                    "childrens": []
                }
            ]
        },  

        getCodeEditorProperties(){
            return [
                {
                    "id": "editorScript",
                    "component": "ClicCodeEditor",
                    "props": {
                        "containerStyle": "padding: 0; height: 100%; width: 100%;",
                        "value": this.inicializacao()
                    },
                    "childrens": []
                }
            ]
        },

        getToolbarProperties(){
            return {
                "id":"menuTopoToolbar",
                "props":{
                    'md': 12,
                    'containerStyle': 'padding-top: 0px; padding-bottom: 0px;',
                    'content': [
                       {
                            "text": {
                                "PT": "Arquivo",
                                "EN": "File"
                            },
                            "menu": [
                                {
                                    "text": {
                                        "PT": "Salvar",
                                        "EN": "Save"
                                    },
                                    "hotkey": {
                                        "win": "ctrl+shift+S"
                                    },
                                     "click": "this.$emit('clicCodeStudioSaveValue')"
                                }
                            ]
                        },
                        {
                            'text': {
                                'PT': 'Documentação',
                                'EN': 'Documentation'
                            },
                            'hotkey': {
                                'win': 'ctrl+shift+D'
                            },
                            'click': 'window.open(`http://wiki.clictech.com.br/`, `_blank`);'
                        },
                        {
                            'text': 'Presets',
                            'hotkey': {
                                'win': 'ctrl+shift+P'
                            },
                            "click": "let comp = this.$getPropriedades(this) ;let presetsVisivel = comp.reparticaoPresets.getPropValue('visible'); comp.reparticaoScript.setPropValue('md', !presetsVisivel ? 9 : 12); comp.reparticaoPresets.setPropValue('visible', !presetsVisivel);"
                        }
                    ]
                }
                
            }


        }


    },

    methods: {
  
        inicializacao(){
            try {
                if(this.code && this.code != ''){
                    this.codigo = JSON.stringify(this.code);
                 
                    return this.codigo;
                }else{
                    return this.code;
                }

            } catch (e) {
                console.error(`[CodeEditor] Falha ao executar o evento 'inicializacao'. Detalhes: ${e}`);
            }
        },

        save(){
            this.editedScript = this.$root.$store.getters[
                "clicCodeStudio/getPropriedades"].editorScript.getPropValue("value");

            this.$emit('changeValueCodeEditor',this.editedScript); 
            this.$bvModal.hide(this.chave);
        }
    },
    
    props: {
        code:{
            default: "",
        },
        chave:{
            type: String,
            default: "",
        },
    },

};
</script>

<style scoped>
    .editor{
        text-align: left;
        width: 100%;
        height: 100%;
        min-height: 100vh;
    }

    .monaco-editor .parameter-hints-widget {
        border: 0px;
    }
    .monaco-editor .parameter-hints-widget .signature {
        padding: 0px;
    }
    .monaco-editor .suggest-widget {
        border: 0px;
    }
    .monaco-editor.vs-dark .suggest-widget {
        border: 0px;
    }
    .monaco-editor .rename-box {
        top: 0;
    }
    * >>> .modal-dialog {
        max-width: 100% !important;
        margin: 0 !important;
        top: 28px !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: 100vh !important;
        display: flex !important;
        position: fixed !important;
        z-index: 100000 !important;
    }

    * >>> .modal-body{
        padding: 0 !important;
    }
</style>



