<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
    :id="componentKey"
  >
    <div class="form-group left-label">    
      <div class="d-flex flex-row align-items-start">
          <label class="form-label form-label-clic" v-if="label">
            <b v-if="required && (value == undefined || value === '')" class="text-danger">*</b>
            {{ $storage.getTextoTraduzido(label) }}
          </label>
          <ClicHintInterno
              v-if="hintText"
              :cp="cp"
          />
      </div>

      <div class="form-control-wrap">
        <div class="form-icon form-icon-left" v-if="iconName">
          <ClicIconInterno
            v-if="iconName"
            :cp="cp"
          />
        </div>
        <b-form-input
          :style="style"
          :aria-invalid="ariaInvalid"
          :autocomplete="autocomplete"
          :autofocus="autofocus"
          :debounce="debounce"
          :disabled="disabled"
          :form="form"
          :formatter="formatterFunc"
          :lazy="lazy"
          :lazy-formatter="lazyFormatter"
          :list="list"
          :max="max"
          :min="min"
          :name="name"
          :no-wheel="noWheel"
          :number="number"
          :placeholder="$storage.getTextoTraduzido(placeholder)"
          :plaintext="plaintext"
          :readonly="readonly"
          :required="required"
          :size="size"
          :state="fieldState"
          :step="step"
          :trim="trim"
          :type="type"
          :class="`form-control ${this.class}`"
          :autocapitalize="autocapitalize"
          v-model="value"
          :ref="cp.id"
          @change="change"
          @input="input"
          @blur="blur"
          @update="update"
          v-on:keyup="keyup"
        >
        
        </b-form-input>
        <b-form-invalid-feedback 
          v-if="invalidFeedbackMessage" 
        >
          {{invalidFeedbackMessage}}
        </b-form-invalid-feedback>
      </div>
    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicInput',
  mixins: [ComponentMixin],

  data() {
    return {
      value       : undefined,
      oldValue    : undefined,
      fieldState  : undefined,          
      public:{
        isValid         : this.validateField,
        clearField      : this.clearField,
        undo            : this.undo,
        apply           : this.apply,
        isValueChanged  : this.isValueChanged,
        focus           : this.focus
      }
    }
  },

  mounted() {
    this.oldValue = this.value;
  },
  
  methods: {
    input(value) {
      if(this.lodash.has(this.cp, 'events.input')){
        try {
          eval(this.cp.events.input);
        } catch (e) {
          console.error(`[ClicInput] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
    change(value) {
      if(this.lodash.has(this.cp, 'events.change')){
        try {
          eval(this.cp.events.change);
        } catch (e) {
          console.error(`[ClicInput] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
    blur(event) {
      if (this.lodash.has(this.cp, 'props.validateScript')){
        this.validateField();  
      };

      if  (this.lodash.has(this.cp, 'events.blur')) {
        try {
          eval(this.cp.events.blur);
        } catch (e) {
          console.error(`[ClicInput] Falha ao executar evento 'blur'. Detalhes: ${e}`);
        }
      }
    },
    update(value) {
      if(!this.fieldState || this.fieldState){
        this.fieldState = undefined;
      };
      if  (this.lodash.has(this.cp, 'events.update')) {
        try {
          eval(this.cp.events.update);
        } catch (e) {
          console.error(`[ClicInput] Falha ao executar evento 'update'. Detalhes: ${e}`);
        }
      }
    },
    keyup(keyboardEvent) {
      if  (this.lodash.has(this.cp, 'events.keyup')) {
        try {
          eval(this.cp.events.keyup);
        } catch (e) {
          console.error(`[ClicInput] Falha ao executar evento 'keyup'. Detalhes: ${e}`);
        }
      }
    },

    undo() {
      this.value = this.oldValue;
    },
    apply() {
      this.oldValue = this.value;
    },
    isValueChanged() {
      return this.oldValue != this.value;
    },

    focus() {
      this.$refs[this.cp.id].focus();
    },

    formatterFunc(value) {
      if (this.lodash.has(this.cp, 'props.formatter')) {
        try {
          
          const evaluate = new Function('value', this.cp.props.formatter);
          return evaluate(value);

        } catch (e) {
          console.error(`[ClicInput] Falha ao executar o 'formatter'. Detalhes: ${e}`);
        }
      } else {
        return value;
      }
    }

  },

};

</script>

<style>
.invalid-feedback{
  margin-top:0;
}
</style>



