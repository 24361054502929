<template>
    <ClicColInterno
        :cp="cp"
        :key="componentKey"
        containerStyle="height: 100%; width: 100%;"
    >

    <CodeDiff
        :language="language"
        :oldString="oldString"
        :newString="newString"
        :context="context"
        :outputFormat="outputFormat"
        :diffStyle="diffStyle"
        :forceInlineComparison="forceInlineComparison"
        :trim="trim"
        :noDiffLineFeed="noDiffLineFeed"
        :maxHeight="maxHeight"
        :filename="filename"
        :newFilename="newFilename"
        :hideHeader="hideHeader"
        :hideStat="hideStat"
        :theme="theme"
        :ignoreMatchingLines="ignoreMatchingLines"
        @diff="diff"
    >
        <template v-slot:stat="slotProps" v-if="$getSlot('stat')">
            <MotorS 
                :cp="cp" 
                :childrens="$getSlot('stat')"
                :idTela="idTela"
                :state="state"
            />
        </template>
    </CodeDiff>


    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import { CodeDiff } from 'v-code-diff';

const DiffMatchPatch = require('diff-match-patch');
const dmp = new DiffMatchPatch();

export default {
    name: 'ClicCodeDiff',
    mixins: [ComponentMixin],
    components: {
        CodeDiff
    },

    mounted() {
        const originalDoc = "Este é o documento original.";

        
        const patches = [
            dmp.patch_make(originalDoc, "Este é o documento modificado."),
            dmp.patch_make("Este é o documento modificado.", "Este é o documento final.")
        ];
        const historyIndex = 0;

        const result = this.getDocumentsFromHistory(originalDoc, patches, historyIndex);
        console.log("Documento Atual:", result.currentDoc);
        console.log("Documento no Histórico:", result.historicalDoc);

    },

    data(){
        return {
            public : {
                
            },


            doc : {
                id: "doc1",
                content: "Este é o conteúdo atualizado do documento.",
                history: [
                    {
                        timestamp: "2024-07-05T12:34:56.789Z",
                        author: "Autor 1",
                        content: "Este é o conteúdo inicial do documento."
                    },
                    {
                        timestamp: "2024-07-06T12:34:56.789Z",
                        author: "Autor 2",
                        content: "Este é o conteúdo atualizado do documento."
                    }
                ]
            }


        
        }
    },

    methods: {
        diff(result) {
            if(this.lodash.has(this.cp, 'events.diff')){
                try {
                    eval(this.cp.events.diff);
                } catch (e) {
                    console.error(`[ClicCodeDiff] Falha ao executar evento 'diff'. Detalhes: ${e}`);
                }
            }
        },



        applyPatches(originalDoc, patches) {
            let patchesApplied = dmp.patch_apply(patches, originalDoc);
            return patchesApplied[0];
        },

        getDocumentsFromHistory(originalDoc, history, historyIndex) {
            // Calcula o documento atual aplicando todos os patches do histórico
            const currentDoc = this.applyPatches(originalDoc, history);

            // Calcula o documento até o ponto do histórico especificado
            const patchesUpToHistoryIndex = history.slice(0, historyIndex + 1);
            const historicalDoc = this.applyPatches(originalDoc, patchesUpToHistoryIndex);

            return {
                currentDoc,
                historicalDoc
            };
        },




    }

};
</script>

<style lang="scss">

</style>



