import axios from 'axios';
import lodash from 'lodash';

const USERKEY = window.location.host.split('.')[0] + '-clic-framework';

const temKey = () => localStorage.getItem(USERKEY) !== null

const adicionaAuthorization = (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}` 
}

const removeAuthorization = () => {
  delete axios.defaults.headers.common.Authorization;
}

const getUserObj = () => {
  if (temKey()) {
    let userObj = JSON.parse(localStorage.getItem(USERKEY));
    adicionaAuthorization(userObj.token);
    return JSON.parse(localStorage.getItem(USERKEY));
  }
  return temKey() ? JSON.parse(localStorage.getItem(USERKEY)) : ''
}

const setUserObj = (_this, userObj) => {
  if  (userObj && userObj.token) {
    localStorage.setItem(USERKEY, JSON.stringify(userObj));
    adicionaAuthorization(userObj.token);
    _this.window?.ReactNativeWebView?.postMessage('logou');
    _this.$router.push({ name: _this.$router.getRoutes().filter(item => item.meta.isHome)[0].name });
    if  (userObj.salvarLogin) {
      addUsuarioHistorico(userObj);
    }
  }
}

const setIdioma = (idioma) => {
  if(temKey()){
    let obj = JSON.parse(localStorage.getItem(USERKEY));
    obj.idioma = idioma;
    localStorage.setItem(USERKEY, JSON.stringify(obj));
  }
}

const getIdioma = () => {
  if(temKey()){
    let obj = JSON.parse(localStorage.getItem(USERKEY));
    return obj.idioma || 'PT';
  } else {
    return 'PT';
  }
}

const getUsuariosHistorico = () => {
  let obj = JSON.parse(localStorage.getItem(USERKEY));
  return (obj && obj.usuariosHistorico) || [];
}

const addUsuarioHistorico = (user) => {
  delete user.token;
  let obj = JSON.parse(localStorage.getItem(USERKEY));
  let usuariosHistorico = obj.usuariosHistorico || [];
 
  const i = usuariosHistorico.findIndex(_element => _element.id === user.login);
  if (i > -1) usuariosHistorico[i] = user; else usuariosHistorico.push(user); 
  
  obj.usuariosHistorico = usuariosHistorico;
  localStorage.setItem(USERKEY, JSON.stringify(obj));
}

const removeUsuarioHistorico = (user) => {
  let obj = JSON.parse(localStorage.getItem(USERKEY));
  let usuariosHistorico = obj.usuariosHistorico || [];
  usuariosHistorico.splice(usuariosHistorico.findIndex(e => e.login == user.login),1);
  obj.usuariosHistorico = usuariosHistorico;
  localStorage.setItem(USERKEY, JSON.stringify(obj));
}

const getTextoTraduzido = (texto) => {
  return texto ? (texto[getIdioma()] || texto['PT'] || texto) : '';
}

const getSessionKey = () => {
  return window.location.host.split('.')[0] + '-clic-framework';
}

const getToken = () => {
  if (temKey()) {
    let obj = JSON.parse(localStorage.getItem(USERKEY));
    return obj.accessToken;
  }
}

const removeUserObj = (_this) => {
  if (temKey()) {
    let obj = JSON.parse(localStorage.getItem(USERKEY));
    delete obj.token;
    localStorage.setItem(USERKEY, JSON.stringify(obj));
    _this.window?.ReactNativeWebView?.postMessage('logout');
    _this.$router.go();
  }
  removeAuthorization();
}

const logout = () => {
  let sessao = JSON.parse(localStorage.getItem(getSessionKey()));
  
}

const getFrontend = (subdominio) => {
  return new Promise((resolve, reject) => {
    let obj = {};
    let version = 0;
    if (temKey()) {
      obj = JSON.parse(localStorage.getItem(USERKEY));
      if  (obj.frontend) {
        version = obj.frontendVersion || 0;
      }
    }

    const api = `${process.env.VUE_APP_BACKEND_URL}/api/frontend?subdominio=${subdominio}&situacao=A&version=${version}&teste=1`
    axios.get(api).then(retorno => {
      let newVersion = lodash.get(retorno, 'data.version', 0);
      let dadosFrontend = lodash.get(retorno, 'data.dados', obj.frontend);
      obj.frontend = dadosFrontend;
      obj.frontendVersion = newVersion;
      localStorage.setItem(USERKEY, JSON.stringify(obj));
      resolve(dadosFrontend);
    });
    
  });
}

export default {
  getToken,
  getUserObj,
  setUserObj,
  removeUserObj,
  temKey,
  setIdioma,
  getIdioma,
  getTextoTraduzido,
  getUsuariosHistorico,
  addUsuarioHistorico,
  removeUsuarioHistorico,
  getSessionKey,
  getFrontend
}
