import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
       
        ...GeneralProperties.documentation,
         
         // containerTitle      : { 
         //    hintTitle:``,
         //    hintText:``
         // },
       
         // col                 : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // cols                : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // offset              : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // offsetLG            : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // offsetMD            : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // offsetSM            : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // offsetXL            : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // order               : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // orderLG             : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // orderMD             : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // orderSM             : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // orderXL             : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // routerComponentName : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // sm                  : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // lg                  : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // md                  : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // xl                  : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // containerTag        : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // alignSelf           : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // containerClass      : { 
         //    hintTitle:``,
         //    hintText:``
         // },
         // containerStyle      : { 
         //    hintTitle:``,
         //    hintText:``
         // },
     }
 }