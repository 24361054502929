import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

    //     jsonId                   :{
    //         hintTitle: "",
    //         hintText:""
    //     },

    //     text                     :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     mode                     :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     height                   :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     tabSize                  :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     tocNavPositionRight      :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     defaultShowToc           :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     placeholder              :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     autofocus                :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     defaultFullscreen        :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     includeLevel             :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     leftToolbar              :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     rightToolbar             :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     toolbar                  :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     toolbarConfig            :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     disabledMenus            :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     uploadImageConfig        :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     beforePreviewChange      :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     codemirrorConfig         :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     codemirrorStyleReset     :{
    //         hintTitle: "",
    //         hintText:""
    //     },

    //     change              :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     blur                :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     save                :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     imageClick          :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     fullscreenChange    :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    //     uploadImage         :{
    //         hintTitle: "",
    //         hintText:""
    //     },
    }
}
