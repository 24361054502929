import ClicTableColumnTextDocs from "./ClicTableColumnTextDocs"

export default {
    ClicTableColumnText : {
        config      : {icon:"md-switchright-outlined", documentation: ClicTableColumnTextDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {            

            customFormatter     : {type: 'string',      default: undefined, component: { name : 'input' } },

            key                 : {type: 'string',      default: undefined, component: { name : 'input' } },
            label               : {type: 'string',      default: "",        component: { name : 'input' } },
            sortable            : {type: 'boolean',     default: undefined, component: { name : 'switch' } },        
            headerTitle         : {type: 'string',      default: undefined, component: { name : 'input' } },
            headerAbbr          : {type: 'string',      default: undefined, component: { name : 'input' } },
            sortKey             : {type: 'string',      default: undefined, component: { name : 'input' } },
            sortDirection       : {type: 'string',      default: undefined, component: { name : 'input' } },
            sortByFormatted     : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            filterByFormatted   : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            tdClass             : {type: 'string',      default: undefined, component: { name : 'multiselect', 
                                    values: ["show-col-xl", "show-col-lg", "show-col-md", "show-col-sm",
                                    "hide-col-xl", "hide-col-lg", "hide-col-md", "hide-col-sm"]}},
            thClass             : {type: 'string',      default: undefined, component: { name : 'multiselect', 
                                    values: ["show-header-xl", "show-header-lg", "show-header-md", "show-header-sm",
                                    "hide-header-xl", "hide-header-lg", "hide-header-md", "hide-header-sm"]}},
            thStyle             : {type: 'string',      default: undefined, component: { name : 'input' } },
            variant             : {type: 'string',      default: undefined, component: { name : 'input' } },
            tdAttr              : {type: 'string',      default: undefined, component: { name : 'input' } },
            thAttr              : {type: 'string',      default: undefined, component: { name : 'input' } },
            isRowHeader         : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            stickyColumn        : {type: 'boolean',     default: undefined, component: { name : 'switch' } },


        },
        events : {           
        }
    }
}
