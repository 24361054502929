<template>
    <b-row
        :align-content="alignContent"
        :align-h="alignH"
        :align-v="alignV"
        :cols="cols"
        :cols-lg="colsLG"
        :cols-md="colsMD"
        :cols-sm="colsSM"
        :cols-xl="colsXL"
        :no-gutters="noGutters"
        :tag="tag"
        :class="`${this.class} row-bg-white`"
        :style="style"
        :key="componentKey"
        v-show="visible"
    >
        <b-col v-if="title || subtitle" md=12>
            <h6 v-if="title" class="title">{{ $storage.getTextoTraduzido(title) }}</h6>
            <h6 v-if="subtitle" class="subtitle p-1">{{ $storage.getTextoTraduzido(subtitle) }}</h6>
        </b-col>

        <MotorS 
            :cp="cp" 
            :childrens="childrens"
            :idTela="idTela"
            :state="state"
        />
    </b-row>
</template>
<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicRow',
    mixins: [ComponentMixin],
    data() {
        return {
            public:{
                isValidForm         : this.validateForm,
                clearForm           : this.clearForm,
                getFormValuesAsJson : this.getForm,
                setFormValues       : this.setFormValues,
                applyForm           : this.applyForm,
                undoForm            : this.undoForm
            }
        }
    },

    methods:{
        setFormValues(objeto){
            try{
                if(this.cp.id){
                    let components  = this.$getPropriedades(this),
                        allInputs   = [];
                        
                    for(let cp of components[this.cp.id].childrens){
                        allInputs.push(...this.getInputsInForm(cp, components));
                    };

                    for(let id of allInputs){     
                        const comp = components[id];
                        if(comp.getPropValue("jsonId") != undefined){
                            let value = objeto[comp.getPropValue("jsonId")];
                            comp.setPropValue("value", value);
                            comp.setPropValue("fieldState", undefined);
                            
                        } else {
                            let value = objeto[id];
                            comp.setPropValue("value", value);
                            comp.setPropValue("fieldState", undefined);  
                        }
                    };
                };
            }catch(e){
                console.error(`['ClicRow'] Ocorreu um erro no método 'setFormValue'. Detalhes: ${e}`)
            }
        },

        getForm(alias){
            if(this.cp.id){
                let components  = this.$getPropriedades(this),
                    retorno     = {},
                    allInputs   = [];
                    
                for(let cp of components[this.cp.id].childrens){
                    allInputs.push(...this.getInputsInForm(cp, components));
                }

                for(let id of allInputs){  
                    const comp = components[id];    
                    if(alias && alias[id]){
                        retorno[alias[id]] = comp.getPropValue("value");
                    }else if(comp.getPropValue("jsonId")){
                        retorno[comp.getPropValue("jsonId")] = comp.getPropValue("value");
                    }else if(id){
                        retorno[id] = comp.getPropValue("value");
                    }      
                }
                
                return retorno;
            };
        },

        async validateForm(){
            if(this.cp.id){
                /*
                    TODO
                    Quando a row estiver em uma table, pegar os componentes da row, e não da tela!
                    1 - criar function que percorre todos os componentes pai, até a raiz, para descobrir se agum é ClicTable
                    2 - caso achar a table, usar o this.$getRowComponents()
                    3 - demais situações, permanece a rotina do jeito que estava, usando this.getPropriedades(this)
                */
                let components  = this.$getPropriedades(this),
                    retorno     = [],
                    allInputs   = [],
                    isValid;
                    
                for(let cp of components[this.cp.id].childrens){
                    allInputs.push(...this.getInputsInForm(cp, components));
                }

                for(let id of allInputs){
                    
                    isValid = await components[id].isValid();
      
                    if(isValid != undefined){
                        retorno.push(isValid);
                    }
                }
                return retorno.every(v => v === true) ? true : false;
            };
            
        },

        clearForm(){
            if(this.cp.id){
                let components = this.$getPropriedades(this),
                    allInputs  = [];
                    
                for(let cp of components[this.cp.id].childrens){
                    allInputs.push(...this.getInputsInForm(cp, components));
                }
            
                for(let id of allInputs){
                    components[id].clearField();
                    components[id].setPropValue("fieldState", undefined);
                }
            };
        },

        undoForm(){
            let components = this.$getPropriedades(this);
            let allInputs  = [];
                
            for(let cp of components[this.cp.id].childrens){
                allInputs.push(...this.getInputsInForm(cp, components));
            }
        
            for(let id of allInputs){
                components[id].undo();
            }
        },

        applyForm(){
            let components = this.$getPropriedades(this);
            let allInputs  = [];
                
            for(let cp of components[this.cp.id].childrens){
                allInputs.push(...this.getInputsInForm(cp, components));
            }
        
            for(let id of allInputs){
                components[id].apply();
            }
        },

        getInputsInForm(component, rootNode){
            let retorno = [];

            function getInput(cp, rootComp){
                if(cp.component && 
                    (cp.component == "ClicInput"  ||
                    cp.component == "ClicInputMask" ||
                    cp.component == "ClicInputCurrencyMask" ||
                    cp.component == "ClicMultiselect" ||
                    cp.component == "ClicDatePicker" ||
                    cp.component == "ClicTextArea" ||
                    cp.component == "ClicSwitch" ||
                    cp.component == "ClicCodeEditor" ||
                    // cp.component == "ClicMarkdown") && rootComp[cp.id] && rootComp[cp.id].id){
                    cp.component == "ClicMarkdown") && rootComp[cp.id] && rootComp[cp.id].id){
                        retorno.push(rootComp[cp.id].id)
                }else{
                    if(cp.childrens && cp.childrens.length > 0){
                        for(let childrens of cp.childrens){
                          getInput(childrens, rootComp);
                        }
                    }
                }
                return [...retorno];
            };
            return getInput(component, rootNode);
        }
    },
};
</script>

<style>
.row-bg-white{
    background-color: transparent ;
}

</style>