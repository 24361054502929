import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicBreadcrumbsDocs from './ClicBreadcrumbDocs';

export default {
    ClicBreadcrumb : {
        config      : {icon:"md-smartbutton", documentation: ClicBreadcrumbsDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            items           : {type: 'array',    default: [],    component: { name : 'code' } },
            card            : {type: 'boolean',  default: false, component: { name : 'switch' } },
            representRoute  : {type: 'boolean',  default: false, component: { name : 'switch' } },

            ...ClicColInterno,
        },
        events : {
            click           : {type: 'string', component: { name : 'code' } },
        }
    }
}









