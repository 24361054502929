import Vue from 'vue';
import { io } from 'socket.io-client';
import TokenService from "./tokenService";
import AuthService from './authService';
import router from '../router/Router';

class SocketDefaultService {
  socketDefault;

  constructor() {
    this.connectDefault();
  }

  connectDefault() {
    try {
      const userData = TokenService.getUser();
      const token = userData && userData.accessToken;
      this.socketDefault = io(`${process.env.VUE_APP_SOCKET_ENDPOINT}/globalsocket`,
        {
          path: `/socket/server/`,
          auth: {
            token
          }
        }
      );
    } catch(e) {
      console.error(`[socket] falha ao criar e configurar a conexão default. Detalhes: `, e);
    } 
  }

  userConnect(dados) {
    this.socketDefault.emit("userConnect", dados);
    this.configureOnUserDisconnectEvent(); 
  }
  
  disconnect() {
    if(this.socketDefault) {
      this.socketDefault.disconnect();
    }
  }

  setupSocketConnection() {
    const userData = TokenService.getUser();
    if  (userData) {
      const { id, login, subdominio } = userData;
      this.userConnect({ id, login, subdominio });
    }
  }

  configureOnUserDisconnectEvent() {
    if  (this.socketDefault) {
      try {
        this.socketDefault.on("userDisconnect", (id) => {
          const user = TokenService.getUser();
          if  (id == user.id) {
            this.disconnect();
            AuthService.logout();
            router.go();
          }
        });
      } catch(e) {
        console.error(`[socket][userDisconnect] falha ao configurar. Detalhes: `, e);
      }
    } else {
      console.error(`[socket][userDisconnect] socket não conectado.`);
    }
  }
  
}

export default new SocketDefaultService();