<template>
    <notifications
        :closeOnClick=true
        width=350
    >
        <template slot="body" slot-scope="{ item, close }">

            <div class="alert alert-pro alert-secondary alert-icon alert-dismissible" 
                :class="`alert-${item.type}`"
                style="margin: 10px; cursor: pointer;" 
                @click="close"
            >
                <em class="icon ni"
                    :class="obterIconeNotificacao(item.type)"></em> 
                <div class="alert-text">
                    <h6 v-if="item.title">{{ item.title }}</h6>
                    <div v-html="item.text"/>
                </div>
            <button class="close"></button>
            </div>

        </template>
    </notifications>
</template>

<script>

export default {
    name: 'ClicNotificationInterno',
    methods: {
        obterIconeNotificacao(tipo) {
            return  tipo == "success"   ? "ni-check-circle"     : 
                    tipo == "danger"    ? "ni-cross-circle"     :
                    tipo == "warning"   ? "ni-alert-circle"     :
                    tipo == "info"      ? "ni-info"             :   "ni-info";
        }
    }
};
</script>

<style lang="scss">


    .clic-notificacao {
        
        margin: 0 5px 5px;
        padding: 10px;
        font-size: 12px;
        color: #383d41;
        background: #e2e3e5 !important;
        border-left: 5px solid #383d41;

        &.informacao {
            color: #0c5460;
            background: #d1ecf1 !important;
            border-left-color: #0c5460;
            opacity: 0.9;
        }

        &.sucesso {
            color : #155724;
            background: #d4edda !important;
            border-left-color: #155724;
            opacity: 0.9;
        }

        &.alerta {
            color: #856404;
            background: #fff3cd !important;
            border-left-color: #856404;
            opacity: 0.9;
        }

        &.erro {
            color: #721c24;
            background: #f8d7da !important;
            border-left-color: #721c24;
            opacity: 0.9;
        }
    }
  


</style>