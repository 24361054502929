<template>
  <div>

    <b-modal :ref="isEditable == true ? 'modalSetID' : 'modal'" no-close-on-esc no-close-on-backdrop size="md">
      <template #modal-header>
        <h5>Adicionando Componente</h5>
      </template>

      <b-form-group label="ID Componente" :description="` ID formatado: ${lodash.camelCase(idNewComponent)}`"
        class="mb-1" style="font-weight: 900;">
        <b-form-input placeholder="Ex: idNovoComponente" v-model="idNewComponent" />
      </b-form-group>

      <template #modal-footer>
        <b-button size="sm" variant="outline-light" @click="closeModal('cancel')">
          Cancelar
        </b-button>
        <b-button size="sm" variant="outline-primary" @click="closeModal('continuar')">
          Continuar
        </b-button>
      </template>
    </b-modal>


    <b-modal :ref="'copy-paste-modal'" title="Renomeie os itens">
      <b-row class="my-1">
        <b-col sm="4">
          <b-form-input id="input-prefix" v-model="prefix" :state="null" placeholder="Prefixo"></b-form-input>
        </b-col>
        <b-col sm="4" class="component-name-modal">
          ComponenteXYZ
        </b-col>
        <b-col sm="4">
          <b-form-input id="input-suffix" v-model="suffix" :state="null" placeholder="Sufixo"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-1">
        <div class="m-2">Exemplo: {{ prefix }}ComponenteXYZ{{ suffix }}</div>
      </b-row>


      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="outline-warning" @click="$refs['copy-paste-modal'].hide()">
          Cancelar
        </b-button>
        <b-button size="sm" variant="info" @click="doPaste()">
          Colar
        </b-button>

      </template>

    </b-modal>

    <div v-if="searchable" class="container-filter">
      <b-form-input v-model="searchFilter" placeholder="Pesquise pelo nome do componente" />
    </div>
    <!-- <div class="drag-preview" :style="dragStyle" ref="dragPreview"></div> -->

    <draggable :list="dataList" :group="!copyable ? { name: 'g1' } : { name: 'g1', pull: 'clone', put: false }"
      :clone="cloneAction" @add="onAdd" v-bind="dragOptions">
      <transition-group type="transition" name="flip-list">
        <ul v-for="(node, index) in dataList" :key="index">
          <li class="list-group-item container-item-node">
            <div v-if="node.label != ''" class="item-tree">
              <p @click="nodeClicked(node, index)">
                <b-row>
                  <span>
                    <ClicIconInterno v-if="!isEditable"
                      :iconName="node.config && node.config.icon ? node.config.icon : `md-backuptable-outlined`"
                      iconScale="1.2" :iconTitle="node.data.component" />
                  </span>
                  <span id="cointainer-button-drop">
                    <b-dropdown variant="transparent" dropright v-if="isEditable" size="sm">
                      <template #button-content>
                        <ClicIconInterno iconName="md-morevert-twotone" iconScale=".9" iconTitle="Opções" />
                      </template>
                      <b-dropdown-item-button @click="doDelete(index)" class="text-left">
                        <ClicIconInterno iconName="md-restorefromtrash-round" /><b> Remover</b>
                      </b-dropdown-item-button>
                      <b-dropdown-item-button @click="doCopy(index)" class="text-left">
                        <ClicIconInterno iconName="md-contentcopy" /><b> Copiar</b>
                      </b-dropdown-item-button>
                      <b-dropdown-item-button @click="openModalCopyPaste(index)" class="text-left">
                        <ClicIconInterno iconName="md-contentpaste" /><b> Colar</b>
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </span>

                  <div style="height:35px;" class="pb-2" v-if="isEditable">
                    <span v-if="hasChildren(node)" class="type">
                      <ClicIconInterno iconName="md-keyboarddoublearrowdown-sharp" iconScale="1" v-if="isExpanded(node)"
                        @click="nodeClicked(node, index)" />
                      <ClicIconInterno iconName="md-keyboarddoublearrowright-round" iconScale="1"
                        @click="nodeClicked(node, index)" v-else />
                    </span>
                    <span v-else @click="nodeClicked(node, index)" class="type">
                      <ClicIconInterno iconName="md-keyboardarrowright" iconScale="1.2" />
                    </span>
                  </div>
                  &nbsp;&nbsp;

                  <div>
                    <p :class="node.config && node.config.isContainer == true ? 'isContainer' : 'noContainer'"
                      v-if="isEditable">
                      {{ `${node.data.id} (${node.data.component})` }}
                    </p>
                    <p :class="node.config && node.config.isContainer == true ? 'isContainer' : 'noContainer'" v-else>
                      {{ `${node.label}` }}
                    </p>
                  </div>
                </b-row>
              </p>

              <TreeBrowser v-if="isExpanded(node) && node.childrens" :nodes="node.childrens" :depth="depth + 1"
                @select="sendSelectedNode" />

            </div>
          </li>
        </ul>

      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import getAllComponentsProperties from '../../core/componentsProperties'

export default {
  name: "TreeBrowser",
  components: {
    draggable,
  },
  props: {
    nodes: Array,
    depth: { // niveis em aberto
      type: Number,
      default: 0,
    },
    copyable: { // permite copiar de uma lista para outra
      type: Boolean,
    },
    isEditable: { // permite editar itens da lista | Modal
      type: Boolean,
      default: true
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: [],
      data: this.nodes,
      selectedNode: [],
      searchFilter: '',
      containerComponents: [],
      clonedData: '',
      idNewComponent: '',
      indexToAddedItem: 999,
      timer: null,

      //Modal colar itens
      prefix: "",
      suffix: "",
      pasteIndex: 0,
      objectCopyedIds: [],
      objectTest: {}

    };
  },
  methods: {
    getConfigComponentes() {
      let components = getAllComponentsProperties();

      Object.entries(components).forEach(cp => {
        if (cp[1].config && cp[1].config.isContainer) {
          this.containerComponents.push(cp[0])
        }
      })
    },

    cloneAction(item) {
      this.clonedData = structuredClone(item);
      // Cria uma cópia do objeto da coluna copiável, desvinculando do item original.
      return this.clonedData;
    },

    hasChildren(node) {
      if (node.childrens && node.childrens.length > 0) {
        return true
      } else {
        if (node) {
          //Valida se o node é um container, se sim, o mantém expansível
          this.containerComponents.filter((cComponent) => {
            if (cComponent == node.data.component) {
              node.childrens = [
                {
                  "label": "",
                  "data": {
                    "id": "",
                    "component": "",
                    "props": {},
                    "childrens": []
                  }
                }
              ]
            }
          })
        }
        return false
      }
    },

    existInThisNode(oldList, added) {
      let counter = 0;
      oldList.map((item) => {
        return item.data.id == added.data.id ? counter++ : counter = counter;
      })

      return counter > 1 ? true : false;
    },

    closeModal(action) {
      if (action == "continuar") {
        if (this.idNewComponent == "") {

          this.$notificacao({ text: "Você deve informar um ID para continuar", type: 'warning' });
        } else {
          this.data[this.indexToAddedItem].data.id = this.lodash.camelCase(this.idNewComponent);
          this.idNewComponent = this.lodash.camelCase(this.idNewComponent);

          if (!this.existInThisNode(this.data, this.data[this.indexToAddedItem])) {

            this.$refs['modalSetID'].hide()
            this.$notificacao(
              {
                text: `${this.idNewComponent}(${this.data[this.indexToAddedItem].data.component}) foi adicionado`,
                type: 'success'
              }
            );

          } else {
            this.$notificacao({ text: `${this.idNewComponent} já existe na estrutura.`, type: 'warning' });
          }

        }
      } else {
        this.doDelete(this.indexToAddedItem, -1);
        this.$refs['modalSetID'].hide();
      }

    },

    onAdd(e) {
      this.indexToAddedItem = e.newIndex;
      this.idNewComponent = '';
      let isInvalidDepth = false;
      let idEmpty = false;

      if (this.data[this.indexToAddedItem].data.component != "LayoutMenuTopoConteudo"
        && this.data[this.indexToAddedItem].data.component != "LayoutTopoConteudo"
        && this.data[this.indexToAddedItem].data.component != "LayoutVazio" && this.depth == 0) {
        isInvalidDepth = true;
      };
      if (this.data[this.indexToAddedItem].data.component == "LayoutVazio" && this.depth > 0) {
        isInvalidDepth = true;
      };
      if (this.data[this.indexToAddedItem].data.component == "LayoutMenuTopoConteudo" && this.depth > 0) {
        isInvalidDepth = true;
      };
      if (this.data[this.indexToAddedItem].data.component == "LayoutTopoConteudo" && this.depth > 0) {
        isInvalidDepth = true;
      };

      if (!this.data[this.indexToAddedItem].data.id ||
        this.data[this.indexToAddedItem].data.id == "") {

        idEmpty = true;
      }

      if (isInvalidDepth) {
        this.doDelete(this.indexToAddedItem);
      }
      if (idEmpty && !isInvalidDepth) {
        this.$refs['modalSetID'].show();
      }

    },

    isExpanded(node) {
      return this.expanded.indexOf(node) !== -1;
    },

    sendSelectedNode(selected, index) {
      this.$emit('select', selected, index);
    },

    nodeClicked(node, index) {
      if (this.isEditable) {
        if (this.timer) {
          this.selectedNode = node;
          this.sendSelectedNode(this.selectedNode, index);
          clearTimeout(this.timer);
          this.timer = null;
        } else {
          this.timer = setTimeout(() => {
            if (!this.isExpanded(node)) {
              this.expanded.push(node);
              this.selectedNode = node;
              this.sendSelectedNode(this.selectedNode, index);
            } else {
              this.expanded.splice(this.expanded.indexOf(node));
            }
            this.timer = null;
          }, 200);
        }
      }

    },
    removeDuplicatedIds: function () {
      let newArray = [];

      for (let obj of this.objectCopyedIds) {
        // Verifica se newArray já contém um objeto com a mesma actualId
        if (!newArray.some(item => item.actualId === obj.actualId)) {
          newArray.push(obj);
        }
      }
      this.objectCopyedIds = newArray;
    },
    identifyIds(objeto) {
      if (Array.isArray(objeto)) {
        for (let i = 0; i < objeto.length; i++) {
          this.identifyIds(objeto[i]);
        }

      } else if (typeof objeto === 'object' && objeto !== null) {
        for (let chave in objeto) {
          if (chave === 'id') {
            let newId = `${this.prefix}${objeto[chave]}${this.suffix}`;

            // Verifica se um objeto idêntico já existe no array
            let objetoExistente = this.objectCopyedIds.find(item => item.actualId === `${objeto[chave]}` && item.newId === `${newId}`);

            if (!objetoExistente) {
              // Se não existir, adiciona o novo objeto ao array
              this.objectCopyedIds.push({ actualId: `${objeto[chave]}`, newId: `${newId}` });
            }

            // objeto[chave] = newId;

          } else {
            this.identifyIds(objeto[chave]);
          }
        }
      }
    },
    replaceComponentIntoScript: function (objeto) {
      let objectStringified = JSON.stringify(objeto);

      for (const obj of this.objectCopyedIds) {
        // Escapa caracteres especiais em obj.actualId
        const escapedActualId = obj.actualId.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        const regex = new RegExp(escapedActualId, 'g');
        objectStringified = objectStringified.replace(regex, obj.newId);
      }

      return JSON.parse(objectStringified);
    },
    doDelete: function (index) {
      this.data.splice(index, 1);
    },
    doCopy: function (index) {
      this.objectCopyedIds = [];
      let copy = this.data[index];

      // Colocar o objeto na área de transferência
      navigator.clipboard.writeText(JSON.stringify(copy))
        .then(() => {
          this.$notificacao({ text: 'Objeto copiado para a área de transferência', type: 'success' });
        })
        .catch(err => {
          this.$notificacao({ text: `Erro ao copiar para área de transferência: ${err.toString()}`, type: 'warning' });
        });

    },
    doPaste: function () {
      navigator.clipboard.readText()
        .then(text => {
          let copy = JSON.parse(text)

          //Adiciona no array os ids que serão modificados
          this.identifyIds(copy)

          //Remove ids duplicados
          this.removeDuplicatedIds()

          //Reescreve o nome dos antigos Ids
          copy = this.replaceComponentIntoScript(copy)

          this.data[this.pasteIndex].childrens.push(copy)
          this.$refs['copy-paste-modal'].hide();

        })
        .catch(err => {
          this.$notificacao({ text: `Erro ao ler da área de transferência: ${err.toString()}`, type: 'warning' });
        });
    },
    openModalCopyPaste: function (index) {
      this.pasteIndex = index;
      console.log("open")
      this.$refs['copy-paste-modal'].show();
    },
  },

  mounted() {
    this.getConfigComponentes();
  },

  computed: {
    dataList() {
      if (this.searchFilter.trim().length > 0) {
        return this.data.filter((el) =>
          el.label.toLowerCase().includes(this.searchFilter.trim())
        )
      } else {
        return this.data;
      }
    },
    dragOptions() {
      return {
        animation: 150
      };
    }

  },

};
</script>

<style>
p {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin: 0
}

p:hover {
  font-weight: 600;
  font-size: 14px;
}

.type {
  margin-right: 10px;
}

.item-tree {
  min-height: 35px;
  width: 100%;
  padding: 0 !important;
  /* background-color: rgb(240, 240, 240); */
}

/* p:hover{
  width:100%;
  border-bottom: 1px solid rgb(221, 221, 221); */

/* }   */
/* .drag-preview {
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(7deg) translateY(55%);
} */
/* .sortable-ghost .item-tree{
 border: 2px dashed rgb(195, 195, 195);
 width:50%;
  overflow: hidden;

} */

.sortable-ghost {
  border: 2px dashed rgb(195, 195, 195);
  width: 80%;
  overflow: hidden;
  color: rgb(0, 0, 0) !important;
  font-weight: 900 !important;
  /* padding-left:50px */
  /* color:white */
}

.sortable-drag {
  opacity: 0%;
  /* color: rgb(95, 95, 95) !important;
  font-weight: 900 !important;
  width: 95%;
  height: 90%; */
}

/* remover arrasto */
.isContainer {
  font-weight: 600;
}

.noContainer {
  font-weight: 400;
}

.list-group-item {
  padding-top: 0;
  /* padding-right: 2rem; */
  padding-bottom: 0;
  /* padding-left: 2rem; */
  min-height: 35px;
}

.container-filter {
  padding-bottom: 20px
}

.container-item-node {
  border: 0;
  cursor: pointer
}

.component-name-modal {
  display: flex;
  align-items: end;
  justify-content: center;
}

li:hover {
  font-weight: bolder;
}

#cointainer-button-drop .dropdown-toggle {
  padding: 0 !important
}
</style>