import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicTabsDocs from './ClicTabsDocs'

export default {
    ClicTabs : {
        config      : {isContainer:true, icon: "md-tab-twotone", documentation: ClicTabsDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

         
            activeNavItemClass  : {type: 'multiple',    default: undefined, component: { name : 'input' } },
            activeTabClass      : {type: 'multiple',    default: undefined, component: { name : 'input' } },
            align               : {type: 'string',      default: undefined, component: { name : 'input' } },
            card                : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            contentClass        : {type: 'multiple',    default: undefined, component: { name : 'input' } },
            end                 : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            fill                : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            justified           : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            lazy                : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            navClass            : {type: 'multiple',    default: undefined, component: { name : 'input' } },
            navWrapperClass     : {type: 'multiple',    default: undefined, component: { name : 'input' } },
            noFade              : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            noKeyNav            : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            noNavStyle          : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            pills               : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            small               : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            tag                 : {type: 'string',      default: undefined, component: { name : 'input' } },
            value               : {type: 'number',      default: undefined, component: { name : 'input' } },
            vertical            : {type: 'boolean',     default: undefined, component: { name : 'switch' } },

            class       : {type: 'string',      default: undefined, component: { name : 'multiselect', 
                values: [
                'card',
                'bg-white',
                ]}
            },

            ...ClicColInterno,

        },
        events : {
            changed             : {type: 'string', component: { name : 'code' } },
            activateTab         : {type: 'string', component: { name : 'code' } },
            input               : {type: 'string', component: { name : 'code' } },
        },
    }
}