import ClicImageInternoDocs from "./ClicImageInternoDocs"

export default {
    imageFW : {
        config            : { documentation: ClicImageInternoDocs.documentation },

        imageAlt          : {type: 'string',      default: undefined, component: { name : 'input' } },
        imageBlank        : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageBlankColor   : {type: 'string',      default: undefined, component: { name : 'input' } },
        imageBlock        : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageCenter       : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageFluid        : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageFluidGrow    : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageHeight       : {type: 'multiple',    default: undefined, component: { name : 'input' } },
        imageLeft         : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageRight        : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageRounded      : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageSizes        : {type: 'multiple',    default: undefined, component: { name : 'input' } },
        imageSrc          : {type: 'string',      default: undefined, component: { name : 'input' } },
        imageSrcset       : {type: 'multiple',    default: undefined, component: { name : 'input' } },
        imageThumbnail    : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
        imageWidth        : {type: 'multiple',    default: undefined, component: { name : 'input' } },
        imageStyle        : {type: 'string',      default: undefined, component: { name : 'input' } },
        imageClass        : {type: 'string',      default: undefined, component: { name : 'input' } },
    }   
}