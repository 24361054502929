<template>
  <div class="clic-body bg-lighter npc-default has-sidebar">
    <div class="clic-app-root">
      <div class="clic-main" title="">
        <ClicMainSideMenu :cp="this.$route" idTela="mainFramework" />
        <div class="clic-wrap">
          <ClicNavBar id="ClicTopNavBar" idTela="mainFramework" :childrens="this.$navbar" /> 
            <div class="clic-content-geral">
              <h5 
                v-if="$route.meta.title"
              >
                {{$storage.getTextoTraduzido($route.meta.title)}}
              </h5>
              <h6
                class="subtitle p-1" 
                v-if="$route.meta.subtitle"
              >
                {{$storage.getTextoTraduzido($route.meta.subtitle)}}
              </h6>
              <MotorS 
                :cp="this.$route" 
                :childrens="this.$route.meta.childrens"
                :idTela="this.$route.name"
              />
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

  import ClicMainSideMenu from '../../components/clicMainSideMenu/ClicMainSideMenu.vue';
  export default {
    name: 'LayoutMenuTopoConteudo',
    watch: {
      '$route' (to, from) {
          this.register();
      }
    },
    components: {
      ClicMainSideMenu,
    },

    created(){
     if  (this.lodash.has(this.$route, 'meta.events.created')) {
        try{
          eval(this.lodash.get(this.$route, 'meta.events.created'))
        }catch(e){
           console.error(`[LayoutMenuTopoConteudo] Falha ao executar evento 'created'. Detalhes: ${e}`);
        }
      }
    },
    
    mounted(){
      if  (this.lodash.has(this.$route, 'meta.events.mounted')) {
        try{
          eval(this.lodash.get(this.$route, 'meta.events.mounted'))
        }catch(e){
           console.error(`[LayoutMenuTopoConteudo] Falha ao executar evento 'mounted'. Detalhes: ${e}`);
        }
      }
    },

    updated () {
      if  (this.lodash.has(this.$route, 'meta.events.updated')) {
        try {
          eval(this.lodash.get(this.$route, 'meta.events.updated'));
        } catch (e) {
          console.error(`[LayoutMenuTopoConteudo] falha na execução do evento 'updated'. Detalhes: ${e}`);
        }
      }
    },
      
    methods: {

      register() {
        if (!this.$store.hasModule(this.$route.name)){
          this.$store.registerModule(`${this.$route.name}`, {
            namespaced: true,
            state(cp) {
              return {
                propriedades: {},
              }
            },
            getters: {
              getPropriedades(state) {
                return state.propriedades
              },
            },
            mutations: {
              setPropriedades(state, propriedades) {
                if(propriedades){
                  state.propriedades = {...state.propriedades, ...propriedades};
                }
              },
            },
          })
        }
      }

    }
  };
</script>
