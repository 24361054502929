import GeneralProperties from '../../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // label                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // required                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // fieldState               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // jsonId                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // name                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // readonly                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // autocomplete             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // invalidFeedbackMessage   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // validateScript           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // style                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
            
        // disabled                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // maskOptions              :{
        //     hintTitle: "",
        //     hintText:""
        // },
            
        // blur    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // change  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // input   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // keyup   :{
        //     hintTitle: "",
        //     hintText:""
        // },
       
    }
}
