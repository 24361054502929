var lodash = require('lodash');

const generateUniqueStringBasedOnTimestamp = (prefix) => {
    let out = new Date().getTime() * Math.random() * 100000;
    return ((prefix || '') + out + 'cp');
}

const getComponentID = (id) => {
    if  (id) {
        return id;
    } else {
        return generateUniqueStringBasedOnTimestamp('clic');
    }
}

module.exports = {
    generateUniqueStringBasedOnTimestamp,
    getComponentID,
}