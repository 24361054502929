
<template>
    <ClicColInterno
        :cp="cp"
        :key="componentKey"
    >
        <b-input-group 
            :append="append"  
			:appendHtml="appendHtml"  
			:id="id"  
			:prepend="prepend"  
			:prependHtml="prependHtml"  
			:size="size"  
			:tag="tag"  
			v-model="vModel" 
			:style="this.style" 
			:class="this.class" 
			
            
        >
            
		<template v-slot:append="slotProps" v-if="$getSlot('append')">
            <MotorS 
                :cp="cp" 
                :childrens="$getSlot('append')"
                :idTela="idTela"
                :state="state"
            />
        </template>
		<template v-slot:default="slotProps" v-if="$getSlot('default')">
            <MotorS 
                :cp="cp" 
                :childrens="$getSlot('default')"
                :idTela="idTela"
                :state="state"
            />
        </template>
		<template v-slot:prepend="slotProps" v-if="$getSlot('prepend')">
            <MotorS 
                :cp="cp" 
                :childrens="$getSlot('prepend')"
                :idTela="idTela"
                :state="state"
            />
        </template>
        </b-input-group>
    </ClicColInterno>
</template>
  
<script>
import ComponentMixin from '../../core/ComponentMixin';

export default {
    name:"ClicInputGroup",
    mixins: [ComponentMixin],
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>
