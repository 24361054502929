import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicIconInterno from '../clicIcon/ClicIconInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';

import ClicInputDocs from './ClicInputDocs';

export default {
    ClicInput : {
        config      : {icon: "md-menuopen", documentation: ClicInputDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            label                   : { type: 'string',     default: undefined, component: { name : 'input' } },
            invalidFeedbackMessage  : { type: 'string',     default: undefined, component: { name : 'input' } },
            validateScript          : { type: 'string',     default: undefined, component: { name : 'code'  } },
            fieldState              : { type: 'boolean',    default: undefined, component: { name : 'switch'} },
            jsonId                  : { type: 'string',     default: undefined, component: { name : 'input' } },

            ariaInvalid             : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            autocomplete            : { type: 'string',     default: 'off',     component: { name : 'input' } },
            autofocus               : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            debounce                : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            disabled                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            form                    : { type: 'string',     default: undefined, component: { name : 'input' } },
            formatter               : { type: 'function',   default: undefined, component: { name : 'code' } },
            lazy                    : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            lazyFormatter           : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            list                    : { type: 'string',     default: undefined, component: { name : 'input' } },
            max                     : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            min                     : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            name                    : { type: 'string',     default: undefined, component: { name : 'input' } },
            noWheel                 : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            number                  : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            placeholder             : { type: 'string',     default: undefined, component: { name : 'input' } },
            plaintext               : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            readonly                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            required                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            size                    : { type: 'string',     default: undefined, component: { name : 'input' } },
            step                    : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            trim                    : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            type                    : { type: 'string',     default: undefined, component: { name : 'input' } },
            value                   : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            autocapitalize          : { type: 'string',     default: undefined, component: { name : 'input' } },

            ...ClicColInterno,
            ...ClicIconInterno,
            ...ClicHintInterno,

        },
        events : {
            blur    : {type: 'string', component: { name : 'code' } },
            change  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
            update  : {type: 'string', component: { name : 'code' } },
            keyup   : {type: 'string', component: { name : 'code' } },
        },
    }
}
