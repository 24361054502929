import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
      
        // label                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // responsePath            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // labels                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // axios                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // invalidFeedbackMessage  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // validateScript          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // required                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // fieldState              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // jsonId                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // searchDelayInMs         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        
        // options                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // multiple                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // trackBy                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // searchable              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // clearOnSelect           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hideSelected            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // placeholder             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // allowEmpty              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // resetAfter              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // closeOnSelect           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // customLabel             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // taggable                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // tagPlaceholder          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // tagPosition             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // max                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // optionsLimit            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // groupValues             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // groupLabel              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // groupSelect             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // blockKeys               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // internalSearch          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // preserveSearch          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // preselectFirst          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // name                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // selectLabel             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // selectGroupLabel        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // selectedLabel           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // deselectLabel           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // deselectGroupLabel      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // showLabels              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // limit                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // limitText               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // loading                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // disabled                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // maxHeight               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // openDirection           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // showNoResults           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // tabindex                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // showPointer             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // optionHeight            :{
        //     hintTitle: "",
        //     hintText:""
        // },

        
        // input           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // select          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // remove          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // searchChange    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // tag             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // open            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // close           :{
        //     hintTitle: "",
        //     hintText:""
        // },
      
    }
}
