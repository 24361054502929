import ClicIconInternoDocs from "./ClicIconInternoDocs"

export default {
    iconFW : {
        config          : {documentation: ClicIconInternoDocs.documentation},
        
        iconName        : {type: 'string',  default: undefined, component: { name : 'input' } },
        iconScale       : {type: 'number',  default: undefined, component: { name : 'input' } },
        iconAnimation   : {type: 'string',  default: undefined, component: { name : 'select', values: ['wrench', 'ring', 'pulse', 'spin', 'spin-pulse', 'flash', 'float'] } },
        iconSpeed       : {type: 'string',  default: undefined, component: { name : 'select', values: ['slow', 'fast'] } },
        iconHover       : {type: 'boolean', default: undefined, component: { name : 'switch' } },
        iconFlip        : {type: 'string',  default: undefined, component: { name : 'select', values: ['vertical', 'horizontal', 'both'] } },
        iconFill        : {type: 'string',  default: undefined, component: { name : 'input' } },
        iconLabel       : {type: 'string',  default: undefined, component: { name : 'input' } },
        iconTitle       : {type: 'string',  default: undefined, component: { name : 'input' } },
        iconInverse     : {type: 'boolean', default: undefined, component: { name : 'switch' } },
        iconText        : {type: 'string',  default: undefined, component: { name : 'input' } },
    }   
}