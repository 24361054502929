import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
     
        // label               :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // atributeId          :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // atributeLabel       :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // atributeChildren    :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // responsePath        :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // axios               :{
        //     hintTitle: "",
        //     hintText:""
        // },  

        // model               :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // defaultTreeNodeName :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // defaultLeafNodeName :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // defaultExpanded	    :{
        //     hintTitle: "",
        //     hintText:""
        // },  
    
        
        // click           :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // doubleClick     :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // changeName      :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // deleteNode      :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // addNode         :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // drop            :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // dropBefore      :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        // dropAfter       :{
        //     hintTitle: "",
        //     hintText:""
        // },  
      
    }
}