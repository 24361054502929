import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicIconDocs from './ClicIconDocs'

export default {
    ClicIcon : {
        config      : {icon: "md-datathresholding", documentation: ClicIconDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            text        : { type: 'string',     default: undefined, component: { name : 'input' } },
            name        : { type: 'string',     default: undefined, component: { name : 'input' } },
            scale       : { type: 'number',     default: undefined, component: { name : 'input' } },
            animation   : { type: 'string',     default: undefined, component: { name : 'input' } },
            speed       : { type: 'string',     default: undefined, component: { name : 'input' } },
            hover       : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            flip        : { type: 'string',     default: undefined, component: { name : 'input' } },
            fill        : { type: 'string',     default: undefined, component: { name : 'input' } },
            label       : { type: 'string',     default: undefined, component: { name : 'input' } },
            title       : { type: 'string',     default: undefined, component: { name : 'input' } },
            inverse     : { type: 'boolean',    default: undefined, component: { name : 'switch' } },

            ...ClicColInterno

        },
        events : { 
        }
    }
}









