<template>
    <ClicColInterno
        alignSelf="end"
        :cp="cp"
        :key="componentKey"
    >
        <div id="swagger-ui"></div>
    </ClicColInterno>
</template>

<script>
import ComponentMixin from '../../core/ComponentMixin';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui-dist/swagger-ui.css';

export default {
    name: "ClicSwagger",
    mixins: [ComponentMixin],

    mounted() {
    },

    data() {
        return {
            spec : undefined,
            url : undefined
        };
    },
    
    watch: {
        url(url) {
            this.dataRefresh();
        },
        spec(spec) {
            this.dataRefresh();
        },
    },

    methods: {

        dataRefresh () {
            let options = {
                dom_id: "#swagger-ui",
                presets: [
                    SwaggerUI.presets.apis,
                    SwaggerUI.SwaggerUIStandalonePreset
                ],
                layout: "BaseLayout", 
            }
            if  (this.url) {
                options.url = this.url;
            }
            if  (this.spec) {
                options.spec = this.spec;
            }
            SwaggerUI(options);
        }
    }
}
</script>

<style>
    .swagger-ui {
        background :#fafafa;
    }
    .topbar {
        background: #ffffff !important;
    }
    
    /*
    .topbar-wrapper img {
        content : url('https://cdnfw.nyc3.digitaloceanspaces.com/framework/admfw/imagens/identidade/logo.png') !important;
    }*/

    .download-url-wrapper {
        display: none !important;
    }

    .swagger-ui table thead tr th {
        background-color: transparent;
    }

    .version-stamp {
        visibility: hidden;
    }
</style>