import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        // jsonId  :{
        //     hintTitle: "",
        //     hintText:""
        // },

        // label   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value   :{
        //     hintTitle: "",
        //     hintText:""
        // },
          
        // change  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // input   :{
        //     hintTitle: "",
        //     hintText:""
        // },

    }
}