import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // align               : {
        //     hintTitle: "",
        //     hintText:""
        // },

        // bgVariant           : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyBgVariant       : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyBorderVariant   : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyClass           : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyTag             : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyTextVariant     : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // borderVariant       : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // footer              : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerBgVariant     : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerBorderVariant : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerClass         : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerHtml          : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerTag           : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerTextVariant   : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // header              : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerBgVariant     : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerBorderVariant : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerClass         : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerHtml          : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerTag           : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerTextVariant   : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgAlt              : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgBottom           : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgEnd              : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgHeight           : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgLeft             : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgRight            : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgSrc              : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgStart            : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgTop              : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // imgWidth            : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // noBody              : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // overlay             : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // subTitle            : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // subTitleTag         : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // subTitleTextVariant : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // tag                 : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // textVariant         : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // title               : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // titleTag            : {
        //     hintTitle: "",
        //     hintText:""
        // },
    },
}
