<template>
    <ClicColInterno :cp="cp" :key="componentKey">
        <vue-file-toolbar-menu :style="style" :content="contentTratado"
            :class="removeCard == true ? 'clicToolbar remove-card-toolbar' : 'clicToolbar'" />
    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import VueFileToolbarMenu from 'vue-file-toolbar-menu';

export default {
    name: 'ClicToolbar',
    mixins: [ComponentMixin],
    components: { VueFileToolbarMenu },

    watch: {
        content(newValue, oldValue) {
            if (newValue.length) {
                let _this = this;
                this.$nextTick(() => {
                    this.contentTratado = this.obterContentTratado(newValue);
                })
            }
        }
    },

    data() {
        return {
            content: [],
            contentTratado: [],
            isMac: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),

            happy: false,
        }
    },

    methods: {
        obterContentTratado(conteudo) {
            let _this = this;
            let montarEventoCLick = (eventoStr) => {
                return () => {
                    try {
                        let click = eventoStr.replaceAll('this', '_this');
                        eval(click);
                    } catch (e) {
                        console.error(`[ClicToolbar] Falha ao executar evendo 'click'. Detalhes: ${e}`);
                    }
                }
            }

            let obterHotkey = (hotkeyObj) => {
                if (hotkeyObj) {
                    if (this.isMac) {
                        return hotkeyObj.mac || (hotkeyObj.win && hotkeyObj.win.toLowerCase().replaceAll('ctrl', 'command').replaceAll('alt', 'option')) || '';

                    } else if (!this.isMac) {
                        return hotkeyObj.win || '';
                    }
                } else {
                    return '';
                }
            }

            let obterTextoTraduzido = (text) => {
                return this.$storage.getTextoTraduzido(text);
            }

            let conteudoStr = JSON.stringify(conteudo);
            let retorno = JSON.parse(
                conteudoStr, (k, v) =>
                k == 'click' ? montarEventoCLick(v) :
                    k == 'hotkey' ? obterHotkey(v) :
                        k == 'text' ? obterTextoTraduzido(v) : v
            );
            return retorno;
        }
    }
}
</script>

<style>
:root {
    --bar-font-color: #526484;
    --bar-font-family: var(--font-family-sans-serif);
    --bar-font-size: 15.5px;
    --bar-button-icon-size: 20px;
    --bar-button-padding: 4px 7px 5px 7px;
    --bar-button-radius: 0;
    --bar-button-hover-bkg: none;
    --bar-button-active-color: white;
    --bar-button-active-bkg: var(--cor-principal) !important;
    --bar-button-open-color: white;
    --bar-button-open-bkg: var(--cor-principal) !important;
    --bar-menu-bkg: rgba(255, 255, 255, 0.95);
    --bar-menu-backdrop-filter: saturate(180%) blur(20px);
    --bar-menu-backdrop-filter-bkg: #fff;
    --bar-menu-border: solid 1px #BBB;
    --bar-menu-border-radius: 0 0 6px 6px;
    --bar-menu-item-chevron-margin: 0;
    --bar-menu-item-hover-color: white;
    --bar-menu-item-hover-bkg: var(--cor-principal) !important;
    --bar-menu-item-padding: 1px 12px 2px 25px;
    --bar-menu-item-icon-size: 16px;
    --bar-menu-item-icon-margin: 0 4px 0 -20px;
    --bar-menu-padding: 3px 0;
    --bar-menu-shadow: 0 6px 13px 0 rgba(60, 60, 60, 0.4);
    --bar-menu-separator-height: 2px;
    --bar-menu-separator-margin: 5px 0;
    --bar-menu-separator-color: rgba(0, 0, 0, 0.08);
    --bar-separator-color: rgba(0, 0, 0, 0.1);
    --bar-separator-width: 2px;
    --bar-separator-margin: 5px 7px;
    --bar-sub-menu-border-radius: 6px;
}
</style>

<style lang="scss" rel="stylesheet/less" scoped>
.clicToolbar {
    width: 100%;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.remove-card-toolbar {
    margin-top: 12px;
    background-color: transparent !important;
    border: none !important;
}
</style>