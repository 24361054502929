import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
            
        path           :{
            hintTitle: "Path",
            hintText:`
            <p>Define o <b style='color:#343a40!important'> Path </b> da rota, o caminho da URL para esta tela.</p>
            <br>
            <p style="font-size:13px">Utilize nomes que indiquem o que a rota faz ou o que é, evite códigos ou abreviações desnecessárias.</p>
            `
        },  
        authenticated  :{
            hintTitle: "Authenticated",
            hintText:`
                <p>Define se a rota é protegida ou não. </p>
                <br>
                <p style="font-size:13px">Quando definido como <b style='color:#343a40!important'> true </b>, será necessário estar logado no sistema para acessar a rota.</p>
            `
        },  
        isLogin        :{
            hintTitle: "isLogin",
            hintText:`
                Irá definir se é a tela de login ou não. 
                <p style="font-size:13px">Cada cliente deve ter apenas uma rota com a propriedade 'isLogin' definida como <b style='color:#343a40!important'> true</b>. </p>
            `
        },  
        isHome         :{
            hintTitle: "isHome",
            hintText:`
                <p>Irá definir se é a tela principal do sistema ou não. </p>
                <br>
                <p style="font-size:13px">Cada cliente deve ter apenas uma rota com a propriedade 'isHome' definida como <b style='color:#343a40!important'> true</b>. </p>
            `
        },  
    }
}
