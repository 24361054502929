
import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
            append: {
                hintTitle: "append",
                hintText: "type = string<br> default=null<br> doc-url = <a target='_blank' href='https:/bootstrap-vue.org/docs/components/input-group/'>Doc Link</a><br> description = Text to append to the input group"
            },
        
            appendHtml: {
                hintTitle: "appendHtml",
                hintText: "type = string<br> default=null<br> doc-url = <a target='_blank' href='https:/bootstrap-vue.org/docs/components/input-group/'>Doc Link</a><br> description = HTML string to append to the input group. Has precedence over 'append' prop"
            },
        
            id: {
                hintTitle: "id",
                hintText: "type = string<br> default=null<br> doc-url = <a target='_blank' href='https:/bootstrap-vue.org/docs/components/input-group/'>Doc Link</a><br> description = Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed"
            },
        
            prepend: {
                hintTitle: "prepend",
                hintText: "type = string<br> default=null<br> doc-url = <a target='_blank' href='https:/bootstrap-vue.org/docs/components/input-group/'>Doc Link</a><br> description = Text to prepend to the input group"
            },
        
            prependHtml: {
                hintTitle: "prependHtml",
                hintText: "type = string<br> default=null<br> doc-url = <a target='_blank' href='https:/bootstrap-vue.org/docs/components/input-group/'>Doc Link</a><br> description = HTML string to prepend to the input group. Has precedence over 'prepend' prop"
            },
        
            size: {
                hintTitle: "size",
                hintText: "type = string<br> default=null<br> doc-url = <a target='_blank' href='https:/bootstrap-vue.org/docs/components/input-group/'>Doc Link</a><br> description = Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'"
            },
        
            tag: {
                hintTitle: "tag",
                hintText: "type = string<br> default=div<br> doc-url = <a target='_blank' href='https:/bootstrap-vue.org/docs/components/input-group/'>Doc Link</a><br> description = Specify the HTML tag to render instead of the default tag"
            },
        
    }
}
