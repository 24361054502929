import ClicHintInternoDocs from "./ClicHintInternoDocs"

export default {
    hintFW : {
        config: { documentation: ClicHintInternoDocs.documentation },
        
        hintTitle   : { type: 'string',  default: {
                                                    PT:'Informação',
                                                    EN:'Information'
                                                }, component: { name : 'input' } },
        hintText    : { type: 'string',  default: undefined,    component: { name : 'input' } },    
    }   
}