import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicTabDocs from './ClicTabDocs'

export default {
    ClicTab : {
        config      : {isContainer:true, icon:"md-tablechart", documentation: ClicTabDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            active              : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            buttonId            : {type: 'string',      default: undefined, component: { name : 'input' } },
            disabled            : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            lazy                : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            noBody              : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            tag                 : {type: 'string',      default: undefined, component: { name : 'input' } },
            title               : {type: 'string',      default: undefined, component: { name : 'input' } },
            titleItemClass      : {type: 'multiple',    default: undefined, component: { name : 'input' } },
            titleLinkAttributes : {type: 'object',      default: undefined, component: { name : 'input' } },
            titleLinkClass      : {type: 'multiple',    default: undefined, component: { name : 'input' } },
           
            ...ClicColInterno,

        },
        events : {
            click               : {type: 'string', component: { name : 'code' } },
        },
    }
}