import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicImageInterno from '../clicImage/ClicImageInternoModel';
import ClicIconInterno from '../clicIcon/ClicIconInternoModel';

import ClicButtonDocs from './ClicButtonDocs';

export default {
    ClicButton : {
        config      : {icon:"md-smartbutton", documentation: ClicButtonDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            label                   : {type: 'string',      default: undefined,     component: { name : 'input' } },

            active                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            activeClass             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            append                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            block                   : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            disabled                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            exact                   : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            exactActiveClass        : {type: 'string',      default: undefined,     component: { name : 'input'  } },
            exactPath               : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            exactPathActiveClass    : {type: 'string',      default: undefined,     component: { name : 'input'  } },
            href                    : {type: 'string',      default: undefined,     component: { name : 'input'  } },
            noPrefetch              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            pill                    : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            prefetch                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            pressed                 : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            rel                     : {type: 'string',      default: undefined,     component: { name : 'input'  } },
            replace                 : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            routerComponentName     : {type: 'string',      default: undefined,     component: { name : 'input'  } },
            size                    : {type: 'string',      default: undefined,     component: { name : 'input'  } },
            squared                 : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            tag                     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            target                  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            to                      : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            type                    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            variant                 : {type: 'string',      default: undefined,     component: { name : 'select', values: 
                                        [   'link',
                                            'primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark',  
                                            'outline-primary', 'outline-secondary', 'outline-success', 'outline-warning', 'outline-danger', 'outline-info', 'outline-light', 'outline-dark'
                                        ]} 
                                    },
            
            ...ClicColInterno,
            ...ClicIconInterno,
            ...ClicImageInterno
        },
        events : {
            click           : {type: 'string', component: { name : 'code' } },
            clickPromise    : {type: 'string', component: { name : 'code' } },
        }
    }
}









