<template>
  <div id="app">
    <!--<vue-pull-refresh :on-refresh="onRefresh" :config="config"> -->
    <router-view/>
    <ClicCopilot/>
    <!-- <ClicSession/> -->
    <!-- </vue-pull-refresh> -->
    <ClicNotificationInterno/>
  </div>
</template>

<script>

  import Vue from 'vue';
  import VuePullRefresh from 'vue-pull-refresh';
  import socketDefaultService from './services/socketDefaultService';
  import socketServerService from './services/socketServerService';
  import socketTenantService from './services/socketTenantService.js';


  export default {
    
    components: {
      'vue-pull-refresh': VuePullRefresh,
    },

    data(){
      return {
        config:{
          errorLabel: "Ocorreu um erro ao atualizar, tente novamente mais tarde...",
          startLabel: "Puxe para recarregar a página",
          readyLabel: "A página será recarregada",
          loadingLabel: "Carregando...",
          pullDownHeight:"120",
        },
      }
    },

    created() {  
      const subdominio  = (this.$host && this.$host.subdominio);
      Vue.prototype.$socket = socketTenantService.socketTenant;
      if  (subdominio == "admfw") {
        Vue.prototype.$globalSocket = socketDefaultService.socketDefault;
        Vue.prototype.$serverSocket = socketServerService.socketServer;
      }  
      socketDefaultService.setupSocketConnection();

      // carregar o CSS do tenant, conforme subdominio
      
      let cssTenant   = document.createElement('link');
      cssTenant.rel   = 'stylesheet';
      
      cssTenant.href  = `${process.env.VUE_APP_BUCKET_URL}/${subdominio}/css/customizado.css`;
      document.head.appendChild(cssTenant); 
    },

    mounted() {
    },

    beforeUnmount() {
      socketDefaultService.disconnect();
    },
    
    methods: {
      onRefresh () {
        document.location.reload()
        window.ReactNativeWebView.postMessage("refresh");
      },
    }
  };
</script>


<style>

  
</style>

<style lang="scss">
/*
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100vw;
    height: 100vh;
  }
*/

#app {
  height: 100%;
}

html,body {
  height: 100%;
  margin: 0;
  padding: 0;
}

</style>



