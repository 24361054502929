// assistantExpressions.js
const assistantExpressions = {
    amedrontada: require("@/assets/theme/images/assistant-memoji/amedrontada.png"),
    apaixonada: require("@/assets/theme/images/assistant-memoji/apaixonada.png"),
    beleza: require("@/assets/theme/images/assistant-memoji/beleza.png"),
    bocejando: require("@/assets/theme/images/assistant-memoji/bocejando.png"),
    brava: require("@/assets/theme/images/assistant-memoji/brava.png"),
    cabecaExplodindo: require("@/assets/theme/images/assistant-memoji/cabecaExplodindo.png"),
    chocada: require("@/assets/theme/images/assistant-memoji/chocada.png"),
    chorando: require("@/assets/theme/images/assistant-memoji/chorando.png"),
    chorandoDeRir: require("@/assets/theme/images/assistant-memoji/chorandoDeRir.png"),
    comemorando: require("@/assets/theme/images/assistant-memoji/comemorando.png"),
    confusa: require("@/assets/theme/images/assistant-memoji/confusa.png"),
    contandoSegredo: require("@/assets/theme/images/assistant-memoji/contandoSegredo.png"),
    coracaoNosOlhos: require("@/assets/theme/images/assistant-memoji/coracaoNosOlhos.png"),
    decepcionada: require("@/assets/theme/images/assistant-memoji/decepcionada.png"),
    distraida: require("@/assets/theme/images/assistant-memoji/distraida.png"),
    doente: require("@/assets/theme/images/assistant-memoji/doente.png"),
    doisDedos: require("@/assets/theme/images/assistant-memoji/doisDedos.png"),
    entediada: require("@/assets/theme/images/assistant-memoji/entediada.png"),
    envergonhada: require("@/assets/theme/images/assistant-memoji/envergonhada.png"),
    espantada: require("@/assets/theme/images/assistant-memoji/espantada.png"),
    entusiasmada: require("@/assets/theme/images/assistant-memoji/entusiasmada.png"),
    extrovertida: require("@/assets/theme/images/assistant-memoji/extrovertida.png"),
    feliz: require("@/assets/theme/images/assistant-memoji/feliz.png"),
    focada: require("@/assets/theme/images/assistant-memoji/focada.png"),
    furiosa: require("@/assets/theme/images/assistant-memoji/furiosa.png"),
    gesto: require("@/assets/theme/images/assistant-memoji/gesto.png"),
    gestoBeijo: require("@/assets/theme/images/assistant-memoji/gestoBeijo.png"),
    gestoCoracao: require("@/assets/theme/images/assistant-memoji/gestoCoracao.png"),
    gestoCoracaoComDedos: require("@/assets/theme/images/assistant-memoji/gestoCoracaoComDedos.png"),
    gestoMaosParaBaixo: require("@/assets/theme/images/assistant-memoji/gestoMaosParaBaixo.png"),
    gestoOla: require("@/assets/theme/images/assistant-memoji/gestoOla.png"),
    gestoPare: require("@/assets/theme/images/assistant-memoji/gestoPare.png"),
    gestoIdeia: require("@/assets/theme/images/assistant-memoji/gestoIdeia.png"),
    joinha: require("@/assets/theme/images/assistant-memoji/joinha.png"),
    mandandoBeijo: require("@/assets/theme/images/assistant-memoji/mandandoBeijo.png"),
    meditando: require("@/assets/theme/images/assistant-memoji/meditando.png"),
    nocauteada: require("@/assets/theme/images/assistant-memoji/nocauteada.png"),
    normal: require("@/assets/theme/images/assistant-memoji/normal.png"),
    ofendendo: require("@/assets/theme/images/assistant-memoji/ofendendo.png"),
    ola: require("@/assets/theme/images/assistant-memoji/ola.png"),
    pensativa: require("@/assets/theme/images/assistant-memoji/pensativa.png"),
    piscada: require("@/assets/theme/images/assistant-memoji/piscada.png"),
    rezando: require("@/assets/theme/images/assistant-memoji/rezando.png"),
    silencio: require("@/assets/theme/images/assistant-memoji/silencio.png"),
    sonolenta: require("@/assets/theme/images/assistant-memoji/sonolenta.png"),
    soquinho: require("@/assets/theme/images/assistant-memoji/soquinho.png"),
    surpresa: require("@/assets/theme/images/assistant-memoji/surpresa.png"),
    torcendo: require("@/assets/theme/images/assistant-memoji/torcendo.png"),
    triste: require("@/assets/theme/images/assistant-memoji/triste.png"),
    vapo: require("@/assets/theme/images/assistant-memoji/vapo.png"),
};


module.exports = assistantExpressions;
