<template>
  <ClicColInterno
    alignSelf="end"
    :cp="cp"
    :key="componentKey"
  >
    <div class="form-group">
      <div v-if="(alignSelf || 'end') == 'end'">&nbsp;</div>
      <div class="form-control-wrap">
        <b-form-checkbox
          :aria-label="ariaLabel"
          :aria-labelledby="ariaLabelledby"
          :autofocus="autofocus"
          :button="button"
          :button-variant="buttonVariant"
          :checked="checked"
          :disabled="disabled"
          :form="form"
          :indeterminate="indeterminate"
          :inline="inline"
          :name="name"
          :plain="plain"
          :required="required"
          :size="size"
          :state="state"
          :switch=false
          :unchecked-value="uncheckedValue"
          v-model="value"
          @change="change"
          @input="input"
        >
          {{ $storage.getTextoTraduzido(label) }}
          <ClicHintInterno
            v-if="hintText"
            :cp="cp"
          />
      </b-form-checkbox>
      </div>
    </div>
     
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicCheckbox',
  mixins: [ComponentMixin],

  methods: {
    
    change(checked) {
      try {
        if  (this.lodash.has(this.cp, 'events.change')) {
          eval(this.cp.events.change);
        } 
      } catch (e) {
        console.error(`[ClicCheckbox] Falha ao executar evendo 'change'. Detalhes: ${e}`);
      }
    },
    
    input(checked) {
      try {
        if  (this.lodash.has(this.cp, 'events.input')) {
          eval(this.cp.events.input);
        } 
      } catch (e) {
        console.error(`[ClicCheckbox] Falha ao executar evendo 'input'. Detalhes: ${e}`);
      }
    }

  },
};
</script>