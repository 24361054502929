<template>
    <b-dropdown-divider
        :tag="lodash.get(cp, 'props.tag', 'hr')"
        :style="lodash.get(cp, 'props.tag')"
        :class="lodash.get(cp, 'props.tag')"
    >
    </b-dropdown-divider>
</template>
<script>

export default {
    props: {
        cp: Object
    },
};
</script>

<style scoped>

    .dropdown-divider {
        margin: 0px;
    }

</style>
