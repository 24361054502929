import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';
import ClicTreeViewDocs from './ClicTreeViewDocs';

export default {
    ClicTreeView : {
        config      : {icon: "md-streetview-sharp", documentation: ClicTreeViewDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            label               : {type: 'string',      default: undefined,     component: { name : 'input' } },
            atributeId          : {type: 'string',      default: undefined,     component: { name : 'input' } },
            atributeLabel       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            atributeChildren    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            responsePath        : {type: 'string',      default: undefined,     component: { name : 'input' } },
            axios               : {type: 'string',      default: undefined,     component: { name : 'code' } },

            model               : {type: 'object',      default: {
                                                                    id: 0, 
                                                                    parent: null, 
                                                                    children: null, 
                                                                    isLeaf: false, 
                                                                    name: 'root'
                                                                },              component: { name : 'code' } },
            defaultTreeNodeName : {type: 'string',      default: '',            component: { name : 'input' } },
            defaultLeafNodeName : {type: 'string',      default: '',            component: { name : 'input' } },
            defaultExpanded	    : {type: 'boolean',     default: false,         component: { name : 'switch' } },

            addTreeNodeIcon     : {type: 'string',      default: undefined,     component: { name : 'select', values : ['md-createnewfolder-outlined'] } },
            addLeafNodeIcon     : {type: 'string',      default: undefined,     component: { name : 'select', values : ['md-playlistadd-round'] } },
            editNodeIcon        : {type: 'string',      default: undefined,     component: { name : 'select', values : ['md-editnote-round'] } },
            delNodeIcon         : {type: 'string',      default: undefined,     component: { name : 'select', values : ['md-deletesweep-round'] } },
            leafNodeIcon        : {type: 'string',      default: undefined,     component: { name : 'select', values : ['fa-file'] } },
            treeNodeIcon        : {type: 'string',      default: undefined,     component: { name : 'select', values : ['md-folder-outlined'] } },
        
            ...ClicColInterno,
            ...ClicHintInterno,   
        },
        events : {
            click           : {type: 'string', component: { name : 'code' } },
            doubleClick     : {type: 'string', component: { name : 'code' } },
            changeName      : {type: 'string', component: { name : 'code' } },
            deleteNode      : {type: 'string', component: { name : 'code' } },
            addNode         : {type: 'string', component: { name : 'code' } },
            drop            : {type: 'string', component: { name : 'code' } },
            dropBefore      : {type: 'string', component: { name : 'code' } },
            dropAfter       : {type: 'string', component: { name : 'code' } },
        }
    }
}