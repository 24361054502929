const openPdfFromBase64 = ({ b64Pdf, fileName }) => {
    let blob = base64toBlob(b64Pdf);
    const pdfInformation = getBase64PdfInformation(b64Pdf);

    if(window.ReactNativeWebView){
        let objBase64Mobile = {
            "type"     : "base64",
            "string"   : `${b64Pdf}`,
            "filename" : `${pdfInformation.title}`
        };

        window.ReactNativeWebView.postMessage(JSON.stringify(objBase64Mobile));
    };

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName || `${pdfInformation.title}.pdf`);
    } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
    }
}

const downloadPdfFromBase64 = ({ b64Pdf, fileName }) => {
    const pdfInformation = getBase64PdfInformation(b64Pdf);
    var a = document.createElement("a");
    a.setAttribute("download", fileName || `${pdfInformation.title}.pdf`);
    a.setAttribute("href", `data:application/octet-stream;base64, ${b64Pdf}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const openPdfFromUrl = ({ url }) => {
    window.open(url, '_blank');
}

const downloadPdfFromUrl = ({ url, fileName }) => {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName || getPDFFileNameFromURL(url);
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        })
    .catch(error => {
        console.error('Falha ao baixar o PDF. Detalhes: ', error);
    });
}

const getBase64PdfInformation = (b64Pdf) => {
    let title = "relatório.pdf"
    let fileSize = "0.0KB";
    let pdfVersion = "";
    try {
        var bin = atob(b64Pdf);
        const titleMatch = bin.match(/\/Title\s*\((.*?)\)/);
        if (titleMatch && titleMatch[1]) {
            title = convertOctalToASCII(titleMatch[1]);
        }
        fileSize = Math.round(bin.length / 1024) + 'KB';
        pdfVersion = bin.match(/^.PDF-([0-9.]+)/)[1];
    } catch (e) {
        console.error("Falha ao obter informações do PDF. Detalhes: ", e);
    }
    return { title, fileSize, pdfVersion };
}


const base64toBlob = (base64Data) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
}

const convertOctalToASCII = (text) => {
    return text.replace(/\\(\d{3})/g, (match, octal) =>
      String.fromCharCode(parseInt(octal, 8))
    );
}

const getPDFFileNameFromURL = (url) => {
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const decodedFileName = decodeURIComponent(fileName);
    const queryParamIndex = decodedFileName.indexOf('?');
    if (queryParamIndex !== -1) {
      return decodedFileName.substring(0, queryParamIndex);
    }
    return decodedFileName;
  }


module.exports = {
    openPdfFromBase64,
    downloadPdfFromBase64,
    getBase64PdfInformation,
    openPdfFromUrl,
    downloadPdfFromUrl
}