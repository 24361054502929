
import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicInputGroupDocs from './ClicInputGroupDocs';

export default {
    ClicInputGroup : {
        config      : {isContainer: true, icon: "md-dynamicfeed-round", documentation: ClicInputGroupDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            append					: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			appendHtml				: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			id						: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			prepend					: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			prependHtml				: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			size						: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			tag						: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			vModel					: {type: 'string',  default: undefined, component: { name : 'input' } }, 
			ref						: {type:  'string',  default: undefined, component: { name : 'input' } }, 
			
            ...ClicColInterno,
        },
        events : {
            
        },
    }
}

