import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicSwaggerDocs from './ClicSwaggerDocs'

export default {
    ClicSwagger : {
        config      : {icon:"bi-card-list", documentation: ClicSwaggerDocs.documentation }, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            url     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            spec    : {type: 'string',      default: undefined,     component: { name : 'code'  } },
  
            ...ClicColInterno   
        },
        events : {

        },
    }
}