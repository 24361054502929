<template>
    <draggable
        class=""
        style="background:pink;"
        :list="tasks"
        :group="{ name: 'g1' }"
    >
        <div
            class=""
            style="background:yellow;"
            v-for="element in tasks"
            :key="element.name"
        >
            {{ element.name }}
            <nested-draggable :tasks="element.children"/>
        </div>
    </draggable>
</template>
<script>
import draggable from 'vuedraggable';
export default {
  props: {
    tasks: {
      required: true,
      type: Array
    }
  },
  components: {
    draggable
  },
  name: "nested-draggable"
};
</script>
<style scoped>
    .dragArea {
        min-height: 50px;
        /*outline: 1px dashed;*/
    }
</style>