import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicInputFileDocs from './ClicInputFileDocs';

export default {
    ClicInputFile : {
        config      : {icon: "md-menuopen", documentation: ClicInputFileDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            value                   : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            label                   : { type: 'string',     default: undefined, component: { name : 'input' } },
            invalidFeedbackMessage  : { type: 'string',     default: undefined, component: { name : 'input' } },
            validateScript          : { type: 'string',     default: undefined, component: { name : 'code'  } },
            fieldState              : { type: 'boolean',    default: undefined, component: { name : 'switch'} },
            jsonId                  : { type: 'string',     default: undefined, component: { name : 'input' } },

            browseText              : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            accept                  : { type: 'string',     default: undefined, component: { name : 'input' } },
            autofocus               : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            capture                 : { type: 'multiple',   default: undefined, component: { name : 'switch' } },
            disabled                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            directory               : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            dropPlaceholder         : { type: 'string',     default: "Arraste os arquivos", component: { name : 'input' } },
            fileNameFormatter       : { type: 'string',     default: undefined, component: { name : 'code' } },
            multiple                : { type: 'boolean',    default: undefined,      component: { name : 'switch' } },
            noDrop                  : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            noDropPlaceholder       : { type: 'string',     default: undefined, component: { name : 'input' } },
            noTraverse              : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            placeholder             : { type: 'string',     default: "Arraste ou selecione arquivos", component: { name : 'input' } },
            plain                   : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            required                : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            size                    : { type: 'string',     default: undefined, component: { name : 'input' } },
            state                   : { type: 'boolean',    default: undefined, component: { name : 'switch' } },

            ...ClicColInterno


        },
        events : {
            change  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
        },
    }
}
