<template>
    <div class="floating-icon-copilot">

        <!-- <b-button id="show-btn" @click="$bvModal.show('session-modal')">Open Modal</b-button> -->

        <b-modal id="session-modal" title="Parece que sua sessão está expirando!">
            <div class="remaining-time">
                Tempo restante: {{ remainingTime }}
            </div>

            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="outline-warning" @click="$bvModal.hide('session-modal')">
                    Ignorar
                </b-button>
                <b-button size="sm" variant="info" @click="renewSession()">
                    Renovar sessão
                </b-button>
            </template>

        </b-modal>
    </div>
</template>
  
<script>
import Vue from 'vue';
import axios from 'axios';
import { BButton, BIcon } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import TokenService from "../../services/tokenService";

export default {
    name: 'ClicSession',
    components: {
        BButton,
        BIcon
    },
    data() {
        return {
            userId: "",
            validityRefresh: "",
            refreshToken: "",
            remainingTime: "",

            //Minutos restantes em que o modal aparecerá
            exposureModalTimes: [15, 10, 5, 4, 3, 2, 1]

        };
    },
    mounted() {

        this.initialize();
        this.startValidityCheck();

    },
    computed: {},
    methods: {
        initialize() {
            let userObj = this.$storage.getUserObj();
            this.userId = userObj?.id;
            // this.getStorageParameters()
        },
        //Pega os parametros do Local Storage
        getStorageParameters() {
            let userObj = this.$storage.getUserObj();
            this.validityRefresh = userObj?.validityRefresh;
            this.refreshToken = userObj?.refreshToken;
            this.userId = userObj?.id;
            this.startValidityCheck()
        },
        //Verifica se os parametros já existem
        startValidityCheck() {
            if (this.validityRefresh) {
                setInterval(this.checkValidity, 1000);
            } else {
                //Tenta de novo até que as variaveis sejam preenchidas, sinal de que o login foi feito
                setTimeout(() => { this.getStorageParameters() }, 5000);
            }
        },

        checkValidity() {

            //atualiza os parametros, pois se outra tela aberta no mesmo tenant atualzar o token é necessário saber
            let userObj = this.$storage.getUserObj();
            this.validityRefresh = userObj?.validityRefresh;
            this.refreshToken = userObj?.refreshToken;
            this.userId = userObj?.id;

            const currentDate = new Date();
            const timeDifference = new Date(this.validityRefresh) - currentDate;

            if (timeDifference > 0) {

                // Define os limites em milissegundos
                const limits = this.exposureModalTimes.map(minutes => minutes * 60 * 1000);

                for (const limit of limits) {
                    // A diferença está dentro do intervalo de 3 segundo do limite, exibe o modal
                    if (timeDifference <= limit && timeDifference > limit - 3000) {
                        this.$bvModal.show('session-modal')
                    }
                }

                //Tratamentos para o timer
                this.atualizarTimer(timeDifference)

            } else {
                this.remainingTime = `A sessão expirou!`;
            }


        },
        formatNumber(value) {
            if (value > 0) {
                return value < 10 ? `0${value}` : value;
            } else {
                return `00`
            }
        },
        atualizarTimer(timeDifference) {
            const hours = this.formatNumber(Math.floor(timeDifference / 3600000));
            const minutes = this.formatNumber(Math.floor((timeDifference % 3600000) / 60000));
            const seconds = this.formatNumber(Math.floor((timeDifference % 60000) / 1000));
            this.remainingTime = `${hours}:${minutes}:${seconds}`;
        },
        async renewSession() {

            var options = {
                method: 'POST',
                url: `https://admfw.clictecnologia.com.br/auth/refresh`,
                headers: { "content-type": "application/json" },
                data: {
                    refreshToken: this.refreshToken
                }
            };

            try {
                let response = await axios.request(options);
                TokenService.updateLocalAccessTokenAllProperties(response.data);

                this.$notificacao({ text: "Sessão renovada!", type: 'success' });
                this.$bvModal.hide('session-modal')

                return this.getStorageParameters()
            } catch (e) {
                return { error: e }
            }
        }

    }
};
</script>
  
<style>
.floating-icon-copilot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 997;

    @media screen and (max-width: 1100px) {
        bottom: 10px;
        right: 10px;
    }

    @media screen and (max-width: 400px) {
        bottom: 5px;
        right: 5px;
    }
}

.remaining-time {
    font-size: medium;
}
</style>
  