<template>
  <div>
   <b-button v-on:click="getLocate()">Exibe localização</b-button>
  </div>
</template>

<script>

export default {
    name: "ClicGeolocation",
    components: {
    },
    computed: {
        inRange() {
            const coords = this.$geolocation.coords
             console.log(this.$geolocation.coords)
        },
    },
    watch: {
        inRange(reached) {
            if (reached !== true) return
            console.log('Congratulations, you arrived')
           // this.locate = locate;
            getLocate();
            this.$geolocation.watch = false // Stop watching location
        }
       
    },
    methods: {
        async getLocate(){
            const locate = await this.$geolocation.getCurrentPosition()
            console.log(locate.coords, "locate")
            alert(JSON.stringify(locate.coords))
        }
    }   
}

</script>