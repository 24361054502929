import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicTextDocs from './ClicTextDocs'

export default {
    ClicText : {
        config      : {icon: "md-textfields", documentation: ClicTextDocs.documentation },  
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            tag                 : {type: 'string',      default: 'p',           component: { name : 'input' } },
            text                : {type: 'string',      default: undefined,     component: { name : 'input' } },
            value               : {type: 'string',      default: undefined,     component: { name : 'input' } },
            label               : {type: 'string',      default: undefined,     component: { name : 'input' } },
     
            ...ClicColInterno   
        },
        events : {
        }
    }
}