<template>
  <ClicColInterno
    :cp="cp"
    alignSelf="end"
    :key="componentKey"
  >
    <b-dropdown
      :block="block"
      :boundary="boundary"
      :disabled="disabled"
      :dropleft="dropleft"
      :dropright="dropright"
      :dropup="dropup"
      :html="html"
      :lazy="lazy"
      :menu-class="menuClass"
      :no-caret="noCaret"
      :no-flip="noFlip"
      :offset="offset"
      :popper-opts="popperOpts"
      :right="right"
      :role="role"
      :size="size"
      :split="split"
      :split-button-type="splitButtonType"
      :split-class="splitClass"
      :split-href="splitHref"
      :split-to="splitTo"
      :split-variant="splitVariant"
      text=""
      :toggle-attrs="toggleAttrs || { style : 'padding: 0.36rem 0.36rem !important;'}"
      :toggle-class="toggleClass"
      :toggle-tag="toggleTag"
      :toggle-text="toggleText"
      :variant="variant"
      :class="this.class"
    >
      <template slot="button-content">

        <ClicImageInterno
          v-if="lodash.has(cp.props, 'imageSrc')" 
          :cp="cp"
          imageWidth=25
          imageHeight=25
        />

        <div class="d-flex align-items-center">
          <ClicIconInterno
            :cp="cp"
            v-if="lodash.has(cp.props, 'iconName')"
          />
          <b :class="text ? 'ml-1' : ''">{{text}}</b>
          <v-icon 
            name="fa-angle-down"
            scale=1
            v-if="lodash.has(cp, 'props.text')"
          />
        </div>
      
      </template>
      
      <MotorS 
        :cp="cp" 
        :childrens="childrens"
        :idTela="idTela"
        :state="state"
      />

    </b-dropdown>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicDropdown',
  mixins: [ComponentMixin],
};
</script>


<style lang="scss" scoped>


  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgb(1 1 1 / 0%) !important;
  }

  .btn:hover {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: 0 0 0 3px rgb(1 1 1 / 0%) !important;
  }

  .ov-icon {
    overflow: hidden;
  }

  .cbutton {
    padding: 0.3rem 0.3rem !important;
  }
 
</style>