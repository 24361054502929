import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        scrollToTop :{
            hintTitle: "Informação",
            hintText:"Quando definido como verdadeiro, o gatilho para mudança de página ocorrerá no topo do container. Ex: Chat"
        },

        // items               :{
        //     hintTitle: "",
        //     hintText:""
        // },
            
    }
}