import ClicModalFooterDocs from "./ClicModalFooterDocs"

export default {
    ClicModalFooter : {
        config      : {isContainer:true, icon:"md-verticalalignbottom-round", documentation: ClicModalFooterDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            alignH  : {type: 'string',      default: 'end',         component: { name : 'input' } },
            alignV  : {type: 'string',      default: 'center',      component: { name : 'input' } },

        },
        events : {
        }
    }
}