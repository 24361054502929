<template>
  <ClicColInterno :cp="cp" :key="componentKey">
    <b-breadcrumb :items="items" :class="card ? 'breadcrumb-card-container' : ''" @click="click"></b-breadcrumb>
  </ClicColInterno>
</template>
<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicBreadcrumb',
  mixins: [ComponentMixin],
  data() {
    return {
      public: {
        getItemsToString: this.getItemsToString,
        setStringItems: this.setStringItems
      },
      items: []
    
    };
  },
  created() {
    this.verifyIsRoute()
  },
  methods: {
    click($event) {
      if (this.lodash.has(this.cp, 'events.click')) {
        try {
          eval(this.cp.events.click);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'click'. Detalhes: ${e}`);
        }
      }
    },
    verifyIsRoute() {
      if (this.representRoute) {
        const { hostname, protocolo } = this.$host
        const route = this.$route

        let fullPath = `${protocolo}//${hostname}`
        let items = [{ text: hostname, href: fullPath }]
        const routeParts = route.fullPath.split('/')

        for (let part of routeParts) {
          if (part != "") {
            fullPath += `/${part}`
            items.push({
              text: part,
              href: fullPath
            })
          }
        }

        this.items = items

      }
    },
    getItemsToString() {
      let itemsString = ""
      for (let item of this.items) {
        itemsString += `${item.text}/`
      }
      return itemsString
    },
    setStringItems(stringItems) {
      const itemsParts = stringItems.split('/')
      let items = []

      for (let part of itemsParts) {
        if (part != "") {
          items.push({
            text: part,
            href: ""
          })
        }
      }

      this.items = items
    },
  }
}
</script>
<style scoped>
.breadcrumb-card-container {
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}
</style>
