import ClicNotificationDocs from './ClicNotificationDocs';

export default {
    ClicNotification : {
        config      : {icon:"md-smartbutton", documentation: ClicNotificationDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            label                   : {type: 'string',      default: undefined,     component: { name : 'input' } },

           
        },
        events : {
        }
    }
}









