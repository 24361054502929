const XLSX = require('xlsx');
const lodash = require('lodash');

const converterJsonParaXls = ({jsonData, fields, fileName, sheetName}) => {
    const defaultError = "Falha em 'converterJsonParaXls': ";

    if (!fileName) { throw new Error(`${defaultError} 'filename' não informado.`) };
    if (!jsonData) { throw new Error(`${defaultError} 'jsonData' não informado.`) };
    if (!Array.isArray(jsonData)) { throw new Error(`${defaultError} 'jsonData' não é um array.`) };

    const mappedData = mapearCamposJsonParaColunasExcel({jsonData, fields});

    // Criar uma planilha
    const ws = XLSX.utils.json_to_sheet(mappedData);

    // Criar um sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet1');

    // Escrever arquivo
    XLSX.writeFile(wb, fileName + '.xlsx');
}

const mapearCamposJsonParaColunasExcel = ({jsonData, fields}) => {
    return jsonData.map(item => {
        const mappedItem = {};
        for (const field in fields) {
            const excelColumnName = fields[field];
            mappedItem[excelColumnName] = lodash.get(item, field);
        }
        return mappedItem;
    });
}

module.exports = {
    converterJsonParaXls
}