import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';

import ClicCheckboxDocs from './ClicCheckboxDocs'

export default {
    ClicCheckbox : {
        config      : {icon:"md-libraryaddcheck-outlined", documentation: ClicCheckboxDocs.documentation},  
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            label           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            hint            : {type: 'string',      default: undefined,     component: { name : 'input' } },
          
            ariaLabel       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            ariaLabelledby  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            autofocus       : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            button          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            buttonVariant   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            checked         : {type: 'any',         default: false,         component: { name : 'input' } },
            disabled        : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            form            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            indeterminate   : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            inline          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            name            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            plain           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            required        : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            size            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            state           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            /*switch        : {type: 'boolean',     default: undefined,     component: { name : 'boolean' } }, Obs.: ClicSwitch */
            uncheckedValue  : {type: 'any',         default: false,         component: { name : 'input' } },
            value           : {type: 'any',         default: false,         component: { name : 'input' } },

            ...ClicColInterno,  
            ...ClicHintInterno
        },
        events : {
            change  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
        },
    }
}