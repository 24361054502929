import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicCardDocs from './ClicCardDocs'

export default {
    ClicCard : {
        config      : {isContainer:true, icon:"md-calltoaction", documentation: ClicCardDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            align               : {type: 'string',      default: undefined,     component: { name : 'select', values: ['left', 'center', 'right'] } },
            bgVariant           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            bodyBgVariant       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            bodyBorderVariant   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            bodyClass           : {type: 'any',         default: undefined,     component: { name : 'input' } },
            bodyTag             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            bodyTextVariant     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            borderVariant       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footer              : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerBgVariant     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerBorderVariant : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerClass         : {type: 'any',         default: undefined,     component: { name : 'input' } },
            footerHtml          : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerTag           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerTextVariant   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            header              : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerBgVariant     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerBorderVariant : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerClass         : {type: 'any',         default: undefined,     component: { name : 'input' } },
            headerHtml          : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerTag           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerTextVariant   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            imgAlt              : {type: 'string',      default: undefined,     component: { name : 'input' } },
            imgBottom           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            imgEnd              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            imgHeight           : {type: 'any',         default: undefined,     component: { name : 'input' } },
            imgLeft             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            imgRight            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            imgSrc              : {type: 'string',      default: undefined,     component: { name : 'input' } },
            imgStart            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            imgTop              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            imgWidth            : {type: 'any',         default: undefined,     component: { name : 'input' } },
            noBody              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            overlay             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            subTitle            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            subTitleTag         : {type: 'string',      default: undefined,     component: { name : 'input' } },
            subTitleTextVariant : {type: 'string',      default: undefined,     component: { name : 'input' } },
            tag                 : {type: 'string',      default: undefined,     component: { name : 'input' } },
            textVariant         : {type: 'string',      default: undefined,     component: { name : 'input' } },
            title               : {type: 'string',      default: undefined,     component: { name : 'input' } },
            titleTag            : {type: 'string',      default: undefined,     component: { name : 'input' } },

            ...ClicColInterno   
        },
        events : {
        }
    }
}