import TokenService from "./tokenService.js";
import SocketDefaultService from "./socketDefaultService.js";
import axios from 'axios';

class AuthService {
    login(dadosLogin) {
        return axios.post("/auth/login", {
            ...dadosLogin,
            obterDados: true
        })
        .then((response) => {
            if (response.data.accessToken) {
                TokenService.setUser(response.data);
                let dados = response.data;
                delete dados.accessToken;
                delete dados.refreshToken;
                SocketDefaultService.userConnect(dados);
            }
            return response.data;
        });
    }

    logout() {
        TokenService.removeUser();
    }

    register({ username, email, password }) {
        // TODO ?
    }
}

export default new AuthService();