import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // label                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // invalidFeedbackMessage  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // validateScript          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // fieldState              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // jsonId                  :{
        //     hintTitle: "",
        //     hintText:""
        // },

        // ariaControls       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // boundary           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // buttonOnly         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // buttonVariant      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // calendarWidth      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // closeButton        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // closeButtonVariant :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dark               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dateDisabledFn     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dateFormatOptions  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // direction          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // disabled           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dropleft           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dropright          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dropup             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // form               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerTag          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hideHeader         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // initialDate        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // max                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // menuClass          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // min                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // name               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // navButtonVariant   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noCloseOnSelect    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noFlip             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noHighlightToday   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // offset             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // placeholder        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // popperOpts         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // readonly           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // required           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // resetButton        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // resetButtonVariant :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // resetValue         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // right              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // selectedVariant    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // showDecadeNav      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // size               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // startWeekday       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // todayButton        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // todayButtonVariant :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // todayVariant       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // valueAsDate        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // weekdayHeaderFormat:{
        //     hintTitle: "",
        //     hintText:""
        // },
    
        // context:{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hidden :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // input  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // shown  :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}