import ClicTableHeaderDocs from "./ClicTableHeaderDocs";

export default {
    ClicTableHeader : {
        config      : {icon:"md-switchright-outlined", isContainer: true, documentation: ClicTableHeaderDocs.documentation }, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {            
        },
        events : {           
        }
    }
}