import LayoutVazioDocs from "./LayoutVazioDocs"

export default {
    LayoutVazio : {
        config      : {isContainer:true, icon:"md-tablet-round", isRoute: true, documentation: LayoutVazioDocs.documentation }, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            path           : {type: 'string',       default: undefined,     component: { name : 'input' }},
            authenticated  : {type: 'boolean',      default: undefined,     component: { name : 'switch' } },
            isLogin        : {type: 'boolean',      default: undefined,     component: { name : 'switch' } },
        }
    }
}
