import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // hintTitle    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hintText     :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}









