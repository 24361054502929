import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
       
        // type         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // aspect       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // src          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // srcMobile    :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}