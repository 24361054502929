import ComponentDocs from "./ComponentDocs"

export default {
    config: { documentation: ComponentDocs.documentation },
    props : {
        slot        : {type: 'string',      default: undefined, component: { name : 'input' } },
        visible     : {type: 'boolean',     default: true,      component: { name : 'switch' } },
        class       : {type: 'string',      default: undefined, component: { name : 'multiselect', 
                                                                    values: [
                                                                        'display-contents',
                                                                        'm-1',
                                                                        'ml-1',
                                                                        'mx-auto',
                                                                        'border-0',
                                                                        'text-center'
                                                                    ]}
                     },
        style       : {type: 'string',      default: undefined, component: { name : 'input' } },
    },
    events : {
        created : {type: 'string', component: { name : 'code' } },
        mounted : {type: 'string', component: { name : 'code' } },
        updated : {type: 'string', component: { name : 'code' } },
    }
}
