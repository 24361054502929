import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicInputFileDocs from './ClicProgressBarDocs';

export default {
    ClicProgressBar : {
        config      : {icon: "md-menuopen", documentation: ClicInputFileDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            value                   : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            label                   : { type: 'string',     default: undefined, component: { name : 'input' } },
            labelHtml               : { type: 'string',     default: undefined, component: { name : 'input' } },
            animated                : { type: 'boolean',     default: undefined, component: { name : 'switch' } },
            height                  : { type: 'string',     default: undefined, component: { name : 'input'  } },
            max                     : { type: 'string',    default: undefined, component: { name : 'input'} },
            precision               : { type: 'string',     default: undefined, component: { name : 'input' } },

            showProgress            : { type: 'boolean',   default: true, component: { name : 'switch' } },
            showValue               : { type: 'boolean',     default: true,     component: { name : 'switch' } },
            striped                 : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            variant                 : {type: 'string',      default: 'primary',     component: { name : 'select', values: 
                                        ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'] } 
                                        },
            ...ClicColInterno


        }
     
    }
}
