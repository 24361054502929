import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicIconInterno from '../clicIcon/ClicIconInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';

import ClicTextAreaDocs from './ClicTextAreaDocs';

export default {
    ClicTextArea : {
        config      : {icon: "bi-card-text", documentation: ClicTextAreaDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            label                   : { type: 'string',  default: undefined, component: { name : 'input' } },
            invalidFeedbackMessage  : { type: 'string',  default: undefined, component: { name : 'input' } },
            validateScript          : { type: 'string',  default: undefined, component: { name : 'code'  } },
            fieldState              : { type: 'boolean', default: undefined, component: { name : 'switch'} },
            jsonId                  : { type: 'string',  default: undefined, component: { name : 'input' } },

            ariaInvalid     : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            autocomplete    : { type: 'string',     default: undefined, component: { name : 'input' } },
            autofocus       : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            debounce        : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            disabled        : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            form            : { type: 'string',     default: undefined, component: { name : 'input' } },
            formatter       : { type: 'function',   default: undefined, component: { name : 'code' } },
            lazy            : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            lazyFormatter   : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            maxRows         : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            noAutoShrink    : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            noResize        : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            number          : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            placeholder     : { type: 'string',     default: undefined, component: { name : 'input' } },
            plaintext       : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            readonly        : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            required        : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            rows            : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            size            : { type: 'string',     default: undefined, component: { name : 'input' } },
            trim            : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            value           : { type: 'multiple',   default: undefined, component: { name : 'input' } },
            wrap            : { type: 'string',     default: undefined, component: { name : 'input' } },    
            
            ...ClicColInterno,
            ...ClicIconInterno,
            ...ClicHintInterno,

        },
        events : {
            blur    : {type: 'string', component: { name : 'code' } },
            change  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
            update  : {type: 'string', component: { name : 'code' } },
            keyup   : {type: 'string', component: { name : 'code' } },
        },
    }
}

