<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
  >
    <div class="form-group left-label" style="height: 100%;">    
      <div class="d-flex flex-row align-items-start">
        <label class="form-label form-label-clic" v-if="label">
          {{ $storage.getTextoTraduzido(label) }}
        </label>
        <ClicHintInterno
          v-if="hintText"
          :cp="cp"
        />
      </div>

      <VueMarkdownEditor 
        ref="editor"
        :key="cpId"
        v-model="text" 
        :text="text"
        :mode="mode"
        :height="height"
        :tab-size="tabSize"
        :toc-nav-position-right="tocNavPositionRight"
        :default-show-toc="defaultShowToc"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :default-fullscreen="defaultFullscreen"
        :include-level="includeLevel"
        :left-toolbar="leftToolbar" 
        :right-toolbar="rightToolbar"
        :toolbar="toolbarTratada"
        :toolbar-config="toolbarConfig"
        :disabled-menus="disabledMenus"
        :upload-image-config="uploadImageConfig" 
        :before-preview-change="beforePreviewChange"
        :codemirror-config="codemirrorConfig"
        :codemirror-style-reset="codemirrorStyleReset"
        :style="style"
        :class="this.class"
        @change="change"
        @blur="blur"
        @save="save"
        @image-click="imageClick"
        @fullscreen-change="fullscreenChange"
        @upload-image="uploadImage"
        class="clicMarkdown"
      >
      </VueMarkdownEditor>

    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import hljs from 'highlight.js';
VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs,
});

import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/npm';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';
VueMarkdownEditor.use(createMermaidPlugin());

export default {
  name: 'ClicMarkdown',
  mixins: [ComponentMixin],
  components: {
    VueMarkdownEditor
  },

  mounted() {
    VueMarkdownEditor.lang.use('idioma', this.idioma[this.$storage.getIdioma()]);
  },

  data() {
    return {
      public : {
      },

      cpId : this.$utils.getComponentID(),
      toolbar : undefined,
      toolbarTratada : undefined,
      idioma : {
        PT : {
          undo: {
            toolbar: 'Desfazer',
          },
          redo: {
            toolbar: 'Refazer',
          },
          clear: {
            toolbar: 'Limpar',
          },
          h: {
            toolbar: 'Cabeçalhos',
          },
          h1: {
            toolbar: 'Cabeçalho 1',
            placeholder: 'Cabeçalho 1',
          },
          h2: {
            toolbar: 'Cabeçalho 2',
            placeholder: 'Cabeçalho 2',
          },
          h3: {
            toolbar: 'Cabeçalho 3',
            placeholder: 'Cabeçalho 3',
          },
          h4: {
            toolbar: 'Cabeçalho 4',
            placeholder: 'Cabeçalho 4',
          },
          h5: {
            toolbar: 'Cabeçalho 5',
            placeholder: 'Cabeçalho 5',
          },
          h6: {
            toolbar: 'Cabeçalho 6',
            placeholder: 'Cabeçalho 6',
          },
          bold: {
            toolbar: 'Negrito',
            placeholder: 'Negrito',
          },
          italic: {
            toolbar: 'Itálico',
            placeholder: 'Itálico',
          },
          strikethrough: {
            toolbar: 'Riscado',
            placeholder: 'Riscado',
          },
          quote: {
            toolbar: 'Citação',
            placeholder: 'Citação',
          },
          ul: {
            toolbar: 'Lista não ordenada',
            placeholder: 'Lista não ordenada',
          },
          ol: {
            toolbar: 'Lista ordenada',
            placeholder: 'Lista ordenada',
          },
          table: {
            toolbar: 'Tabela',
          },
          hr: {
            toolbar: 'Linha',
          },
          link: {
            toolbar: 'Link',
            descPlaceholder: 'Link',
          },
          image: {
            toolbar: 'Imagem',
          },
          imageLink: {
            toolbar: 'Link',
          },
          uploadImage: {
            toolbar: 'Upload Imagem',
          },
          code: {
            toolbar: 'Código',
          },
          save: {
            toolbar: 'Salvar',
          },
          preview: {
            enabled: 'Habilitar pré-visualização',
            disabled: 'Desabilitar  pré-visualização',
          },
          toc: {
            title: 'Navegação',
            enabled: 'Ver navegação',
            disabled: 'Esconder navegação',
          },
          syncScroll: {
            enabled: 'Habilitar scroll sincronizado',
            disabled: 'Desabilitar scroll sincronizado',
          },
          fullscreen: {
            enabled: 'Tela cheia (ESC para sair)',
            disabled: 'Sair tela cheia',
          },
        },

        EN : {
          undo: {
            toolbar: 'Undo',
          },
          redo: {
            toolbar: 'Redo',
          },
          clear: {
            toolbar: 'Clear',
          },
          h: {
            toolbar: 'Headings',
          },
          h1: {
            toolbar: 'Heading 1',
            placeholder: 'Heading 1',
          },
          h2: {
            toolbar: 'Heading 2',
            placeholder: 'Heading 2',
          },
          h3: {
            toolbar: 'Heading 3',
            placeholder: 'Heading 3',
          },
          h4: {
            toolbar: 'Heading 4',
            placeholder: 'Heading 4',
          },
          h5: {
            toolbar: 'Heading 5',
            placeholder: 'Heading 5',
          },
          h6: {
            toolbar: 'Heading 6',
            placeholder: 'Heading 6',
          },
          bold: {
            toolbar: 'Bold',
            placeholder: 'Bold',
          },
          italic: {
            toolbar: 'Italic',
            placeholder: 'Italic',
          },
          strikethrough: {
            toolbar: 'Strike',
            placeholder: 'Strike',
          },
          quote: {
            toolbar: 'Blockquote',
            placeholder: 'Blockquote',
          },
          ul: {
            toolbar: 'Unordered list',
            placeholder: 'Unordered list',
          },
          ol: {
            toolbar: 'Ordered list',
            placeholder: 'Ordered list',
          },
          table: {
            toolbar: 'Insert table',
          },
          hr: {
            toolbar: 'Line',
          },
          link: {
            toolbar: 'Insert link',
            descPlaceholder: 'Link',
          },
          image: {
            toolbar: 'Insert image',
          },
          imageLink: {
            toolbar: 'Insert link',
          },
          uploadImage: {
            toolbar: 'Upload Image',
          },
          code: {
            toolbar: 'Insert Codeblock',
          },
          save: {
            toolbar: 'Save',
          },
          preview: {
            enabled: 'Enable preview',
            disabled: 'Disable preview',
          },
          toc: {
            title: 'Navigation',
            enabled: 'Enable navigation',
            disabled: 'Disable navigation',
          },
          syncScroll: {
            enabled: 'Enable sync scroll ',
            disabled: 'Disable sync scroll',
          },
          fullscreen: {
            enabled: 'Full screen(Press ESC to exit)',
            disabled: 'Exit Full Screen',
          },
        }
      }
    }
  },

  watch: {
    toolbar(toolbar) {
      try {
        let objConvertido = new Function(`return ${toolbar}`).bind(this)();

        for (let key in objConvertido) {
          const item = objConvertido[key];
  
          if  (item.script) {
            item.action = (editor) => {
              editor.insert((selected) => {
                try {
                  return new Function(`${item.script}`).bind(this)();
                }catch (e) {
                  console.error(`[ClicMarkdown] Falha ao criar script do item '${item.title}''. Detalhes: ${e}`);
                }
              });
            }

          } else if (!item.action) {
            item.action = (editor) => {
              editor.insert((selected) => {
                return {
                  text: "(((nao implementado)))",
                  selected: "",
                };
              });
            }
          }

        }
        this.toolbarTratada = objConvertido;
        this.cpId = this.$utils.getComponentID();
      } catch (e) {
        console.error(`[ClicMarkdown] Falha ao converter prop 'toolbar' para json. Detalhes: ${e}`);
      }
    },  
  },

  methods: {

    kebabToCamelCase(str) {
      const camelCaseStr = str.replace(/-./g, match => match.charAt(1).toUpperCase());
      return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
    },

    change(text, html) {
      if(this.lodash.has(this.cp, 'events.change')){
        try {
          eval(this.cp.events.change);
        } catch (e) {
          console.error(`[ClicMarkdown] Falha ao executar evento 'change'. Detalhes: ${e}`);
        }
      }
    },

    blur(event) {
      if(this.lodash.has(this.cp, 'events.blur')){
        try {
          eval(this.cp.events.blur);
        } catch (e) {
          console.error(`[ClicMarkdown] Falha ao executar evento 'blur'. Detalhes: ${e}`);
        }
      }
    },

    save(text, html) {
      if(this.lodash.has(this.cp, 'events.save')){
        try {
          eval(this.cp.events.save);
        } catch (e) {
          console.error(`[ClicMarkdown] Falha ao executar evento 'save'. Detalhes: ${e}`);
        }
      }
    },

    imageClick(images, currentIndex) {
      if(this.lodash.has(this.cp, 'events.imageClick')){
        try {
          eval(this.cp.events.imageClick);
        } catch (e) {
          console.error(`[ClicMarkdown] Falha ao executar evento 'imageClick'. Detalhes: ${e}`);
        }
      }
    },

    fullscreenChange(isFullscreen) {
      if(this.lodash.has(this.cp, 'events.fullscreenChange')){
        try {
          eval(this.cp.events.fullscreenChange);
        } catch (e) {
          console.error(`[ClicMarkdown] Falha ao executar evento 'fullscreenChange'. Detalhes: ${e}`);
        }
      }
    },

    uploadImage(event, insertImage) {
      if(this.lodash.has(this.cp, 'events.uploadImage')){
        try {
          eval(this.cp.events.uploadImage);
        } catch (e) {
          console.error(`[ClicMarkdown] Falha ao executar evento 'uploadImage'. Detalhes: ${e}`);
        }
      }
    },
    
  }
};
</script>

<style lang="scss" rel="stylesheet/less" scoped>

  .clicMarkdown {
    width: 100%;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

</style>



