<template>
  <label 
    :id="id"
    class="form-label" 
    style="margin: 0px !important;"
    :key="componentKey"
    v-show="visible"
  >

    <ClicIconInterno
      v-b-modal="id"
      :cp="cp"
      class="btn"
      :iconName="'bi-info-circle'"
      :iconScale="'1.3'"
      
    />
    
    <b-modal 
      :id="id" 
      size="md"
      :title="$storage.getTextoTraduzido(title)"
      hide-footer
    >
      <template #default="{ }">
        <p 
          v-html="$storage.getTextoTraduzido(text)">
        </p>
      </template>
    </b-modal>


    <b-popover 
      v-if="windowWidth > 1024"
      :target="id" 
      triggers="hover" 
      placement="auto"
    >
      <template #title> 
        {{ $storage.getTextoTraduzido(title) }}
      </template>
      <p
        v-html="$storage.getTextoTraduzido(text)">
      </p>
    </b-popover>


  </label>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name : 'ClicHint',
  mixins: [ComponentMixin],

  data(){
    return{
      windowWidth: window.innerWidth,
      id : this.$utils.getComponentID()
    }
  },

};
</script>

