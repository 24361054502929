<template>
    <div style="display: contents;">
        <v-icon 
            :name="getProp('iconName')"
            :scale="getProp('iconScale')"
            :animation="getProp('iconAnimation')"
            :speed="getProp('iconSpeed')"
            :hover="getProp('iconHover')"
            :flip="getProp('iconFlip')"
            :fill="getProp('iconFill')"
            :label="getProp('iconLabel')"
            :title="getProp('iconTitle')"
            :inverse="getProp('iconInverse')"
            v-if="!lodash.has(cp, 'props.iconText')"
        >
        </v-icon>
        
        <div v-if="lodash.has(cp, 'props.iconBadgeClass')"
            :class="'icon-status ' + getProp('iconBadgeClass')"
        >
        </div>
        
        <b-avatar 
            variant="primary" 
            :text="getProp('iconText')"
            v-if="lodash.has(cp, 'props.iconText')"
        >
        </b-avatar>
        
    </div>
</template>

<script>
    
    import ComponentInternalMixin from '../../core/ComponentInternalMixin';

    export default {
        name: 'ClicIconInterno',
        mixins: [ComponentInternalMixin],
    }
    
</script>


<style lang="scss" scoped>
    
  .icon-status:after {
    position: absolute;
    border-radius: 50%;
    right: 0;
    top:-5px;
    height: 10px;
    width: 10px;
    border: 1px solid #fff;
  }

  svg{
    max-width: fit-content;
  }
 
 
</style>
