import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        // tag:{
        //     hintTitle: "",
        //     hintText:""
        // },                     
        // text:{
        //     hintTitle: "",
        //     hintText:""
        // },                    
        // value:{
        //     hintTitle: "",
        //     hintText:""
        // },                   
        // label:{
        //     hintTitle: "",
        //     hintText:""
        // },                   
    }
}