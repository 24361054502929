import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicToolbarDocs from './ClicToolbarDocs'

export default {
    ClicToolbar : {
        config      : {icon: "md-viewsidebar", documentation: ClicToolbarDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            content             : {type: 'array',       default: undefined,     component: { name : 'code'  } },
            removeCard          : {type: 'boolean',     default: undefined,     component: { name : 'switch'  } },

            ...ClicColInterno   
        },
        events : {
        }
    }
}