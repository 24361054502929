import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        label: {
            hintTitle:"Label", 
            hintText:"Informe o título que será utilizado no header do container."
        }, 
        startOpenedContainer: {
            hintTitle:"startOpenedContainer", 
            hintText:"Se definido como verdadeiro, irá iniciar o container aberto."
        }, 
        variant: {
            hintTitle:"variant", 
            hintText:"Define a cor de fundo para o cabeçalho do container, por padrão utiliza o valor 'primary'."
        }, 
        ...GeneralProperties.documentation

    }
}