<template>
    <b-navbar-brand 
        :active="this.lodash.get(this.cp, 'props.active', false)"
        :active-class="this.lodash.get(this.cp, 'props.activeClass')"
        :append="this.lodash.get(this.cp, 'props.append', false)"
        :disabled="this.lodash.get(this.cp, 'props.disabled', false)"
        :exact="this.lodash.get(this.cp, 'props.exact', false)"
        :exact-active-class="this.lodash.get(this.cp, 'props.exactActiveClass')"
        :exact-path="this.lodash.get(this.cp, 'props.exactPath', false)"
        :exact-path-active-class="this.lodash.get(this.cp, 'props.exactPathActiveClass')"
        :href="this.lodash.get(this.cp, 'props.href')" 
        :no-prefetch="this.lodash.get(this.cp, 'props.noPrefetch', false)"
        :prefetch="this.lodash.get(this.cp, 'props.prefectch')"
        :rel="this.lodash.get(this.cp, 'props.rel', null)"
        :replace="this.lodash.get(this.cp, 'props.replace', false)"
        :router-component-name="this.lodash.get(this.cp, 'props.routerComponentName')"
        :tag="this.lodash.get(this.cp, 'props.tag', 'div')"
        :target="this.lodash.get(this.cp, 'props.target', '_self')"
        :to="this.lodash.get(this.cp, 'props.to')"
    > 

      <MotorS
        :cp="[]"
        :childrens="this.cp.childrens"
        @register="register"
        idTela="mainFramework"
      />

    </b-navbar-brand>
</template>
<script>

export default {
    props: {
        cp:{
            type : Object,
            default : function () { return {props : function () { return {} }}},
        },
        idTela:{
            type: String,
            default: "",
        },

    },
    data() {
        return {};
    },
  
    created() {
        this.$store.commit(`${this.idTela}/setPropriedades`, {[`${this.cp.id}`]: this.cp});
        // if(this.$navbar){
        //     this.navbar = JSON.parse(this.$navbar);
        // }
    },

    methods:{
      register() {
        if (!this.$store.hasModule(this.$route.name)) {
          this.$store.registerModule(`${this.$route.name}`, {
            namespaced: true,
            state(cp) {
              return {
                propriedades: {}
              };
            },
            getters: {
              getPropriedades(state) {
                return state.propriedades;
              }
            },
            mutations: {
              setPropriedades(state, propriedades) {
                if (propriedades) {
                  state.propriedades = { ...state.propriedades, ...propriedades };
                }
              }
            }
          });
        }
      }
    }

};
</script>

<style>
.top-logo{
   
    padding: 0px;
    height: 50px;
}
</style>