<template>
    <b-img
        :alt="getProp('imageAlt')"
        :blank="getProp('imageBlank')"
        :blank-color="getProp('imageBlankColor')"
        :block="getProp('imageBlock')" 
        :center="getProp('imageCenter')"
        :fluid="getProp('imageFluid')"
        :fluid-grow="getProp('imageFluidGrow')" 
        :height="getProp('imageHeight')"
        :left="getProp('imageLeft')"
        :right="getProp('imageRight')"
        :rounded="getProp('imageRounded')"
        :sizes="getProp('imageSizes')"
        :src="tratarImageSrc(getProp('imageSrc'))"
        :srcset="getProp('imageSrcset')"
        :thumbnail="getProp('imageThumbnail')"
        :width="getProp('imageWidth')"
        :style="getProp('imageStyle')"
        :class="getProp('imageClass')"
    >
    </b-img>
</template>

<script>

import ComponentInternalMixin from '../../core/ComponentInternalMixin';

export default {
    name: 'ClicImageInterno',
    mixins: [ComponentInternalMixin],

    methods: {
        tratarImageSrc(src) {
            return eval('`'+src+'`');
        }
    }
};
</script>
