<template>
    <ClicColInterno
        :cp="cp"
    >
    
        <b-tabs
            :active-nav-item-class="activeNavItemClass"
            :active-tab-class="activeTabClass"
            :align="align"
            :card="card"
            
            :content-class="contentClass"
            :end="end"
            :fill="fill"
            :justified="justified"
            :lazy="lazy"
            :nav-class="navClass"
            :nav-wrapper-class="navWrapperClass"
            :no-fade="noFade"
            :no-key-nav="noKeyNav"
            :no-nav-style="noNavStyle"
            :pills="pills"
            :small="small"
            :tag="tag"
            v-model="value"
            :vertical="vertical"
            :class="`${this.class} card-padding`"
            @changed="changed"
            @activate-tab="activateTab"
            @input="input"
        >
            <MotorS 
                :cp="cp" 
                :childrens="childrens"
                :idTela="idTela"
            />
            <span slot="empty">
                Nenhuma aba encontrada
            </span>
        </b-tabs>
     
    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicTabs',
    mixins: [ComponentMixin],

    data() {
        return {
            value       : 0,
            selectedTab : undefined,

            public : {
                getSelectedTab : this.getSelectedTab,
            }
        }
    },

    watch: {
        value(newValue) {
            if  (this.childrens[newValue]?.id) {
                this.selectedTab = { index: newValue, id: this.childrens[newValue].id };
            }
        },
    },
 
    methods: {

        changed(currentTabs, previousTabs) {
            let eventoChanged = this.lodash.get(this.cp, 'events.changed');
            if  (eventoChanged) {
                try {
                    eval(eventoChanged);
                } catch (e) {
                    console.error(`[ClicTabs] Falha ao executar evendo 'changed'. Detalhes: ${e}`);
                }
            }
        },

        activateTab(newTabIndex, prevTabIndex, bvEvent) {
            let eventoActivateTab = this.lodash.get(this.cp, 'events.activateTab');
            if  (eventoActivateTab) {
                try {
                    eval(eventoActivateTab);
                } catch (e) {
                    console.error(`[ClicTabs] Falha ao executar evendo 'activateTab'. Detalhes: ${e}`);
                }
            }
        },

        input(tabIndex) {
            let eventoInput = this.lodash.get(this.cp, 'events.input');
            if  (eventoInput) {
                try {
                    eval(eventoInput);
                } catch (e) {
                    console.error(`[ClicTabs] Falha ao executar evendo 'input'. Detalhes: ${e}`);
                }
            }
        },

        getSelectedTab() {
            return this.selectedTab;
        }

    }
};
</script>

<style>
    .card-padding {
        padding: 7px;
        background-color: var(--light-gray) !important;
    }
    .nav-tabs .nav-link {
        color: #526484 !important; 
    }
    .nav-tabs .nav-link.active:after {
        background:var(--cor-principal-claro) !important; 
    }

    .nav-tabs .nav-link.active {
        color: var(--cor-principal) !important;
    }
    
</style>



