<template>
    <b-tab 
        :active="active" 
        :button-id="buttonId" 
        :disabled="disabled" 
        :lazy="lazy" 
        :no-body="noBody" 
        :tag="tag" 
        :title="visible ? $storage.getTextoTraduzido(title) : ''" 
        :style="visible ? style : 'visibility:hidden'" 
        :title-item-class="titleItemClass" 
        :title-link-attributes="titleLinkAttributes" 
        :title-link-class="titleLinkClass"
        @click="click"
    >
        <MotorS 
            :cp="cp" 
            :childrens="childrens"
            :idTela="idTela"
        />
   </b-tab>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicTab',
    mixins: [ComponentMixin],
    
    methods: {

        click (event) {
            let eventoClick = this.lodash.get(this.cp, 'events.click');
            if  (eventoClick) {
                try {
                    eval(eventoClick);
                } catch (e) {
                    console.error(`[ClicTab] Falha ao executar evendo 'click'. Detalhes: ${e}`);
                }
            }
        },

    }
};
</script>




