<template>
  <div class="layout">
    <MotorS 
      :cp="this.$route" 
      :childrens="this.$route.meta.childrens"
      @register="register"
      :idTela="getIdTela()"/>
  </div>
</template>

<script>
// @ is an alias to /src
import MotorS from './MotorS.vue';

export default {
  name: 'LayoutLogin',
  created() {
    // this.register()
  },
  mounted() {
  },
  watch: {
    '$route' (to, from) {
        //on route change re run: onCreated
        // this.onCreated();
        this.register()
    }
  },
  components: {
    MotorS
  },
  methods: {
    getIdTela(){
      return JSON.parse(JSON.stringify(this.$route.name))
    },
    register() {
      if(!this.$store.hasModule(this.$route.name)){
        this.$store.registerModule(`${this.$route.name}`, {
          namespaced: true,
          state(cp) {
              return {
                  propriedades: {},
              }
          },
          getters: {
              getPropriedades(state) {
                  return state.propriedades
              },
          },
          mutations: {
            setPropriedades(state, propriedades) {
              if(propriedades){
                state.propriedades = {...state.propriedades, ...propriedades};
              }
              
            },
          },
        })
      }

      // this.$store.commit(`${_this.idTela}/setPropriedades`, {[`${_this.cp.id}`]: _this.cp})
    }
  }
};
</script>

<style lang="scss">
@import "@/common/scss/breakpoints.scss";

.layout {
  height: inherit;
  min-width: 100%;

  display: flex;
  flex-direction: column;

  @media (min-width: $medium-size) {
    flex-direction: row;
  }

  &__topo {
    position: fixed;
  }

  &__corpo {
    order: 3;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5em;

    @media (min-width: $medium-size) {
      display: flex;
      align-items: center;
    }
  }
  
}

</style>