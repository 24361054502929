import ClicRowDocs from "./ClicRowDocs"

export default {
    ClicRow : {
        config      : {isContainer: true, icon: 'md-tablerows-round', documentation: ClicRowDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : 'input' },},
        props : {
            title               : {type: 'string',  default: undefined, component: { name: 'input' } },
            subtitle            : {type: 'string',  default: undefined, component: { name: 'input' } },

            alignContent    : {type: 'string',      default: undefined, component: { name: 'input' } },
            alignH          : {type: 'string',      default: undefined, component: { name: 'input' } },
            alignV          : {type: 'string',      default: undefined, component: { name: 'input' } },
            cols            : {type: 'multiple',    default: undefined, component: { name: 'input' } },
            colsLG          : {type: 'multiple',    default: undefined, component: { name: 'input' } },
            colsMD          : {type: 'multiple',    default: undefined, component: { name: 'input' } },
            colsSM          : {type: 'multiple',    default: undefined, component: { name: 'input' } },
            colsXL          : {type: 'multiple',    default: undefined, component: { name: 'input' } },
            noGutters       : {type: 'boolean',     default: undefined, component: { name: 'switch' } },
            tag             : {type: 'string',      default: undefined, component: { name: 'input' } },
        },
        events : {
            
        }
    }
}









