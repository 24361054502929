<template>
    <b-dropdown-item
        v-show="visible"
        :active="active"
        :active-class="activeClass"
        :append="append"
        :disabled="disabled"
        :exact="exact"
        :exact-active-class="exactActiveClass"
        :exact-path="exactPath"
        :exact-path-active-class="exactPathActiveClass"
        :href="lodash.get(this, 'href', 'javascript:void(0)')"
        :link-class="linkClass"
        :no-prefetch="noPrefetch"
        :prefetch="prefetch"
        :rel="rel"
        :replace="replace"
        :router-component-name="routerComponentName"
        :target="target"
        :to="to"
        :variant="variant"
        @click="click"
    >
        <b-row v-if="lodash.has(cp, 'childrens')">
          <MotorS 
            :cp="cp" 
            :childrens="childrens"
            :idTela="idTela"
            :state="state"
          />
        </b-row>
        <p v-if="text">
          {{ $storage.getTextoTraduzido(text) }}
        </p>
    </b-dropdown-item>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicDropdownItem',
  mixins: [ComponentMixin],

  data() {
    return {
      isLoading : false,
    };
  },

  methods: {

    click(event) {
      if  (this.lodash.has(this.cp, 'events.click')) {

        try {
          eval(this.cp.events.click);
        } catch (e) {
          console.error(`[CLicDropdownItem] Falha ao executar evendo 'click'. Detalhes: ${e}`);
        }

      } else if (this.lodash.has(this.cp, 'events.clickPromise')) {

        try {
          this.isLoading = true;
          let click = this.cp.events.clickPromise;
          let script = `
            new Promise((resolve, reject) => {
              ${click}
            });
          `;
          let execucao = eval(script);
          execucao.then(() => {
            this.isLoading = false;
          });
        } catch (e) {
          console.error(`[CLicDropdownItem] Falha ao executar evendo 'clickPromise'. Detalhes: ${e}`);
        }
      }
    }

  }

};
</script>
