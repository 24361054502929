<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
  >
    <b-card
      :align="align" 
      :bg-variant="bgVariant" 
      :body-bg-variant="bodyBgVariant" 
      :body-border-variant="bodyBorderVariant" 
      :body-class="bodyClass" 
      :body-tag="bodyTag" 
      :body-text-variant="bodyTextVariant" 
      :border-variant="borderVariant" 
      :footer="footer" 
      :footer-bg-variant="footerBgVariant" 
      :footer-border-variant="footerBorderVariant" 
      :footer-class="footerClass" 
      :footer-html="footerHtml" 
      :footer-tag="footerTag" 
      :footer-text-variant="footerTextVariant" 
      :header="header" 
      :header-bg-variant="headerBgVariant" 
      :header-border-variant="headerBorderVariant" 
      :header-class="headerClass" 
      :header-html="headerHtml" 
      :header-tag="headerTag" 
      :header-text-variant="headerTextVariant" 
      :img-alt="imgAlt" 
      :img-bottom="imgBottom" 
      :img-end="imgEnd" 
      :img-height="imgHeight" 
      :img-left="imgLeft" 
      :img-right="imgRight" 
      :img-src="imgSrc" 
      :img-start="imgStart" 
      :img-top="imgTop" 
      :img-width="imgWidth" 
      :no-body="noBody" 
      :overlay="overlay" 
      :sub-title="subTitle" 
      :sub-title-tag="subTitleTag" 
      :sub-title-text-variant="subTitleTextVariant" 
      :tag="tag" 
      :text-variant="textVariant" 
      :title="title" 
      :title-tag="titleTag" 
      :style="this.style"
      :class="this.class"
    >
      <MotorS 
        :cp="cp" 
        :childrens="childrens"
        :idTela="idTela"
      />
    </b-card>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicCard',
  mixins: [ComponentMixin]
};
</script>

<style>
  .card-body{
    padding-left: 0.8rem;
  }
</style>