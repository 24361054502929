<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
  >
    <div class="form-group left-label">    
      <div class="d-flex flex-row align-items-start">
         <label class="form-label form-label-clic" v-if="label">
            <b v-if="required && (value == undefined || value === '')" class="text-danger">*</b>
            {{ $storage.getTextoTraduzido(label) }}
          </label>
          <ClicHintInterno
              v-if="hintText"
              :cp="cp"
          />
      </div>

      <div class="form-control-wrap">
        <div class="form-icon form-icon-left" v-if="iconName">
          <ClicIconInterno
            v-if="iconName"
            :cp="cp"
          />
        </div>
        <b-form-textarea
            :style="style"
            :class="this.class"
            :aria-invalid="ariaInvalid" 
            :autocomplete="autocomplete"
            :autofocus="autofocus"
            :debounce="debounce"
            :disabled="disabled"
            :form="form"
            :formatter="formatter"
            :lazy="lazy"
            :lazy-formatter="lazyFormatter"
            :max-rows="maxRows"
            :no-auto-shrink="noAutoShrink"
            :no-resize="noResize"
            :number="number"
            :placeholder="$storage.getTextoTraduzido(placeholder)"
            :plaintext="plaintext"
            :readonly="readonly"
            :required="required"
            :rows="rows"
            :size="size"
            :state="fieldState"
            :trim="trim"
            :value="value"
            v-model="value"
            :wrap="wrap"
            @blur="blur"
            @change="change"
            @input="input"
            @update="update"
            @keyup="keyup"
        ></b-form-textarea>
         <b-form-invalid-feedback 
            v-if="invalidFeedbackMessage" 
          >
            {{invalidFeedbackMessage}}
        </b-form-invalid-feedback>
      </div>
    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicTextArea',
  mixins: [ComponentMixin],

  data() {
    return {
      public:{
        isValid     : this.validateField,
        clearField  : this.clearField,
        undo            : this.undo,
        apply           : this.apply,
        isValueChanged  : this.isValueChanged,
      },

      value       : undefined,
      oldValue    : undefined,
      fieldState  : undefined,
    }
  },

  mounted(){
    this.oldValue = this.value;
  },

  methods: {
    input(value) {
      if(!this.fieldState || this.fieldState){
        this.fieldState = undefined;
      };

      if(this.lodash.has(this.cp, 'events.input')){
        try {
          eval(this.cp.events.input);
        } catch (e) {
          console.error(`[ClicTextArea] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
    change(value) {
      if(this.lodash.has(this.cp, 'events.change')){
        try {
          eval(this.cp.events.change);
        } catch (e) {
          console.error(`[ClicTextArea] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
    blur(event) {
      if (this.lodash.has(this.cp, 'props.validateScript')){
        this.validateField();  
      };

      if  (this.lodash.has(this.cp, 'events.blur')) {
        try {
          eval(this.cp.events.blur);
        } catch (e) {
          console.error(`[ClicTextArea] Falha ao executar evento 'blur'. Detalhes: ${e}`);
        }
      }
    },
    update(value) {
      if  (this.lodash.has(this.cp, 'events.update')) {
        try {
          eval(this.cp.events.update);
        } catch (e) {
          console.error(`[ClicTextArea] Falha ao executar evento 'update'. Detalhes: ${e}`);
        }
      }
    },
    keyup(keyboardEvent) {
      if  (this.lodash.has(this.cp, 'events.keyup')) {
        try {
          eval(this.cp.events.keyup);
        } catch (e) {
          console.error(`[ClicTextArea] Falha ao executar evento 'keyup'. Detalhes: ${e}`);
        }
      }
    },

    undo() {
      this.value = this.oldValue;
    },
    apply() {
      this.oldValue = this.value;
    },
    isValueChanged() {
      return this.oldValue != this.value;
    },

  }
};
</script>
