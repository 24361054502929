<template>
    <div style="display:contents">

        <notifications :duration="-1" :closeOnClick=true width="auto" ref="notification">

            <template slot="body" slot-scope="{ item, close }">
                <div v-if="item.type == 'dialog'">
                    <b-modal :visible="exibirModalDialogo" rounded="lg" size="md !important" centered hide-footer
                        hide-header :no-close-on-backdrop="lodash.get(item, 'data.noCloseOnBackdrop', true)"
                        :hide-backdrop="false" :no-fade="false" @show="gerarAcompanhamentoDialogo"
                        :id="`modalDialogoGlobal`" :ref="`modalDialogoGlobal-${item.id}`">
                        <b-col class="text-center container-conteudo-modal-dialogo ">

                            <div v-html="obterIconeTopoPersonalizadoPorTipoDialogo(iconeDialogo)"></div>

                            <div v-for="(etapaConcluida, index) in dadosDialogos" :key="index"
                                :class="`mt-2 ${lodash.get(item, 'data.dialogClass', 'text-center')}`">
                                <b-alert show dismissible variant="light">
                                    <b :class="`${lodash.get(item, 'data.dialogClass', 'text-center')}`">
                                        {{ etapaConcluida.valor }}
                                    </b>

                                    <template #dismiss>
                                        <v-icon name="fa-check" scale="1.5" :hover="false" fill="green" />
                                    </template>
                                </b-alert>
                            </div>

                            <div :class="`mt-2 ${lodash.get(item, 'data.dialogClass', 'text-center')}`">
                                <b-alert show :variant="lodash.get(item, 'data.hasError', false) ? 'danger' : 'light'"
                                    dismissible>
                                    <b :style="lodash.get(item, 'data.hasError', false) ? 'red' : ''">
                                        {{ item.text }}
                                    </b>

                                    <template #dismiss>
                                        <v-icon :name="obterIconeLinhaNovoDialogo(item, 'icone')" scale="1.5"
                                            :hover="false" :fill="obterIconeLinhaNovoDialogo(item, 'cor')" />
                                    </template>
                                </b-alert>
                            </div>


                        </b-col>


                        <b-row :class="lodash.get(item, 'data.footerClass', 'text-center mt-4')">
                            <b-col v-show="!gerandoAcompanhamentoDialogo">
                                <b-button :class="lodash.get(item, 'data.okButtonClass', 'col-xl-4')"
                                    :style="lodash.get(item, 'data.okButtonStyle', 'border:none; justify-content: center')"
                                    variant="primary" @click="$refs.notification.destroyAll">
                                    {{ lodash.get(item, 'data.okButtonText', 'OK') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-modal>
                </div>

                <!-- 
                <div v-else @click="close">
                    <em class="icon ni" :class="obterIconeNotificacao(item.type)"></em>
                    <div class="alert-text">
                        <h6 v-if="item.title">{{ item.title }}</h6>
                        <div v-html="item.text" />
                    </div>

                    <button class="close"></button>
                </div> -->


                <div v-else @click="close">
                    <b-modal :visible="exibirNotificacao" rounded="lg" size="sm !important" centered hide-footer
                        hide-header :no-close-on-backdrop="false" :hide-backdrop="false" :no-fade="false"
                        :id="`notificacaoGlobal`" :ref="`notificacaoGlobal-${item.id}`">

                        <div class="container-button-close-notification">
                            <b-button variant="light" size="sm" class="button-close-notification" @click="$refs.notification.destroyAll()">
                                <ClicIconInterno style="color: gray !important" iconName="fa-times"></ClicIconInterno>
                            </b-button>
                        </div>

                        <b-col class="text-center container-conteudo-modal-dialogo ">
                            <div v-html="obterIconeTopoPersonalizadoPorTipoDialogo(item.type)"></div>

                            <div :class="`mt-2 text-center`">
                                <h1 v-if="!item.text">{{ obterTituloPadrao(item.title) }}</h1>
                                <div v-else v-html="item.text"></div>

                            </div>
                        </b-col>
                    </b-modal>
                </div>

            </template>
        </notifications>


    </div>
</template>

<script>

export default {
    name: 'ClicNotificationInterno',
    data() {
        return {
            title: 'titulo',
            exibirModalDialogo: true,
            exibirNotificacao: true,
            dadosDialogos: [],
            gerandoAcompanhamentoDialogo: true,
            iconeDialogo: 'loading',
        }
    },

    methods: {
        obterIconeNotificacao(tipo) {
            return tipo == "success" ? "ni-check-circle" :
                tipo == "danger" ? "ni-cross-circle" :
                    tipo == "warning" ? "ni-alert-circle" :
                        tipo == "info" ? "ni-info" : "ni-info";
        },

        resetarAcompanhamentoDialogo() {
            this.dadosDialogos = [];
            this.gerandoAcompanhamentoDialogo = true;
            this.iconeDialogo = "loading"
        },

        obterIconeLinhaNovoDialogo(dialog, key) {
            const icones = {
                hasError: { icone: "md-smsfailed-twotone", cor: "red" },
                showOkButton: { icone: "fa-check", cor: "green" },
                isInfo: { icone: "bi-dot", cor: "#55e3f8" },
                default: { icone: "bi-chat-left-dots", cor: "grey" }
            };

            const data = this.lodash.get(dialog, "data", { 'default': true });
            // Filtrar as ações para incluir apenas aquelas presentes em 'icones'
            const acoes = Object.keys(data).filter(chaveExistente => icones.hasOwnProperty(chaveExistente));

            if (acoes.length > 0) {
                return icones[acoes[0]][key];
            };

            // Se nenhuma ação válida for encontrada, retorna 'default'
            return icones.default[key];
        },

        obterIconeTopoPersonalizadoPorTipoDialogo(tipo) {
            const tiposDialogo = {
                //dashlite icons
                error: `
                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                        <span class="swal2-x-mark">
                            <span class="swal2-x-mark-line-left"></span>
                            <span class="swal2-x-mark-line-right"></span>
                        </span>
                    </div>
                `,
                success: `
                    <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;"><div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                        <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                    </div>
                `,
                danger: `
                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;"><span class="swal2-x-mark">
                            <span class="swal2-x-mark-line-left"></span>
                            <span class="swal2-x-mark-line-right"></span>
                        </span>
                    </div>
                `,
                warning: `
                    <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">
                        <div class="swal2-icon-content">!</div>
                    </div>
                `,
                info: `
                    <div class="swal2-icon swal2-info swal2-icon-show" style="display: flex;">
                        <div class="swal2-icon-content">i</div>
                    </div>
                `,
                loading: `
                    <div class="text-center">  <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">    <span class="visually-hidden"></span>  </div></div>
                `
            };

            if (tipo && tiposDialogo.hasOwnProperty(tipo)) {
                return tiposDialogo[tipo];
            }

        },

        obterTituloPadrao(titulo) {
            const titulosPadrao = {
                success: "Sucesso",
                danger: "Erro",
                warning: "Atenção",
                info: "Informação",
            };

            if (titulo && titulosPadrao.hasOwnProperty(titulo)) {
                return titulosPadrao[titulo];
            }

        },


        gerarAcompanhamentoDialogo(_) {
            let referenciasComponenteNotificacao = this.$refs.notification;

            if (referenciasComponenteNotificacao.list.length > 0 && document.getElementById("modalDialogoGlobal")) {
                this.$bvModal.hide("modalDialogoGlobal");
            };
            this.resetarAcompanhamentoDialogo();

            referenciasComponenteNotificacao.list.forEach((notificacao) => {
                if (notificacao.type !== 'dialog') {
                    return;
                };

                const { data } = notificacao;

                if (data && data.hasError) {
                    this.iconeDialogo = 'error';
                    this.gerandoAcompanhamentoDialogo = false;
                };

                if (data && data.isInfo) {
                    this.iconeDialogo = 'info';
                    this.gerandoAcompanhamentoDialogo = false;
                };

                if (data && data.hideDialog) {
                    this.$refs.notification.destroyAll();
                };

                if (this.gerandoAcompanhamentoDialogo !== false) {
                    this.gerandoAcompanhamentoDialogo = !this.lodash.get(data, 'showOkButton', false);
                };

                if (this.gerandoAcompanhamentoDialogo === false) {
                    if (data && !data.hasError && !data.isInfo) {
                        this.iconeDialogo = 'success';
                    };
                };

                this.dadosDialogos.unshift({
                    valor: notificacao.text
                });
            });

            this.dadosDialogos.pop();
        },

    },


};
</script>

<style lang="scss">
.clic-notificacao {

    margin: 0 5px 5px;
    padding: 10px;
    font-size: 12px;
    color: #383d41;
    background: #e2e3e5 !important;
    border-left: 5px solid #383d41;

    &.informacao {
        color: #0c5460;
        background: #d1ecf1 !important;
        border-left-color: #0c5460;
        opacity: 0.9;
    }

    &.sucesso {
        color: #155724;
        background: #d4edda !important;
        border-left-color: #155724;
        opacity: 0.9;
    }

    &.alerta {
        color: #856404;
        background: #fff3cd !important;
        border-left-color: #856404;
        opacity: 0.9;
    }

    &.erro {
        color: #721c24;
        background: #f8d7da !important;
        border-left-color: #721c24;
        opacity: 0.9;
    }
}

.alert-dismissible .close:after {
    content: "" !important
}

.alert-light {
    border: none !important;
    border-color: 'white' !important;
    color: #526484;
}

.container-button-close-notification{
    justify-content: end;
    display: flex;
    margin-bottom: 8px;
}

.button-close-notification{
    background-color: white !important;
    border: none !important;
    padding: 0px !important;

}
</style>