import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
     
        // customFormatter  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // key:{
        //     hintTitle: "",
        //     hintText:""
        // },              
        // label:{
        //     hintTitle: "",
        //     hintText:""
        // },
        // sortable :{
        //     hintTitle: "",
        //     hintText:""
        // },

        
    }
}