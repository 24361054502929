import containerFW from '../components/clicGridLayout/ClicColInternoModel';
import iconFW from '../components/clicIcon/ClicIconInternoModel';
import imageFW from '../components/clicImage/ClicImageInternoModel';
import hintFW from '../components/clicHint/ClicHintInternoModel';
import badgeFW from '../components/clicBadge/ClicBadgeInternoModel';


const getAllComponentsProperties = () => {
    let retorno = [];

    retorno.push(
        containerFW,
        iconFW,
        badgeFW,
        imageFW,
        hintFW
    )

    return Object.assign({}, ...retorno);
}

export default getAllComponentsProperties