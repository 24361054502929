export default {
    documentation : {
        visible: {
            hintTitle: "Visible",
            hintText: "Quando definido como <b style='color:#343a40!important' >false</b>, o componente não é exibido na tela. Para exibir o componente, defina a propriedade como <b style='color:#343a40!important'>true</b>"
        },

        class: {
            hintTitle: "Class",
            hintText: `Selecione ou adicione as classes que desejar. 
            <a href='https://getbootstrap.com/docs/4.5/utilities/spacing/' target="_blank">Veja exemplos de classes padrões do Bootstrap 4:</a>
            `
        },

        style: {
            hintTitle: "Style",
            hintText: `Informe o style desejado.
            <br>
            <p class='small'>Preferencialmente utilize classes sempre que possível ao ínves de um Style personalizado.</p> 
            `
        },

        created: {
            hintTitle: "Created",
            hintText: `Este evento é o primeiro a ser executado no ciclo de vida dos Vue Hooks.
            <br>
            <a href='https://vuejs.org/guide/essentials/lifecycle.html#registering-lifecycle-hooks' target='_blank' class='small'>
                Veja mais detalhes sobre o ciclo de vida dos hooks do Vue.</a> 
            `
        },

        mounted: {
            hintTitle: "Mounted",
            hintText: `O evento é disparado após a criação do componente, quando o componente ainda não está montado.
            <br>
            <a href='https://vuejs.org/guide/essentials/lifecycle.html#registering-lifecycle-hooks' target='_blank' class='small'>
                Veja mais detalhes sobre o ciclo de vida dos hooks do Vue.</a> 
            `
        },

        updated: {
            hintTitle: "Updated",
            hintText: `O evento é disparado quando há uma mudança em qualquer área do componente. 
            <br>
            <a href='https://vuejs.org/guide/essentials/lifecycle.html#registering-lifecycle-hooks' target='_blank' class='small'>
                Veja mais detalhes sobre o ciclo de vida dos hooks do Vue.</a> 
            `
        },
    },

}
