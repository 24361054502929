import storage from '../store/modules/storage';

class TokenService {

    constructor() {
        this.sessionKey = storage.getSessionKey();
    }

    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem(this.sessionKey));
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem(this.sessionKey));
        return user?.accessToken;
    }

    updateLocalAccessToken(accessToken, refreshToken) {
        let user = JSON.parse(localStorage.getItem(this.sessionKey));
        user.accessToken    = accessToken;
        user.refreshToken   = refreshToken;
        localStorage.setItem(this.sessionKey, JSON.stringify(user));
    }

    updateLocalAccessTokenAllProperties(refreshSessionData) {
        let user = JSON.parse(localStorage.getItem(this.sessionKey));  
        Object.assign(user, refreshSessionData);
        localStorage.setItem(this.sessionKey, JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(localStorage.getItem(this.sessionKey));
    }

    setUser(user) {
        localStorage.setItem(this.sessionKey, JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem(this.sessionKey);
    }
}

export default new TokenService();