<template>
    <ClicColInterno
        :cp="cp"
    >
        <div class="form-group left-label">    
            <label class="form-label" >
                {{ $storage.getTextoTraduzido(label) }}
            </label> 
            <div class="form-control-wrap">       
                <b-form-checkbox  
                    v-model="value" 
                    style="height: 1.806rem;"
                    switch
                    :disabled="disabled"
                    :class="this.class"
                    :style="style"
                    @change="change"
                    @input="input"
                >
                </b-form-checkbox>
            </div>
        </div>
    </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name : 'ClicSwitch',
    mixins: [ComponentMixin],

    data () {
        return {
            public:{
                isValid     : this.validateField,
                clearField  : this.clearField,

                undo            : this.undo,
                apply           : this.apply,
                isValueChanged  : this.isValueChanged,
            },

            value     : this.cp.props.value || false,
            oldValue  : this.cp.props.value || false,
            
        }
    },

    mounted() {
        this.oldValue = this.value;
    },


    methods: {

        change(checked) {
            if  (this.lodash.has(this.cp, 'events.change')) {
                try {
                    eval(this.cp.events.change);
                } catch (e) {
                    console.error(`[ClicSwitch] Falha ao executar evendo 'change'. Detalhes: ${e}`);
                }
            }
        },

        input(checked) {
            if  (this.lodash.has(this.cp, 'events.input')) {
                try {
                    eval(this.cp.events.input);
                } catch (e) {
                    console.error(`[ClicSwitch] Falha ao executar evendo 'input'. Detalhes: ${e}`);
                }
            }
        }
    },
};
</script>