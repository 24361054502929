<template>
  <transition name="fade">
    <b-col
        :id="$utils.getComponentID()"
        :align-self="getProp('alignSelf')"
        :col="getProp('col')"
        :cols="getProp('cols')"
        :lg="getProp('lg')"
        :md="getProp('md')"
        :offset="getProp('offset')"
        :offset-lg="getProp('offsetLG')"
        :offset-md="getProp('offsetMD')"
        :offset-sm="getProp('offsetSM')"
        :offset-xl="getProp('offsetXL')"
        :order="getProp('order')"
        :order-lg="getProp('orderLG')"
        :order-md="getProp('orderMD')"
        :order-sm="getProp('orderSM')"
        :order-xl="getProp('orderXL')"
        :sm="getProp('sm')"
        :tag="getProp('containerTag')"
        :xl="getProp('xl')"
        :style="getProp('containerStyle')"
        :class="getProp('containerClass')"
        style="padding-top: 8px; padding-bottom: 8px;"
        v-show="getProp('visible', true)"
    >

      <b-row v-if="getProp('containerTitle')">
        <b-col md=12 style="padding: 14px;">
          <h6 class="title">{{ $storage.getTextoTraduzido(getProp('containerTitle')) }}</h6>
          <h6 class="subtitle p-1">{{ $storage.getTextoTraduzido(getProp('containerSubtitle')) }}</h6>
        </b-col>
      </b-row>
      
      <slot/>

    </b-col>
  </transition>
</template>

<script>

import ComponentInternalMixin from '../../core/ComponentInternalMixin';

export default {
    name: 'ClicColInterno',
    mixins: [ComponentInternalMixin],
};
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

</style>