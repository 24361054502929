<template>
    <ClicColInterno
        :cp="cp"
    >
        <div class="form-group left-label" style="height: 100%;">    
            <div class="d-flex flex-row align-items-start">
                <label class="form-label form-label-clic" v-if="label">
                    {{ $storage.getTextoTraduzido(label) }}
                </label>
                <ClicHintInterno
                    v-if="hintText"
                    :cp="cp"
                />
            </div>

            <vue-tree-list
                :model="model"
                :default-tree-node-name="defaultTreeNodeName || 'Novo'"
                :default-leaf-node-name="defaultLeafNodeName || 'Novo'"
                :default-expanded="defaultExpanded"
                style="overflow: auto; height: 100%;"
                :style="style"
                :class="this.class"
                @click="click"
                @change-name="changeName"
                @delete-node="deleteNode"
                @add-node="addNode"
                @drop="drop"
                @drop-before="dropBefore"
                @drop-after="dropAfter"
                class="clicTree"
            >
                <template v-slot:leafNameDisplay="slotProps">
                    <span>{{ slotProps.model.name }}</span>
                </template>
                
                <span class="icon" slot="addTreeNodeIcon" v-show="addTreeNodeIcon">
                    <ClicIconInterno
                        :iconName="addTreeNodeIcon"
                        title="Novo"
                    />
                </span>
                
                <span class="icon" slot="addLeafNodeIcon" v-show="addLeafNodeIcon">
                    <ClicIconInterno
                        :iconName="addLeafNodeIcon"
                        title="Novo"
                    />
                </span>
                <span class="icon" slot="editNodeIcon" v-show="editNodeIcon">
                    <ClicIconInterno
                        :iconName="editNodeIcon"
                        title="Editar"
                    />
                </span>
                <span class="icon" slot="delNodeIcon" v-show="delNodeIcon">
                    <ClicIconInterno
                        :iconName="delNodeIcon"
                        title="Remover"
                    />
                </span>
                <span class="icon" slot="leafNodeIcon" v-show="leafNodeIcon">
                    <ClicIconInterno
                        :iconName="leafNodeIcon"
                    />
                </span>

                <template v-slot:treeNodeIcon="slotProps">
                    <span class="icon" v-if="treeNodeIcon || slotProps.model.data.treeNodeIcon || slotProps.model.data.treeNodeIconUrl">
                        <ClicIconInterno
                            v-if="slotProps.model.data.treeNodeIcon"
                            :iconName="slotProps.model.data.treeNodeIcon"
                        />
                        <ClicImageInterno
                            v-if="slotProps.model.data.treeNodeIconUrl"
                            :imageSrc="slotProps.model.data.treeNodeIconUrl"
                            style="height: 18px;"
                        />
                    </span>
                    <span class="icon" v-else></span>
                </template>
            </vue-tree-list>
        </div>
    </ClicColInterno>
</template>


<script>

import ComponentMixin from '../../core/ComponentMixin';
import {VueTreeList, Tree, TreeNode} from 'vue-tree-list';

export default {
    name: 'ClicTreeView',
    mixins: [ComponentMixin],
    components:{
        'vue-tree-list' : VueTreeList
    },

    data() {
        return {

            atributeLabel       : 'label',
            atributeId          : 'id',
            atributeChildren    : 'children',  
            
            public : {
                getSelectedNode : this.getSelectedNode,
                nodeAdd : this.nodeAdd,
                nodeRemove : this.nodeRemove,
                dataRefresh : this.atualizarConsulta,
                getRootNodeFromSelectedNode : this.getRootNodeFromSelectedNode,
                clearSelectedNode: this.clearSelectedNode,
                nodeOverride : this.nodeOverride,
                getValueAsJson : this.getValueAsJson

            },

            model : new Tree([]),
            parametrosConsulta : {},

            selectedNode : undefined,

            timeoutId : null,
            isLoading : false
        }
    },
    
    created() {
        if  (!this.atributeId) {
            console.error(`[ClicTreeView] não carregado. Verifique se a prop 'atributoId' foi informada`);

        } else if  (!this.atributeLabel) {
            console.error(`[ClicTreeView] não carregado. Verifique se a prop 'atributoLabel' foi informada`);

        } else {
            if  (this.axios) {
                this.atualizarConsulta(this.axios);
            }
        }
    },

    methods: {

        getSelectedNode() {
            return this.selectedNode;
        },

        clearSelectedNode(){
            this.selectedNode = undefined;
        },

        getRootNodeFromSelectedNode() {
            function getRoot(nodeA) {
                if  (nodeA.parent.name == 'root') {
                    return nodeA;
                } else {
                    return getRoot(nodeA.parent);
                }
            }
            return this.selectedNode && getRoot(this.selectedNode);
        },

        nodeAdd(newNode) {
            let defaultNode = {}; 
            defaultNode.internalId = this.$utils.getComponentID();
            defaultNode.name = "Novo";
            defaultNode.id = "Novo";
            defaultNode.data = {};
            
            let node = new TreeNode({...defaultNode, ...newNode});
            if (!this.model.children) { this.model.children = []; }
            this.model.addChildren(node);   
            this.addNode(newNode);
        },

        nodeRemove() {
            this.model.remove();
        },
    
        nodeOverride(changedNode) {
            let itensArray = this.converterTreeModelParaJson([...this.model.children]);
            let itensAtualizado = this.sobreescreverNodeDoModel(itensArray, changedNode);
            let novoModel = this.converterJsonParaTreeModel(itensAtualizado);
            this.model = novoModel;
        },

        converterTreeModelParaJson(treeModel) {
            let itens = Object.keys(treeModel).map((key) => {
                let item = treeModel[key];
                if (item.children && item.children.length > 0){
                    item.children = this.converterTreeModelParaJson(item.children);
                }
                return item;
            });
            return itens;
        },

        converterJsonParaTreeModel(jsonArray=[]) {
            return new Tree(this.montarJsonParaTreeModel(jsonArray));
        },

        montarJsonParaTreeModel(jsonArray=[]) {
            let lista = [];
            
            for(let item of jsonArray){
                let novoItem                    = {};
                novoItem.data                   = item.data || item;
                novoItem.internalId             = item.internalId || this.$utils.getComponentID();
                novoItem.id                     = novoItem.data[this.atributeId];
                novoItem.name                   = novoItem.data[this.atributeLabel];
                novoItem.isLeaf                 = item.isLeaf;
                novoItem.dragDisabled           = item.dragDisabled;
                novoItem.addTreeNodeDisabled    = item.addTreeNodeDisabled;
                novoItem.addLeafNodeDisabled    = item.addLeafNodeDisabled;
                novoItem.editNodeDisabled       = item.editNodeDisabled;
                novoItem.delNodeDisabled        = item.delNodeDisabled;

                let children                    = item[this.atributeChildren] || item.children;
                if  (children) {
                    novoItem.children           = this.montarJsonParaTreeModel(children);
                }
                delete novoItem.data[this.atributeChildren];

                lista.push(novoItem);
            }
            return lista;
        },

        sobreescreverNodeDoModel(items, changedNode) {
            for (let i=0; i<items.length; i++){
                if  (items[i].internalId == changedNode.internalId) {
                    items[i] = changedNode;
                    break;
                } else if (items[i].children) {
                    items[i].children = this.sobreescreverNodeDoModel(items[i].children, changedNode);
                }
            }
            return items;
        },

        getValueAsJson() {
            const itens = this.converterTreeModelParaJson([...this.model.children]);
            return this.obterJsonDataRetorno(itens);
        },

        obterJsonDataRetorno(jsonArray) {
            let retorno = [];
            for (let item of jsonArray) {
                let children = item.children;
                item = item.data;
                if  (children) {
                    item[this.atributeChildren] = this.obterJsonDataRetorno(children);
                }
                delete item['isLeaf'];
                delete item['dragDisabled'];
                delete item['addTreeNodeDisabled'];
                delete item['addLeafNodeDisabled'];
                delete item['editNodeDisabled'];
                delete item['delNodeDisabled'];
                retorno.push(item);
            }
            return retorno; 
        },

        atualizarConsulta(parametros) {
            this.isLoading = true;

            if  (parametros && parametros.data) {
                this.model = this.converterJsonParaTreeModel(parametros.data);

            } else { 
                if  (parametros) {
                    this.parametrosConsulta = {
                        ...this.axios,
                        ...parametros
                    };
                }
                if  (this.parametrosConsulta) {
                    this.$axios(this.parametrosConsulta).then((response) => {
                        this.isLoading = false;
                        let dadosApi = this.responsePath ? this.lodash.get(response, 'data.'+this.responsePath, []) : response.data; 
                        this.model = this.converterJsonParaTreeModel(dadosApi);
                    });
                } else {
                    this.isLoading = false;
                }
            }
        },


        /******************* 
                Events      
        ********************/

        click(node) {
            var _this = this;
            if  (!this.timeoutId) {
                this.timeoutId = setTimeout(() => {
                    this.selectedNode = node;
                    node.toggle();
                    let click = this.lodash.get(this.cp, 'events.click');
                    if  (click) {
                        try {
                            click = click.replaceAll('this', '_this');
                            eval(click);
                        } catch(e) {
                            console.error(`[ClicTreeView] Falha ao executar o evento 'click'. Detalhes: ${e}`);
                        }
                    }
                    this.timeoutId = null;
                }, 200);
            } else {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
                this.selectedNode = node;
                node.toggle();
                let click = this.lodash.get(this.cp, 'events.doubleClick');
                if  (click) {
                    try {
                        click = click.replaceAll('this', '_this');
                        eval(click);
                    } catch(e) {
                        console.error(`[ClicTreeView] Falha ao executar o evento 'doubleClick'. Detalhes: ${e}`);
                    }
                }
            }
        },

        changeName(params) {
            var _this = this;
            let changeNameEvt = this.lodash.get(this.cp, 'events.changeName');
            if  (changeNameEvt) {
                try {
                    changeNameEvt = changeNameEvt.replaceAll('this', '_this');
                    eval(changeNameEvt);
                } catch(e) {
                    console.error(`[ClicTreeView] Falha ao executar o evento 'changeName'. Detalhes: ${e}`);
                }
            }
        },

        deleteNode(node) {
            var _this = this;
            let deleteNodeEvt = this.lodash.get(this.cp, 'events.deleteNode');
            if  (deleteNodeEvt) {
                try {
                    deleteNodeEvt = deleteNodeEvt.replaceAll('this', '_this');
                    eval(deleteNodeEvt);
                } catch(e) {
                    console.error(`[ClicTreeView] Falha ao executar o evento 'deleteNode'. Detalhes: ${e}`);
                }
            }
            node.remove();
        },

        addNode(params) {
            var _this = this;
            let addNodeEvt = this.lodash.get(this.cp, 'events.addNode');
            if  (addNodeEvt) {
                try {
                    addNodeEvt = addNodeEvt.replaceAll('this', '_this');
                    eval(addNodeEvt);
                } catch(e) {
                    console.error(`[ClicTreeView] Falha ao executar o evento 'addNode'. Detalhes: ${e}`);
                }
            }
        },

        drop(node, src, target) {
            var _this = this;
            let dropEvt = this.lodash.get(this.cp, 'events.drop');
            if  (dropEvt) {
                try {
                    dropEvt = dropEvt.replaceAll('this', '_this');
                    eval(dropEvt);
                } catch(e) {
                    console.error(`[ClicTreeView] Falha ao executar o evento 'drop'. Detalhes: ${e}`);
                }
            }
        },

        dropBefore(node, src, target) {
            var _this = this;
            let dropBeforeEvt = this.lodash.get(this.cp, 'events.dropBefore');
            if  (dropBeforeEvt) {
                try {
                    dropBeforeEvt = dropBeforeEvt.replaceAll('this', '_this');
                    eval(dropBeforeEvt);
                } catch(e) {
                    console.error(`[ClicTreeView] Falha ao executar o evento 'dropBefore'. Detalhes: ${e}`);
                }
            }
        },
        
        dropAfter(node, src, target) {
            var _this = this;
            let dropAfterEvt = this.lodash.get(this.cp, 'events.dropAfter');
            if  (dropAfterEvt) {
                try {
                    dropAfterEvt = dropAfterEvt.replaceAll('this', '_this');
                    eval(dropAfterEvt);
                } catch(e) {
                    console.error(`[ClicTreeView] Falha ao executar o evento 'dropAfter'. Detalhes: ${e}`);
                }
            }
        },

    },

}

</script>

<style lang="scss" rel="stylesheet/less">
    .vtl {
        .vtl-drag-disabled {
            background-color: #d0cfcf;
            &:hover {
                background-color: #d0cfcf;
            }
        }
        .vtl-disabled {
            background-color: #d0cfcf;
        }
    }
</style>

<style lang="scss" rel="stylesheet/less" scoped>
    .icon {
        &:hover {
            cursor: pointer;
        }
        padding-right: 8px;
    }
    
    .muted {
        color: gray;
        font-size: 80%;
    }

    .product-card {
        overflow-y: scroll;
        height: 100%;
    }

    .clicTree {
        width: 100%;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

</style>


