import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicImageInterno from '../clicImage/ClicImageInternoModel';
import ClicIconInterno from '../clicIcon/ClicIconInternoModel';
import ClicDropdownDocs from './ClicDropdownDocs';

export default {
    ClicDropdown : {
        config      : {isContainer: true, icon: "md-playlistplay-twotone", documentation: ClicDropdownDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            label           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            hint            : {type: 'string',      default: undefined,     component: { name : 'input' } },
         
            block           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            boundary        : {type: 'string',      default: undefined,     component: { name : 'input' } },
            disabled        : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dropleft        : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dropright       : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            dropup          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            html            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            lazy            : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            menuClass       : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            noCaret         : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            noFlip          : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            offset          : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            popperOpts      : {type: 'object',      default: undefined,     component: { name : 'input' } },
            right           : {type: 'boolean',     default: true,          component: { name : 'switch' } },
            role            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            size            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            split           : {type: 'boolean',     default: undefined,     component: { name : 'input' } },
            splitButtonType : {type: 'string',      default: undefined,     component: { name : 'input' } },
            splitClass      : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            splitHref       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            splitTo         : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            splitVariant    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            text            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            toggleAttrs     : {type: 'object',      default: undefined,     component: { name : 'input' } },
            toggleClass     : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            toggleTag       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            toggleText      : {type: 'string',      default: undefined,     component: { name : 'input' } },
            variant         : {type: 'string',      default: undefined,     component: { name : 'select',  values: 
                                ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'] } 
                            },  

            ...ClicIconInterno,
            ...ClicImageInterno,
            ...ClicColInterno   
        },
        events : {
        }
    }
}