export default {
    ClicTableColumnDetail : {
        config      : {icon:"md-switchright-outlined", isContainer: true}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {            

            key                 : {type: 'string',      default: undefined, component: { name : 'input' } },      

        },
        events : {           
        }
    }
}