<template>
    <b-col
        :key="componentKey"
        :align-self="alignSelf"
        :col="col"
        :cols="cols"
        :lg="lg"
        :md="md"
        :offset="offset"
        :offset-lg="offsetLG"
        :offset-md="offsetMD"
        :offset-sm="offsetSM"
        :offset-xl="offsetXL"
        :order="order"
        :order-lg="orderLG"
        :order-md="orderMD"
        :order-sm="orderSM"
        :order-xl="orderXL"
        :sm="sm"
        :tag="tag"
        :xl="xl"
        :style="style"
        :class="this.class"
        v-show="visible"
      >
        <b-row v-if="title">
            <b-col md=12 style="padding: 14px;">
                <h6 class="title">{{ $storage.getTextoTraduzido(title) }}</h6>
                <h6 class="subtitle p-1">{{ $storage.getTextoTraduzido(subtitle) }}</h6>
            </b-col>
        </b-row>
        <MotorS 
            :cp="cp" 
            :childrens="childrens"
            :idTela="idTela"
            :state="state"
        />
    </b-col>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicCol',
    mixins: [ComponentMixin],

    data() {
        return {
        
        };
    },
};
</script>