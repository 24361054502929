import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
       
        ...GeneralProperties.documentation,

        // alignContent     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // alignH           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // alignV           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // cols             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // colsLG           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // colsMD           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // colsSM           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // colsXL           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noGutters        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // tag              :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}









