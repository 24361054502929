<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
    :id="componentKey"
  >
    <div class="form-group left-label">    
      <div class="d-flex flex-row align-items-start">
          <label class="form-label form-label-clic" v-if="label">
            <b v-if="required && (value == undefined || value === '')" class="text-danger">*</b>
            {{ $storage.getTextoTraduzido(label) }}
          </label>
      </div>

      <div class="form-control-wrap">
        
      
 

        <b-form-file
          :style="style"
          :label="label"
          v-model="value"
          :invalidFeedbackMessage="invalidFeedbackMessage"
          :autofocus="autofocus"
          :browse-text="browseText"
          :accept="accept"
          :capture="capture"
          :disabled="disabled"
          :directory="directory"
          :drop-placeholder="dropPlaceholder"
          :file-name-formatter="fileNameFormatter"
          :multiple="multiple"
          :no-drop-placeholder="noDropPlaceholder"
          :no-traverse="noTraverse"
          :placeholder="$storage.getTextoTraduzido(placeholder)"
          :required="required"
          :plain="plain"
          :size="size"
          :state="fieldState"
          :class="`form-control ${this.class}`"
          @change="change"
          @input="input"
        >
        
        </b-form-file>


        <b-form-invalid-feedback 
          v-if="invalidFeedbackMessage" 
        >
          {{invalidFeedbackMessage}}
        </b-form-invalid-feedback>
      </div>
    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicInputFile',
  mixins: [ComponentMixin],

  data() {
    return {
      value       : undefined,
      oldValue    : undefined,
      fieldState  : undefined,          
      public:{
        isValid         : this.validateField,
        clearField      : this.clearField,
        undo            : this.undo,
        apply           : this.apply,
        isValueChanged  : this.isValueChanged,
      }
    }
  },

  // mounted() {
  //   this.oldValue = this.value;
  // },
  
  methods: {
    input(value) {
      if(this.lodash.has(this.cp, 'events.input')){
        try {
          eval(this.cp.events.input);
        } catch (e) {
          console.error(`[ClicInputFile] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
    change(value) {
      if(this.lodash.has(this.cp, 'events.change')){
        try {
          eval(this.cp.events.change);
        } catch (e) {
          console.error(`[ClicInputFile] Falha ao executar evento 'input'. Detalhes: ${e}`);
        }
      }
    },
  },

};

</script>

<style>
.invalid-feedback{
  margin-top:0;
}
</style>