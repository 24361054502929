import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation

        // seriesType      : {
        //     hintTitle: "",
        //     hintText:""
        // },

        // chartOptions    : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // chartData       : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // chartId         : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // datasetIdKey    : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // plugins         : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // cssClasses      : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // styles          : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // width           : {
        //     hintTitle: "",
        //     hintText:""
        // },
        // height          : {
        //     hintTitle: "",
        //     hintText:""
        // },

    }
}