<template>
<!--
    <div class="container-fluid">
        <div class="clic-content-inner">
            <div class="clic-content-body">



   
                <div class="form-group">
                    <label class="form-label">Cliente</label>
                    <div class="form-control-wrap">
                        <select class="form-select js-select2 select2-hidden-accessible" multiple="" data-placeholder="Selecione um cliente" data-select2-id="9" tabindex="-1" aria-hidden="true">
                            <option value="default_option" data-select2-id="22">Default Option</option>
                            <option value="option_select_name" data-select2-id="23">Option select name</option>
                            <option value="option_select_name" data-select2-id="24">Option select name</option>
                            <option value="option_select_name" data-select2-id="25">Option select name</option>
                            <option value="option_select_name" data-select2-id="26">Option select name</option>
                            <option value="option_select_name" data-select2-id="27">Option select name</option>
                            <option value="option_select_name" data-select2-id="28">Option select name</option>
                            <option value="option_select_name" data-select2-id="29">Option select name</option>
                            <option value="option_select_name" data-select2-id="30">Option select name</option>
                            <option value="option_select_name" data-select2-id="31">Option select name</option>
                            <option value="option_select_name" data-select2-id="32">Option select name</option>
                        </select>
                        <span class="select2 select2-container select2-container--default select2-container--below select2-container--focus select2-container--open" dir="ltr" data-select2-id="10" style="width: 445px;">
                            <span class="selection">
                                <span
                                    class="select2-selection select2-selection--multiple"
                                    role="combobox"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                    tabindex="-1"
                                    aria-disabled="false"
                                    aria-owns="select2-q7jz-results"
                                    aria-activedescendant="select2-q7jz-result-aulo-option_select_name"
                                >
                                    <ul class="select2-selection__rendered">
                                        <li class="select2-search select2-search--inline">
                                            <input
                                                class="select2-search__field form-control-lg"
                                                type="search"
                                                tabindex="0"
                                                autocomplete="off"
                                                autocorrect="off"
                                                autocapitalize="none"
                                                spellcheck="false"
                                                role="searchbox"
                                                aria-autocomplete="list"
                                                placeholder="Select Multiple options"
                                                style="width: 437px;"
                                                aria-controls="select2-q7jz-results"
                                                aria-activedescendant="select2-q7jz-result-aulo-option_select_name"
                                            />
                                        </li>
                                    </ul>
                                </span>
                            </span>
                            <span class="dropdown-wrapper" aria-hidden="true"></span>
                        </span>
                    </div>
                </div>
        

                <br>




                <div class="nk-block">
                    <div class="card">
                        <div class="card-aside-wrap">
                            <div class="card-content">
                                <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="#"><em class="icon ni ni-user-circle"></em><span>Informações úteis</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#"><em class="icon ni ni-repeat"></em><span>Últimos Pedidos</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#"><em class="icon ni ni-file-text"></em><span>Outro...</span></a>
                                    </li>
                                   
                                </ul>
                                <div class="card-inner">
                                     <div class="">
                                        <div class="card-inner">
                                            <div class="analytic-au">
                                                <div class="analytic-data-group analytic-au-group g-3">
                                                    <div class="analytic-data analytic-au-data">
                                                        <div class="title">Vendas</div>
                                                        <div class="amount">9.28K</div>
                                                        <div class="change up"><em class="icon ni ni-arrow-long-up"></em>4.63%</div>
                                                    </div>
                                                    <div class="analytic-data analytic-au-data">
                                                        <div class="title">Pendências</div>
                                                        <div class="amount">2.69K</div>
                                                        <div class="change down"><em class="icon ni ni-arrow-long-down"></em>1.92%</div>
                                                    </div>
                                                    <div class="analytic-data analytic-au-data">
                                                        <div class="title">Outro</div>
                                                        <div class="amount">0.94K</div>
                                                        <div class="change up"><em class="icon ni ni-arrow-long-up"></em>3.45%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                
                <br><br>


                <div class="card card-bordered card-preview">
                    <div class="card-inner">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group"><label class="form-label" for="default-01">Tipo de Venda</label><div class="form-control-wrap"><input type="text" class="form-control" id="default-01" placeholder="Input placeholder"></div></div>
                            </div>

                            <div class="col-md-4">
                                <div class="preview-block"><span class="preview-title overline-title">Orçamento?</span><div class="custom-control custom-switch checked"><input type="checkbox" class="custom-control-input" checked="" id="customSwitch2"><label class="custom-control-label" for="customSwitch2">Sim</label></div></div>
                            </div>

                            <div class="col-md-4">

                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="clic-block-head clic-block-head-sm">
                    <div class="clic-block-between">
                        <div class="clic-block-head-content">
                            <h3 class="clic-block-title page-title">Produtos</h3>
                        </div>
                        <div class="clic-block-head-content">
                            <div class="toggle-wrap clic-block-tools-toggle">
                                <a href="#" class="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
                                <div class="toggle-expand-content" data-content="pageMenu">
                                    <ul class="clic-block-tools g-3">
                                        <li>
                                            <div class="form-control-wrap">
                                                <div class="form-icon form-icon-right"><em class="icon ni ni-search"></em></div>
                                                <input type="text" class="form-control" id="default-04" placeholder="Quick search by id" />
                                            </div>
                                        </li>
                                        <li class="clic-block-tools-opt">
                                            <a href="#" data-target="addProduct" class="toggle btn btn-icon btn-primary d-md-none"><em class="icon ni ni-plus"></em></a>
                                            <a href="#" data-target="addProduct" class="toggle btn btn-primary d-none d-md-inline-flex"><em class="icon ni ni-plus"></em><span>Add Product</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clic-block">
                    <div class="card">
                        <div class="card-inner-group">
                            <div class="card-inner p-0">
                                <div class="clic-tb-list">
                                    <div class="clic-tb-item clic-tb-head">
                                        <div class="clic-tb-col clic-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext"><input type="checkbox" class="custom-control-input" id="pid" /><label class="custom-control-label" for="pid"></label></div>
                                        </div>
                                        <div class="clic-tb-col tb-col-sm"><span>Name</span></div>
                                        <div class="clic-tb-col"><span>SKU</span></div>
                                        <div class="clic-tb-col"><span>Price</span></div>
                                        <div class="clic-tb-col"><span>Stock</span></div>
                                        <div class="clic-tb-col tb-col-md"><span>Category</span></div>
                                        <div class="clic-tb-col tb-col-md"><em class="tb-asterisk icon ni ni-star-round"></em></div>
                                        <div class="clic-tb-col clic-tb-col-tools">
                                            <ul class="clic-tb-actions gx-1 my-n1">
                                                <li class="me-n1">
                                                    <div class="dropdown">
                                                        <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <ul class="liclic-list-opt no-bdr">
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-edit"></em><span>Edit Selected</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-trash"></em><span>Remove Selected</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-bar-c"></em><span>Update Stock</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-invest"></em><span>Update Price</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="clic-tb-item">
                                        <div class="clic-tb-col clic-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext"><input type="checkbox" class="custom-control-input" id="pid1" /><label class="custom-control-label" for="pid1"></label></div>
                                        </div>
                                        <div class="clic-tb-col tb-col-sm">
                                            <span class="tb-product"><img src="https://dashlite.net/demo2/images/product/a.png" alt="" class="thumb" /><span class="title">Pink Fitness Tracker</span></span>
                                        </div>
                                        <div class="clic-tb-col"><span class="tb-sub">UY3749</span></div>
                                        <div class="clic-tb-col"><span class="tb-lead">$ 99.49</span></div>
                                        <div class="clic-tb-col"><span class="tb-sub">49</span></div>
                                        <div class="clic-tb-col tb-col-md"><span class="tb-sub">Fitbit, Tracker</span></div>
                                        <div class="clic-tb-col tb-col-md">
                                            <div class="asterisk tb-asterisk">
                                                <a href="#"><em class="asterisk-off icon ni ni-star"></em><em class="asterisk-on icon ni ni-star-fill"></em></a>
                                            </div>
                                        </div>
                                        <div class="clic-tb-col clic-tb-col-tools">
                                            <ul class="clic-tb-actions gx-1 my-n1">
                                                <li class="me-n1">
                                                    <div class="dropdown">
                                                        <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <ul class="liclic-list-opt no-bdr">
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-edit"></em><span>Edit Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-eye"></em><span>View Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-activity-round"></em><span>Product Orders</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-trash"></em><span>Remove Product</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="clic-tb-item">
                                        <div class="clic-tb-col clic-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext"><input type="checkbox" class="custom-control-input" id="pid2" /><label class="custom-control-label" for="pid2"></label></div>
                                        </div>
                                        <div class="clic-tb-col tb-col-sm">
                                            <span class="tb-product"><img src="https://dashlite.net/demo2/images/product/b.png" alt="" class="thumb" /><span class="title">Purple Smartwatch</span></span>
                                        </div>
                                        <div class="clic-tb-col"><span class="tb-sub">UY3750</span></div>
                                        <div class="clic-tb-col"><span class="tb-lead">$ 89.49</span></div>
                                        <div class="clic-tb-col"><span class="tb-sub">103</span></div>
                                        <div class="clic-tb-col tb-col-md"><span class="tb-sub">Gadgets, Fitbit, Tracker</span></div>
                                        <div class="clic-tb-col tb-col-md">
                                            <div class="asterisk tb-asterisk">
                                                <a href="#"><em class="asterisk-off icon ni ni-star"></em><em class="asterisk-on icon ni ni-star-fill"></em></a>
                                            </div>
                                        </div>
                                        <div class="clic-tb-col clic-tb-col-tools">
                                            <ul class="clic-tb-actions gx-1 my-n1">
                                                <li class="me-n1">
                                                    <div class="dropdown">
                                                        <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <ul class="liclic-list-opt no-bdr">
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-edit"></em><span>Edit Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-eye"></em><span>View Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-activity-round"></em><span>Product Orders</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-trash"></em><span>Remove Product</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="clic-tb-item">
                                        <div class="clic-tb-col clic-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext"><input type="checkbox" class="custom-control-input" id="pid3" /><label class="custom-control-label" for="pid3"></label></div>
                                        </div>
                                        <div class="clic-tb-col tb-col-sm">
                                            <span class="tb-product"><img src="https://dashlite.net/demo2/images/product/c.png" alt="" class="thumb" /><span class="title">Black Mi Band Smartwatch</span></span>
                                        </div>
                                        <div class="clic-tb-col"><span class="tb-sub">UY3751</span></div>
                                        <div class="clic-tb-col"><span class="tb-lead">$ 299.49</span></div>
                                        <div class="clic-tb-col"><span class="tb-sub">68</span></div>
                                        <div class="clic-tb-col tb-col-md"><span class="tb-sub">Smartwatch, Tracker</span></div>
                                        <div class="clic-tb-col tb-col-md">
                                            <div class="asterisk tb-asterisk">
                                                <a href="#" class="active"><em class="asterisk-off icon ni ni-star"></em><em class="asterisk-on icon ni ni-star-fill"></em></a>
                                            </div>
                                        </div>
                                        <div class="clic-tb-col clic-tb-col-tools">
                                            <ul class="clic-tb-actions gx-1 my-n1">
                                                <li class="me-n1">
                                                    <div class="dropdown">
                                                        <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <ul class="liclic-list-opt no-bdr">
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-edit"></em><span>Edit Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-eye"></em><span>View Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-activity-round"></em><span>Product Orders</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-trash"></em><span>Remove Product</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="clic-tb-item">
                                        <div class="clic-tb-col clic-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext"><input type="checkbox" class="custom-control-input" id="pid4" /><label class="custom-control-label" for="pid4"></label></div>
                                        </div>
                                        <div class="clic-tb-col tb-col-sm">
                                            <span class="tb-product"><img src="https://dashlite.net/demo2/images/product/d.png" alt="" class="thumb" /><span class="title">Black Headphones</span></span>
                                        </div>
                                        <div class="clic-tb-col"><span class="tb-sub">UY3752</span></div>
                                        <div class="clic-tb-col"><span class="tb-lead">$ 99.49</span></div>
                                        <div class="clic-tb-col"><span class="tb-sub">77</span></div>
                                        <div class="clic-tb-col tb-col-md"><span class="tb-sub">Headphone, Gadgets</span></div>
                                        <div class="clic-tb-col tb-col-md">
                                            <div class="asterisk tb-asterisk">
                                                <a href="#"><em class="asterisk-off icon ni ni-star"></em><em class="asterisk-on icon ni ni-star-fill"></em></a>
                                            </div>
                                        </div>
                                        <div class="clic-tb-col clic-tb-col-tools">
                                            <ul class="clic-tb-actions gx-1 my-n1">
                                                <li class="me-n1">
                                                    <div class="dropdown">
                                                        <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <ul class="liclic-list-opt no-bdr">
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-edit"></em><span>Edit Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-eye"></em><span>View Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-activity-round"></em><span>Product Orders</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-trash"></em><span>Remove Product</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="clic-tb-item">
                                        <div class="clic-tb-col clic-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext"><input type="checkbox" class="custom-control-input" id="pid5" /><label class="custom-control-label" for="pid5"></label></div>
                                        </div>
                                        <div class="clic-tb-col tb-col-sm">
                                            <span class="tb-product"><img src="https://dashlite.net/demo2/images/product/e.png" alt="" class="thumb" /><span class="title">iPhone 7 Headphones</span></span>
                                        </div>
                                        <div class="clic-tb-col"><span class="tb-sub">UY3753</span></div>
                                        <div class="clic-tb-col"><span class="tb-lead">$ 129.49</span></div>
                                        <div class="clic-tb-col"><span class="tb-sub">81</span></div>
                                        <div class="clic-tb-col tb-col-md"><span class="tb-sub">Headphone, Gadgets</span></div>
                                        <div class="clic-tb-col tb-col-md">
                                            <div class="asterisk tb-asterisk">
                                                <a href="#"><em class="asterisk-off icon ni ni-star"></em><em class="asterisk-on icon ni ni-star-fill"></em></a>
                                            </div>
                                        </div>
                                        <div class="clic-tb-col clic-tb-col-tools">
                                            <ul class="clic-tb-actions gx-1 my-n1">
                                                <li class="me-n1">
                                                    <div class="dropdown">
                                                        <a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <ul class="liclic-list-opt no-bdr">
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-edit"></em><span>Edit Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-eye"></em><span>View Product</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-activity-round"></em><span>Product Orders</span></a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><em class="icon ni ni-trash"></em><span>Remove Product</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="clic-add-product toggle-slide toggle-slide-right toggle-screen-any" data-content="addProduct" data-toggle-screen="any" data-toggle-overlay="true" data-toggle-body="true" data-simplebar="init">
                    <div class="simplebar-wrapper" style="margin: -24px;">
                        <div class="simplebar-height-auto-observer-wrapper">
                            <div class="simplebar-height-auto-observer"></div>
                        </div>
                        <div class="simplebar-mask">
                            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                                <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style="height: 100%; overflow: hidden scroll;">
                                    <div class="simplebar-content" style="padding: 24px;">
                                        <div class="clic-block-head">
                                            <div class="clic-block-head-content">
                                                <h5 class="clic-block-title">New Product</h5>
                                                <div class="clic-block-des">
                                                    <p>Add information and add new product.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clic-block">
                                            <div class="row g-3">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="product-title">Product Title</label>
                                                        <div class="form-control-wrap"><input type="text" class="form-control" id="product-title" /></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-label" for="regular-price">Regular Price</label>
                                                        <div class="form-control-wrap"><input type="number" class="form-control" id="regular-price" /></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-label" for="sale-price">Sale Price</label>
                                                        <div class="form-control-wrap"><input type="number" class="form-control" id="sale-price" /></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-label" for="stock">Stock</label>
                                                        <div class="form-control-wrap"><input type="text" class="form-control" id="stock" /></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-label" for="SKU">SKU</label>
                                                        <div class="form-control-wrap"><input type="text" class="form-control" id="SKU" /></div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="category">Category</label>
                                                        <div class="form-control-wrap"><input type="text" class="form-control" id="category" /></div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="form-label" for="tags">Tags</label>
                                                        <div class="form-control-wrap"><input type="text" class="form-control" id="tags" /></div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="upload-zone small bg-lighter my-2 dropzone dz-clickable">
                                                        <div class="dz-message"><span class="dz-message-text">Drag and drop file</span></div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button class="btn btn-primary"><em class="icon ni ni-plus"></em><span>Add New</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="simplebar-placeholder" style="width: auto; height: 700px;"></div>
                    </div>
                    <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                        <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
                    </div>
                    <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                        <div class="simplebar-scrollbar" style="height: 586px; display: block; transform: translate3d(0px, 0px, 0px);"></div>
                    </div>
                </div>





                <br>

 
                <div class="form-group"><label class="form-label" for="default-01">Condição de Pagamento</label><div class="form-control-wrap"><input type="text" class="form-control" id="default-01" placeholder="Input placeholder"></div></div>
                <div class="form-group"><label class="form-label" for="default-01">Forma de Pagamento</label><div class="form-control-wrap"><input type="text" class="form-control" id="default-01" placeholder="Input placeholder"></div></div>




                <br>


                <div class="nk-block card">
                    <table class="table table-ulogs">
                        <thead class="table-light">
                            <tr>
                                <th class="tb-col-os">
                                    <span class="overline-title" style="color: white;">Parcelas <span class="d-sm-none">/ IP</span></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="tb-col-os">1</td>
                                <td class="tb-col-ip"><span class="sub-text">01/01/2022</span></td>
                                <td class="tb-col-time"><span class="sub-text">R$ 100,00</span></td>
                            </tr>
                            <tr>
                                <td class="tb-col-os">2</td>
                                <td class="tb-col-ip"><span class="sub-text">01/02/2022</span></td>
                                <td class="tb-col-time"><span class="sub-text">R$ 100,00</span></td>
                            </tr>
                            <tr>
                                <td class="tb-col-os">3</td>
                                <td class="tb-col-ip"><span class="sub-text">01/03/2022</span></td>
                                <td class="tb-col-time"><span class="sub-text">R$ 100,00</span></td>
                            </tr>
                            <tr>
                                <td class="tb-col-os">4</td>
                                <td class="tb-col-ip"><span class="sub-text">01/04/2022</span></td>
                                <td class="tb-col-time"><span class="sub-text">R$ 100,00</span></td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
                



                <br>

                

                <div class="form-group"><label class="form-label" for="default-textarea">Observações</label><div class="form-control-wrap"><textarea class="form-control no-resize" id="default-textarea"></textarea></div></div>






            </div>
        </div>
    </div>
-->


    <b-container fluid>
        <MotorS 
            :cp="{}" 
            :childrens="childrens"
            idTela="demo"
        />
    </b-container>

</template>
<script>

export default {
  name: 'ClicButton',
  props: {
    cp:{
      type: Object
    },
    idTela: {
      type: String
    }
  },
  data() {
    return {
      isLoading : false,


        childrens : [
                {
                    "component": "ClicModal",
                    "id": "modalA",
                    "props": {
                        "title": "Lista de Turma Já criada para este Curso"
                    },
                    "childrens": [
                        {
                            "component": "ClicModalFooter",
                            "id": "modalFooter",
                            "childrens": [
                                {
                                    "component": "ClicButton",
                                    "id": "btnmodal-cancelar",
                                    "styles": {
                                        "cols": 1
                                    },
                                    "props": {
                                        "label": "Fechar",
                                        "variant": "secondary"
                                    },
                                    "events": {
                                        "change": "",
                                        "click": "let comp = this.$getPropriedades(this); comp.modalA.hideModal()"
                                    }
                                }
                            ]
                        },
                        {
                            "component": "ClicText",
                            "id": "LISTATURMAS",
                            "props": {
                                "md": "12",
                                "sm": "12",
                                "text": "..."
                            }
                        }
                    ]
                },

                {
                            "component": "ClicTable",
                            "id": "tabelaLogs",
                            "containerStyles": {
                                "height": "100%"
                            },
                            "props": {
                                "pathItemsHeaders": "headers",
                                "configAxiosContent": {
                                    "method": "GET",
                                    "url": "https://sgs.clictecnologia.com.br/api/logs",
                                    "params": {
                                        "fetch": 10
                                    }
                                },
                                "pathItemsRows": "dados",
                                "pathItemsRowsCounter": "totalRows",
                                "hover": true,
                                "stacked": "lg",
                                "striped": true,
                                "itemsPerPage": 10,
                                "tableTitle": "Ocorrências",
                                "hasFilter": true,
                                "hasPerPageSelect": true,
                                "controlsTopMenu": true,
                                "keysFilter": [
                                    "data",
                                    "dadosInternosLog.nomeObjeto",
                                    "log.inicioProcessamento",
                                    "log.fimProcessamento"
                                ]
                            }
                        },
                        {
                            "component": "ClicModal",
                            "id": "modalLogs",
                            "props": {
                                "title": "Visualizar dados"
                            },
                            "childrens": [
                                {
                                    "component": "ClicModalFooter",
                                    "id": "modalFooter",
                                    "childrens": [
                                        {
                                            "component": "ClicButton",
                                            "id": "btnmodal-cancelar",
                                            "props": {
                                                "label": "Fechar",
                                                "alignSelf": "center"
                                            },
                                            "events": {
                                                "click": "let comp = this.$getPropriedades(this); comp.modalLogs.hideModal()"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "component": "ClicJson",
                                    "id": "logJson",
                                    "props": {
                                        "label": {
                                            "PT": "Código",
                                            "EN": "Code"
                                        },
                                        "hint": {
                                            "PT": "Informe o código do produto",
                                            "EN": "Type product code"
                                        },
                                        "cols": 12
                                    }
                                }
                            ]
                        },
                
                
                {
                    "component": "ClicCard",
                    "id": "colunaLoginGeral",
                    "props": {
                        "cols": "12"
                    },
                    "childrens": [
                        {
                            "component": "ClicMultiselect",
                            "id": "SECCURSO",
                            "styles": {
                                "cols": 7
                            },
                            "props": {
                                "label": {
                                    "PT": "Curso",
                                    "EN": "Course"
                                },
                                "md": 12,
                                "placeholder": "Selecione o Curso",
                                "labels": "nome",
                                "axios": {
                                    "method": "GET",
                                    "url": "https://sgs.clictecnologia.com.br/api/listacursos"
                                }
                            },
                            "events": {
                                "select": "let comp = this.$getPropriedades(this); this.axios.get(`https://sgs.clictecnologia.com.br/api/listarturmas?curso=${selectedOption.id}`).then((resp) => { if (resp && resp.data && resp.data.sucesso && resp.data.data != '') { comp.modalA.showModal(); comp.modalA.childrens[1].props.text = resp.data.data; } });"
                            }
                        },
                        {
                            "component": "ClicRow",
                            "childrens": [
                                {
                                    "component": "ClicCol",
                                    "id": "",
                                    "props": {
                                        "md": "6"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicMultiselect",
                                            "id": "SECLOJA",
                                            "props": {
                                                "label": {
                                                    "PT": "Loja",
                                                    "EN": "Shopstore"
                                                },
                                                "cols": 12,
                                                "placeholder": "Selecione a Loja",
                                                "labels": "nome",
                                                "axios": {
                                                    "method": "GET",
                                                    "url": "https://sgs.clictecnologia.com.br/api/listalojas"
                                                }
                                            }
                                        }
                                    ]
                                },
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "6"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicMultiselect",
                                            "id": "SECMUNICIPIO",
                                            "props": {
                                                "hint": "Para ser aplicado apenas nos casos de cursos presenciais.",
                                                "label": {
                                                    "PT": "Município(Cursos presenciais)",
                                                    "EN": "City(Live only)"
                                                },
                                                "cols": 12,
                                                "placeholder": "Digite nome do Município",
                                                "labels": "nome",
                                                "axios": {
                                                    "method": "GET",
                                                    "url": "https://sgs.clictecnologia.com.br/api/listamunicipios"
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "component": "ClicRow",
                            "childrens": [
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "8"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicInput",
                                            "id": "SECNAME",
                                            "props": {
                                                "label": {
                                                    "PT": "Nome da Turma",
                                                    "EN": "Section Name"
                                                },
                                                "value": ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "4"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicMultiselect",
                                            "id": "SECDIASACESSO",
                                            "props": {
                                                "label": {
                                                    "PT": "Dias de Acessos",
                                                    "EN": "Shopstore"
                                                },
                                                "cols": 12,
                                                "placeholder": "Selecione",
                                                "labels": "nome",
                                                "axios": {
                                                    "method": "GET",
                                                    "url": "https://sgs.clictecnologia.com.br/api/listapropriedades?propriedade=dias-de-acesso"
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "component": "ClicRow",
                            "childrens": [
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "6"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicDatePicker",
                                            "id": "SECDATAINI",
                                            "props": {
                                                "label": {
                                                    "PT": "Data Inicio Curso",
                                                    "EN": "Class Start Date"
                                                },
                                                "value": ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "6"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicDatePicker",
                                            "id": "SECDATAFIM",
                                            "props": {
                                                "label": {
                                                    "PT": "Data Fim Curso",
                                                    "EN": "Class End Date"
                                                },
                                                "value": ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "component": "ClicRow",
                            "childrens": [
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "4"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicInput",
                                            "id": "SECVAGAS",
                                            "props": {
                                                "label": {
                                                    "PT": "Nº de vagas",
                                                    "EN": "Nº de vagas"
                                                },
                                                "value": ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "4"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicInput",
                                            "id": "SECMINVAGAS",
                                            "props": {
                                                "label": {
                                                    "PT": "Nº mínimo para abertura de turma",
                                                    "EN": "Nº mínimo para abertura de turma"
                                                },
                                                "value": ""
                                            }
                                        }
                                    ]
                                },
                                {
                                    "component": "ClicCol",
                                    "props": {
                                        "cols": "4"
                                    },
                                    "childrens": [
                                        {
                                            "component": "ClicInput",
                                            "id": "SECPRECO",
                                            "props": {
                                                "label": {
                                                    "PT": "Preço (R$)",
                                                    "EN": "Preço (R$)"
                                                },
                                                "value": ""
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "component": "ClicSwitch",
                            "id": "SECTIPOACESSO",
                            "props": {
                                "label": {
                                    "PT": "Os alunos podem apenas participar do curso entre estas datas",
                                    "EN": "Students can only attend the course between these dates"
                                },
                                "checked": true
                            }
                        },
                        {
                            "component": "ClicRow",
                            "props" : {
                                "alignContent": "end",
                                "alignH": "end"
                            },
                            "childrens": [
                                {
                                    "component": "ClicButton",
                                    "id": "BTNSALVARAPI",
                                    "props": {
                                        "containerStyle": "display: contents;",
                                        "label": "Criar Turma",
                                        "alignSelf": "center"
                                    },
                                    "events": {
                                        "clickPromise": "let comp = this.$getPropriedades(this); let dados = { course_id : comp.SECCURSO.props.value, name : comp.SECNAME.props.value, start_at : comp.SECDATAINI.props.value, end_at : comp.SECDATAFIM.props.value, restrict_enrollments : comp.SECTIPOACESSO.props.checked, numVagas : comp.SECVAGAS.props.value, numMinVagas : comp.SECMINVAGAS.props.value, valorCurso : comp.SECPRECO.props.value, diasAcesso: comp.SECDIASACESSO.props.value, municipio: comp.SECMUNICIPIO.props.value, loja: comp.SECLOJA.props.value  }; this.axios.post('https://sgs.clictecnologia.com.br/api/criarturma',dados).then((r) => { resolve(1); console.log(r.data); this.$notificacao({text: r.data.mensagem, type: r.data.sucesso ? 'success' : 'warning'}); });"
                                    }
                                },
                                {
                                    "component": "ClicButton",
                                    "id": "BTNLIMPARCAMPOS",
                                    "props": {
                                        "containerStyle": "display: contents;",
                                        "label": "Limpar campos",
                                        "variant": "transparent",
                                        "alignSelf": "center"
                                    },
                                    "events": {
                                        "click": "let comp = this.$getPropriedades(this); comp.SECCURSO.props.value = ''; comp.SECNAME.props.value = ''; comp.SECDATAINI.props.value = ''; comp.SECDATAFIM.props.value = ''; comp.SECTIPOACESSO.props.checked = true; comp.SECVAGAS.props.value = ''; comp.SECMINVAGAS.props.value = ''; comp.SECPRECO.props.value = ''; comp.SECDIASACESSO.props.value = undefined; comp.SECMUNICIPIO.props.value = undefined; comp.SECLOJA.props.value = '';"
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]


    };
  },
  created() {
  },


  methods: {
  },
};
</script>
