export default {
    documentation : {
        iconName : {
            hintTitle: "Informação iconName",
            hintText:`
                Bibliotecas de ícones disponíveis: 
                <p>* Bootstrap Icons</p>
                <p>* Material Design Icons</p>
                <br>
                <a href='https://oh-vue-icons.js.org/' target="_blank">Clique para ver os ícones disponíveis</a>
                <br>
                <br>
                <p style="font-size: 12px; font-weight:200">Atenção: O nome do ícone deve ser declarado no formato 
                    <b style="font-weight:600">"kebab-case"</b>.
                </p>
                
            `
        },
        // iconScale        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconAnimation    :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconSpeed        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconHover        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconFlip         :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconFill         :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconLabel        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconTitle        :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconInverse      :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
        // iconText         :{
        //     hintTitle: "Informação iconName",
        //     hintText:""
        // },
    }   
}   