import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        label: {
            hintTitle:"Label", 
            hintText:"Informe o título que será utilizado no botão."
        }, 

        // active:{
        //     hintTitle:"a", 
        //     hintText:"b"
        // }, 
        // activeClass             : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // append                  : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // block                   : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // disabled                : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // exact                   : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // exactActiveClass        : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // exactPath               : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // exactPathActiveClass    : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // href                    : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // noPrefetch              : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // pill                    : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // prefetch                : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // pressed                 : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // rel                     : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // replace                 : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // routerComponentName     : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // size                    : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // squared                 : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // tag                     : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // target                  : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // to                      : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        // type                    : {
        //     hintTitle:"a", 
        //     hintText:"b"
        // },
        variant                 : {
            hintTitle:"Variant", 
            hintText:`
                - <b style="color:#007bff!important" >Primary</b>
                    <br>
                - <b style="color:#6c757d!important" >Secondary</b>
                    <br>
                - <b style="color:#28a745!important" >Success</b>
                    <br>
                - <b style="color:#dc3545!important" >Danger</b>
                    <br>
                - <b style="color:#f5f5fa!important" >Light</b>
                    <br>
                - <b style="color:#343a40!important" >Dark</b>
            `
        },
      
    }
}









