<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
  >
    <b-embed 
      :type="type" 
      :aspect="aspect"
      :src="(windowWidth < '991' && srcMobile) ? srcMobile : src" 
      :style="style"
      :class="this.class"
      class="card"
    >
    </b-embed>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicIFrame',
  mixins: [ComponentMixin],
  data(){
    return{
        windowWidth: window.innerWidth,
    }
  },

};
</script>
<style scoped>
    div{
      height: calc(100vh - 128px);
    }

    @media (max-width: 991px) {
      div{
        width: calc(100vw - 35px) !important;
        padding:0;
      }
    }

</style>