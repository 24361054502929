<template>
  <b-container fluid class="p-0 vh-100">
    <b-row class="m-0 h-100">
      <b-col >
        <MotorS 
          :cp="this.$route" 
          :childrens="this.$route.meta.childrens"
          :idTela="this.$route.name"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {
    name: 'LayoutVazio',
    watch: {
      '$route' (to, from) {
        this.register()
      }
    },

    created(){
      if  (this.lodash.has(this.$route, 'meta.events.created')) {
        try{
          eval(this.lodash.get(this.$route, 'meta.events.created'))
        }catch(e){
          console.error(`[LayoutVazio] Falha ao executar evento 'created'. Detalhes: ${e}`);
        }
      }
    },
    
    mounted(){
      if  (this.lodash.has(this.$route, 'meta.events.mounted')) {
        try{
          eval(this.lodash.get(this.$route, 'meta.events.mounted'))
        }catch(e){
          console.error(`[LayoutVazio] Falha ao executar evento 'mounted'. Detalhes: ${e}`);
        }
      }
    },

    updated () {
      if  (this.lodash.has(this.$route, 'meta.events.updated')) {
        try {
          eval(this.lodash.get(this.$route, 'meta.events.updated'));
        } catch (e) {
          console.error(`[LayoutVazio] falha na execução do evento 'updated'. Detalhes: ${e}`);
        }
      }
    },
    
    methods: {
    
      register() {
        if(!this.$store.hasModule(this.$route.name)){
          this.$store.registerModule(`${this.$route.name}`, {
            namespaced: true,
            state(cp) {
              return {
                propriedades: {},
              }
            },
            getters: {
              getPropriedades(state) {
                return state.propriedades
              },
            },
            mutations: {
              setPropriedades(state, propriedades) {
                if(propriedades){
                  state.propriedades = {...state.propriedades, ...propriedades};
                }
              },
            },
          })
        }
      },
    }
  };
</script>