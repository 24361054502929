import ClicHintDocs from "./ClicHintDocs";
import ClicIconInterno from '../clicIcon/ClicIconInternoModel';

export default {
    ClicHint : {
        config      : {icon: "md-message", documentation: ClicHintDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            title   : { type: 'string',  default: "Informação", component: { name : 'input' } },
            text    : { type: 'string',  default: undefined,    component: { name : 'input' } }, 

            ...ClicIconInterno
        },
        events : {
        }
    }
}









