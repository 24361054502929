import ClicColInterno from '../../clicGridLayout/ClicColInternoModel'
import ClicIconInterno from '../../clicIcon/ClicIconInternoModel';
import ClicHintInterno from '../../clicHint/ClicHintInternoModel';

import ClicInputMaskDocs from './ClicInputMaskDocs';

export default {
    ClicInputMask : {
        config      : {icon: "md-abc", documentation: ClicInputMaskDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            fieldState              : { type: 'boolean',    default: undefined,     component: { name : 'switch' } },
            jsonId                  : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            label                   : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            required                : { type: 'boolean',    default: undefined,     component: { name : 'switch' } },
            invalidFeedbackMessage  : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            validateScript          : { type: 'string',     default: undefined,     component: { name : 'code'   } },
            readonly                : { type: 'boolean',    default: undefined,     component: { name : 'switch' } },
            autocomplete            : { type: 'string',     default: 'off',         component: { name : 'input'  } },
            placeholder             : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            name                    : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            
            disabled                : { type: 'boolean',    default: undefined,     component: { name : 'switch' } },
            value                   : { type: 'multiple',   default: undefined,     component: { name : 'input'  } },
            mask                    : { type: 'string',     default: '##/##/####',  component: { name : 'input'  } },
            masked                  : { type: 'boolean',    default: undefined,     component: { name : 'switch' } },
            tokens                  : { type: 'object',     default: undefined,     component: { name : 'input'  } },
            type                    : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            
            ...ClicColInterno,
            ...ClicIconInterno,
            ...ClicHintInterno,

        },
        events : {
            blur    : {type: 'string', component: { name : 'code' } },
            change  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
            keyup   : {type: 'string', component: { name : 'code' } },
        },
    }
}
