import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,


        // options          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // height           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // plus             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // axios            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // resolvePath      :{
        //     hintTitle: "",
        //     hintText:""
        // },

        // error            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // change           :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}
