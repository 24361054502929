import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        rowSelected                 :{
            hintTitle: "rowSelected",
            hintText:`Ao selecionar uma linha, emite o evento retornando o array <b> 'rows' </b>.
                <br>
                <br>
                O Array de linhas selecionadas pode ser resgatado também, como: 
                <br>
                <br>
                <small>componentes.idTabela.getSelectedRows()</small>
            `
        },    

        // controlsTopMenu                 :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // hidePagination                  :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // configAxiosContent              :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // configAxiosHeaders              :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // headers                         :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // sortBy                          :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // sortDesc                        :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // itemsPerPage                    :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // stacked                         :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // borderless                      :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // hover                           :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // striped                         :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // outlined                        :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // dark                            :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // footClone                       :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // small                           :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // headVariant                     :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // dataRow                         :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // pathItemsRows                   :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // pathItemsHeaders                :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // pathItemsRowsCounter            :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // refreshIntervalSeconds          :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // sortBy                          :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // keysFilter                      :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // hasPerPageSelect                :{
        //     hintTitle: "",
        //     hintText:""
        // },    
      
        // rowClass           :{
        //     hintTitle: "",
        //     hintText:""
        // },    
      
    }
}