import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicSwitchDocs from './ClicSwitchDocs'

export default {
    ClicSwitch : {
        config      : {icon:"md-switchright-outlined", documentation: ClicSwitchDocs.documentation }, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            jsonId              : { type: 'string',     default: undefined,     component: { name : 'input' } },

            label               : {type: 'object',      default: undefined,     component: { name : 'input' } },
            value               : {type: 'string',      default: undefined,     component: { name : 'input' } },
            disabled            : {type: 'boolean',     default: undefined,     component: { name : 'switch'} },
  
            ...ClicColInterno   
        },
        events : {
            change  : {type: 'string', component: { name : 'code' } },
            input   : {type: 'string', component: { name : 'code' } },
        },
    }
}