import ClicBadgeInternoDocs from "./ClicBadgeInternoDocs"

export default {
    badgeFW : {
        config          : {documentation: ClicBadgeInternoDocs.documentation},
    
        badgeText                : { type: 'string',      default: undefined,     component: { name : 'input' } },
        badgeVariant             : { type: 'string',      default: undefined,     component: { name : 'select', values: 
            ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'] }
        },
    }   
}