import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicCodeReaderDocs from './ClicCodeReaderDocs';

export default {
    ClicCodeReader : {
        config      : {isContainer: true, icon:"md-calendarmonth-twotone", documentation: ClicCodeReaderDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            ...ClicColInterno,

        },
        events : {

        }
    }
}









