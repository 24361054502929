import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // content :{
        //     hintTitle: "",
        //     hintText:""
        // },  
        
          
    }
}