import ClicDropdownItemDocs from "./ClicDropdownItemDocs"

export default {
    ClicDropdownItem : {
        config      : {isContainer: true, icon: "md-playlistplay-twotone", documentation: ClicDropdownItemDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            text            : {type: 'string',      default: undefined,     component: { name : 'input' } },
                     
            active              : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            activeClass         : {type: 'string',      default: undefined,     component: { name : 'input' } },
            append              : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            disabled            : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            exact               : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            exactActiveClass    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            exactPath           : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            exactPathActiveClass: {type: 'string',      default: undefined,     component: { name : 'input' } },
            href                : {type: 'string',      default: undefined,     component: { name : 'input' } },
            linkClass           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            noPrefetch          : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            prefetch            : {type: 'string',      default: undefined,     component: { name : 'switch' } },
            rel                 : {type: 'string',      default: undefined,     component: { name : 'input' } },
            replace             : {type: 'boolean',     default: false,         component: { name : 'switch' } },
            routerComponentName : {type: 'string',      default: undefined,     component: { name : 'input' } },
            target              : {type: 'string',      default: undefined,     component: { name : 'input' } },
            to                  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            variant             : {type: 'string',      default: undefined,     component: { name : 'select', values: 
                                    ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'] } 
                                },
        },
        events : {
            click           : {type: 'string', component: { name : 'code' } },
            clickPromise    : {type: 'string', component: { name : 'code' } },
        }
    }
}