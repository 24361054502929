import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicCodeEditorDocs from './ClicCodeEditorDocs'

export default {
    ClicCodeEditor : {
        config      : {icon: "md-code-twotone", documentation: ClicCodeEditorDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            jsonId      : { type: 'string',     default: undefined,     component: { name : 'input' } },

            width       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            height      : {type: 'string',      default: undefined,     component: { name : 'input' } },
            value       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            original    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            language    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            theme       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            options     : {type: 'array',       default: {
                                                    theme           : "vs-dark",
                                                    formatOnPaste   : true,
                                                    formatOnType    : true,
                                                    tabCompletion   : true,
                                                    automaticLayout : true
                                                },                      component: { name : 'code' } },
            
            ...ClicColInterno   
        },
        events : {
            change              : {type: 'string', component: { name : 'code' } },
            editorBeforeMount   : {type: 'string', component: { name : 'code' } },
            editorMounted       : {type: 'string', component: { name : 'code' } },
            blur                : {type: 'string', component: { name : 'code' } },
        },
    }
}