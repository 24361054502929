<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
  >
    <v-jsoneditor 
      v-model="value"
      :options="{...optionsDefault, ...options}" 
      :height="height"
      :plus="plus" 
      @error="error"
      @input="change"
    >
    </v-jsoneditor>
      
  </ClicColInterno>
</template>

<script>

import VJsoneditor from 'v-jsoneditor'
import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicJson',
  components: {
    VJsoneditor,    
  },
  mixins: [ComponentMixin],

  data() {
    return {
      value : "",
      optionsDefault : {
        mode            : 'view',
        modes           : undefined,
        enableSort      : true,
        enableTransform : true,
        navigationBar   : false,
        statusBar       : true,
        history         : true,
        search          : true
      }
    };
  },

  mounted() {
    if(this.axios){
      this.getJsonDataAxios(this.axios, this.resolvePath);
    }
  },

  methods: {
    
    getJsonDataAxios(axios, path){
      try {
        this.$axios(axios).then(response => {
          this.jsonData = lodash.get(response, path ? ('data.' + path) : 'data');
        });
      } catch(e) {
        console.error(`['ClicJson'] Falha ao carregar dados por requisição. \n Detalhes: ${e}`)
      }
    },

    change(json){
      if (this.lodash.has(this.cp, 'events.change')) {
        try {
          eval(this.cp.events.change);
        } catch (e) {
          console.error(`[ClicJson] Falha ao executar evento 'change'. Detalhes: ${e}`);
        }
      }  
    },

    error(e){
      //console.error(`['ClicJson'] Ocorreu um erro. \n Detalhes: ${e}`);
      if (this.lodash.has(this.cp, 'events.error')) {
        try {
          eval(this.cp.events.error);
        } catch (e) {
          console.error(`[ClicJson] Falha ao executar evento 'error'. Detalhes: ${e}`);
        }
      }  
    }
  },

};
</script>

<style lang="scss">
div.jsoneditor-outer.has-main-menu-bar {
  background-color: rgb(146, 145, 145);
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected{
  background-color: var(--cor-principal-claro) !important;
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected :focus{
  background-color: rgb(146, 145, 145);
}

.jsoneditor-container.min-box {
  min-width: auto !important;
}

div.jsoneditor-menu a.jsoneditor-poweredBy {
  display: none !important;
}

.jsoneditor-frame{
  border-radius:10px
}

.jsoneditor-treepath{
  color: #fff;
}

div.jsoneditor{
  border:.0 solid rgb(146, 145, 145) !important;
}

td{
  white-space: normal !important;
}

</style>