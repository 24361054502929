import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        // width       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // height      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // original    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // language    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // theme       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // options     :{
        //     hintTitle: "",
        //     hintText:""
        // },       
        // change           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // editorBeforeMount:{
        //     hintTitle: "",
        //     hintText:""
        // },
        // editorMounted     :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}