import ClicColDocs from "./ClicColDocs"

export default {
    ClicCol : {
        config      : {isContainer: true, icon:"md-viewcolumn", documentation: ClicColDocs.documentation},  
        component   : {type: 'string', component: { name : '' },        disabled:true},
        id          : {type: 'string', component: { name : 'input' }},
        props : {
            
            title               : {type: 'string',  default: undefined, component: { name: 'input' } },
            subtitle            : {type: 'string',  default: undefined, component: { name: 'input' } },

            col                 : {type: 'boolean', default: undefined, component: { name: 'input' } },
            cols                : {type: 'string',  default: undefined, component: { name: 'input' } },
            offset              : {type: 'string',  default: undefined, component: { name: 'input' } },
            offsetLG            : {type: 'string',  default: undefined, component: { name: 'input' } },
            offsetMD            : {type: 'string',  default: undefined, component: { name: 'input' } },
            offsetSM            : {type: 'string',  default: undefined, component: { name: 'input' } },
            offsetXL            : {type: 'string',  default: undefined, component: { name: 'input' } },
            order               : {type: 'string',  default: undefined, component: { name: 'input' } },
            orderLG             : {type: 'string',  default: undefined, component: { name: 'input' } },
            orderMD             : {type: 'string',  default: undefined, component: { name: 'input' } },
            orderSM             : {type: 'string',  default: undefined, component: { name: 'input' } },
            orderXL             : {type: 'string',  default: undefined, component: { name: 'input' } },
            routerComponentName : {type: 'string',  default: undefined, component: { name: 'input' } },
            sm                  : {type: 'string',  default: undefined, component: { name: 'input' } },
            lg                  : {type: 'string',  default: undefined, component: { name: 'input' } },
            md                  : {type: 'string',  default: undefined, component: { name: 'input' } },
            xl                  : {type: 'string',  default: undefined, component: { name: 'input' } },
            tag                 : {type: 'string',  default: undefined, component: { name: 'input' } },
            alignSelf           : {type: 'string',  default: undefined, component: { name: 'select', values: ['start', 'center', 'end', 'baseline', 'stretch']} },

        },
        events:{
        }
    }
}