import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
       
        ...GeneralProperties.documentation,

        // label            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hint             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        
        // block            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // boundary         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // disabled         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dropleft         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dropright        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dropup           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // html             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // lazy             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // menuClass        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noCaret          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noFlip           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // offset           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // popperOpts       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // right            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // role             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // size             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // split            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // splitButtonType  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // splitClass       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // splitHref        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // splitTo          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // splitVariant     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // text             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // toggleAttrs      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // toggleClass      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // toggleTag        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // toggleText       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        variant                 : {
            hintTitle:"Variant", 
            hintText:`
                - <b style="color:#007bff!important" >Primary</b>
                    <br>
                - <b style="color:#6c757d!important" >Secondary</b>
                    <br>
                - <b style="color:#28a745!important" >Success</b>
                    <br>
                - <b style="color:#dc3545!important" >Danger</b>
                    <br>
                - <b style="color:#f5f5fa!important" >Light</b>
                    <br>
                - <b style="color:#343a40!important" >Dark</b>
            `
        },
    }
}