import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicJsonDocs from './ClicJsonDocs'

export default {
    ClicJson : {
        config      : {icon: "md-accounttree-round", documentation: ClicJsonDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            options         : {type: 'object',      default: {},        component: { name : 'input' } },
            height          : {type: 'string',      default: '350px',   component: { name : 'input' } },
            plus            : {type: 'boolean',     default: false,     component: { name : 'switch'} },
            value           : {type: 'array',       default: [],        component: { name : 'code' } },
            axios           : {type: 'object',      default: undefined, component: { name : 'code' } },
            resolvePath     : {type: 'string',      default: undefined, component: { name : 'input' } },

            ...ClicColInterno,

        },
        events : {
            error           : {type: 'string', component: { name : 'code' } },
            change          : {type: 'string', component: { name : 'code' } },
        },
    }
}
