import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // alignH  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // alignV  :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}