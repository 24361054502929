import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // alt          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // blank        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // blankColor   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // block        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // center       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // fluid        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // fluidGrow    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // height       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // left         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // right        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // rounded      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // sizes        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // src          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // srcset       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // thumbnail    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // width        :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}