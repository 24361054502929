import LayoutMenuTopoConteudoDocs from "./LayoutMenuTopoConteudoDocs"

export default {
    LayoutMenuTopoConteudo : {
        config      : {isContainer:true, icon:"md-pivottablechart-twotone", isRoute: true, documentation: LayoutMenuTopoConteudoDocs.documentation }, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            path           : {type: 'string',       default: undefined,     component: { name : 'input' }},
            title          : {type: 'string',       default: undefined,     component: { name : 'input' }},
            subtitle       : {type: 'string',       default: undefined,     component: { name : 'input' }},
            authenticated  : {type: 'boolean',      default: undefined,     component: { name : 'switch' } },
            isLogin        : {type: 'boolean',      default: undefined,     component: { name : 'switch' } },
            isHome         : {type: 'boolean',      default: undefined,     component: { name : 'switch' } },
        }
    }
}
