<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h2>Visão Camera</h2>
        <code v-if="device">{{ device.label }}</code>
        <div class="border">
          <vue-web-cam id="webcam-codereader" ref="webcam" :device-id="deviceId" width="100%" @started="onStarted"
            @error="onError" @cameras="onCameras" @notsupported="onNotsupported" @camera-change="onCameraChange" />
        </div>

        <div class="row">
          <div class="col-md-12">
            <select v-model="camera">
              <option>-- Selecione a Câmera --</option>
              <option v-for="device in devices" :key="device.deviceId" :value="device.deviceId">{{ device.label }}
              </option>
            </select>
          </div>
          <div class="col-md-12">
            <button type="button" class="btn btn-primary" @click="onCapture">Capturar</button>
            <button type="button" class="btn btn-danger" @click="onStop">Parar Camera</button>
            <button type="button" class="btn btn-success" @click="onStart">Iniciar Camera</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h2>Captura</h2>
        <figure class="figure">
          <img :src="img" class="img-responsive" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { BrowserQRCodeReader } from '@zxing/browser';
import Quagga from 'quagga';
import ComponentMixin from '../../core/ComponentMixin';
import { WebCam } from "vue-web-cam";

export default {
  name: "ClicWebCam",
  mixins: [ComponentMixin],
  components: {
    "vue-web-cam": WebCam
  },

  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      qrCodeControls: null,
      devices: [],
      qrCodeReader: new BrowserQRCodeReader()
    };
  },
  computed: {
    device: async function () {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },

  methods: {

    async initializeQrCode() {
      await this.$nextTick();
      const selectedDeviceId = this.deviceId;
      const previewElem = document.querySelector('#webcam-codereader > video');

      this.qrCodeControls = await this.qrCodeReader.decodeFromVideoDevice(selectedDeviceId, previewElem, (result, error, controls) => {
        if (result) {
          alert(result, "QR CODE DETECTADO")
        }
      });
    },

    async initializeBarCode() {
      await this.$nextTick();
      const previewElem = document.querySelector('#webcam-codereader');

      Quagga.init({
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: previewElem
        },
        decoder: {
          readers: ["code_128_reader"]
        }
      }, function (err) {
        if (err) {
          console.log(err);
          return
        }
        Quagga.start();
        Quagga.onDetected((codeBar) => {
          alert(codeBar, "Código de barras detectado")
          console.log(codeBar)
        })
      });
    },


    onCapture() {
      this.img = this.$refs.webcam.capture();
    },
    onStarted(stream) {
      this.initializeQrCode()
      this.initializeBarCode()
    },
    onStop() {
      this.$refs.webcam.stop();
      this.qrCodeControls.stop()
      Quagga.stop()
    },
    onStart() {
      this.$refs.webcam.start();
      this.qrCodeControls.start()
      Quagga.start()
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onNotsupported() {
      console.log("Não suportado")
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    }
  }
}
</script>
