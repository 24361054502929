import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // active              :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // buttonId            :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // disabled            :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // lazy                :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // noBody              :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // tag                 :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // title               :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // titleItemClass      :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // titleLinkAttributes :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // titleLinkClass      :{
        //     hintTitle: "",
        //     hintText:""
        // },    

        // click               :{
        //     hintTitle: "",
        //     hintText:""
        // },    
     
    }
}