import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // title    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // text     :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}









