import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        card: {
            hintTitle:"card", 
            hintText:"Envolve o breadcrumb em uma card"
        }, 
        representRoute: {
            hintTitle:"representRoute", 
            hintText:"Chama um método interno que modifica os items do breadcrumb para que ele represente a rota atual, disponibilizando links que levam individualmente a cada elemento"
        }, 
        items: {
            hintTitle:"items", 
            hintText:"Array dos itens exibidos no breadcrumb"
        }, 
    
            variant                 : {
            hintTitle:"Variant", 
            hintText:`
                - <b style="color:#007bff!important" >Primary</b>
                    <br>
                - <b style="color:#6c757d!important" >Secondary</b>
                    <br>
                - <b style="color:#28a745!important" >Success</b>
                    <br>
                - <b style="color:#dc3545!important" >Danger</b>
                    <br>
                - <b style="color:#f5f5fa!important" >Light</b>
                    <br>
                - <b style="color:#343a40!important" >Dark</b>
            `
        },
      
    }
}









