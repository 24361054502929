<template>
    <div style="display:contents">
        {{state.row.value}}
    </div>
</template>


<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicTableColumnText',
    mixins: [ComponentMixin],

}
</script>