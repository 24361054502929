<template>
  <label class="form-label" style="margin: 0px !important;">

    <v-icon 
      :id="id"
      name="bi-info-circle"
      style="color:var(--blue); padding: 0; margin: 0; min-width: 25px;"
      scale="1.3"
      v-b-modal="id"
      class="btn"
    />

    <b-modal 
      :id="id" 
      size="sm"
      :title="$storage.getTextoTraduzido(getProp('hintTitle'))"
      hide-footer
    >
      <template #default="{ }">
        <p 
          v-html="$storage.getTextoTraduzido(getProp('hintText'))">
        </p>
      </template>
    </b-modal>


    <b-popover 
      v-if="windowWidth > 1024"
      :target="this.id" 
      triggers="hover" 
      placement="auto"
    >
      <template #title> 
        {{ $storage.getTextoTraduzido(getProp('hintTitle')) }}
      </template>
      <p
        v-html="$storage.getTextoTraduzido(getProp('hintText'))">
      </p>
    </b-popover>


  </label>
</template>

<script>

import ComponentInternalMixin from '../../core/ComponentInternalMixin';

export default {
  name: 'ClicHintInterno',
  mixins: [ComponentInternalMixin],

  data(){
    return{
      windowWidth: window.innerWidth,
      id : this.$utils.getComponentID()
    }
  },

};
</script>

