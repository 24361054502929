<template>
  <b-row
    :align-h="alignH"
    :align-v="alignV"
    :style="style"
    :class="this.class"
  >
    <MotorS
      :cp="this.cp"
      :childrens="this.childrens"
      :idTela="idTela"
    />
  </b-row>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicModalFooter',
  mixins: [ComponentMixin],

  data() {
    return {
        slotSelector: '#modal-footer'
    }
  },

}
</script>
