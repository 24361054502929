<template>
  <div class="home">
    <h1>404</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NaoDefinido',
  props: {
    id:{
      type: String,
      default: "",
    },
    label:{
      type: String,
      default: "",
    },
    cp:{
      default: "",
    }
  }
};
</script>
