<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>Visão Camera</h2>
                <code v-if="device">{{ device.label }}</code>
                <div class="border">
                    <vue-web-cam
                        ref="webcam"
                        :device-id="deviceId"
                        width="100%"
                        @started="onStarted"
                        @stopped="onStopped"
                        @error="onError"
                        @cameras="onCameras"
                        @camera-change="onCameraChange"
                    />
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <select v-model="camera">
                            <option>-- Selecione a Câmera --</option>
                            <option
                                v-for="device in devices"
                                :key="device.deviceId"
                                :value="device.deviceId"
                            >{{ device.label }}</option>
                        </select>
                    </div>
                    <div class="col-md-12">
                        <button type="button" class="btn btn-primary" @click="onCapture">Capturar</button>
                        <button type="button" class="btn btn-danger" @click="onStop">Parar Camera</button>
                        <button type="button" class="btn btn-success" @click="onStart">Iniciar Camera</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h2>Captura</h2>
                <figure class="figure">
                    <img :src="img" class="img-responsive" />
                </figure>
            </div>
        </div>
    </div>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import { WebCam } from "vue-web-cam";

export default {
    name: "ClicWebCam",
    mixins: [ComponentMixin],
    components: {
        "vue-web-cam": WebCam
    },
     data() {
        return {
            img: null,
            camera: null,
            deviceId: null,
            devices: []
        };
    },
    computed: {
        device: async function() {
           // alert(this.devices, "DEVICES" )          
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    watch: {
        camera: function(id) {
            console.log(id)
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },

    methods: {
        onCapture() {
            this.img = this.$refs.webcam.capture();
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        }
    }
}
</script>
