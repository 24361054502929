import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        // imageAlt          : {
        //     hintTitle:"",
        //     hintText:""
        // },
        
        // imageBlank         : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageBlankColor    : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageBlock         : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageCenter        : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageFluid         : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageFluidGrow     : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageHeight        : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageLeft          : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageRight         : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageRounded       : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageSizes         : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageSrc           : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageSrcset        : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageThumbnail     : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageWidth         : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageStyle         : {
        //     hintTitle:"",
        //     hintText:""
        // },
        // imageClass         : {
        //     hintTitle:"",
        //     hintText:""
        // },
    }   
}