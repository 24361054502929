import GeneralProperties from '../../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // fieldState              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // jsonId                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // label                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // required                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // invalidFeedbackMessage  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // validateScript          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // readonly                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // autocomplete            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // placeholder             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // name                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        
        // disabled                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // mask                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // masked                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // tokens                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // type                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        
        // blur   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // change :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // input  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // keyup  :{
        //     hintTitle: "",
        //     hintText:""
        // },

    }
}
