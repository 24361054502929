import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicHintInterno from '../clicHint/ClicHintInternoModel';
import ClicMarkdownDocs from './ClicMarkdownDocs';

export default {
    ClicMarkdown : {
        config      : {icon: "bi-markdown", documentation: ClicMarkdownDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled: true },
        id          : {type: 'string', component: { name : '' } },
        props : {
            jsonId                  : { type: 'string',     default: undefined, component: { name : 'input' } },
            label                   : { type: 'string',     default: undefined, component: { name : 'input' } },

            text                    : { type: 'string',     default: undefined, component: { name : 'input' } },
            mode                    : { type: 'string',     default: undefined, component: { name : 'select', values: ['edit', 'editable', 'preview'] } },
            height                  : { type: 'string',     default: '100%',    component: { name : 'input' } },
            tabSize                 : { type: 'number',     default: undefined, component: { name : 'input' } },
            tocNavPositionRight     : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            defaultShowToc          : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            placeholder             : { type: 'string',     default: undefined, component: { name : 'input' } },
            autofocus               : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            defaultFullscreen       : { type: 'boolean',    default: undefined, component: { name : 'switch' } },
            includeLevel            : { type: 'array',      default: undefined, component: { name : 'input' } },
            leftToolbar             : { type: 'string',     default: undefined, component: { name : 'input' } },
            rightToolbar            : { type: 'string',     default: 'preview', component: { name : 'input' } },
            toolbar                 : { type: 'object',     default: undefined, component: { name : 'code' } },
            toolbarConfig           : { type: 'object',     default: undefined, component: { name : 'code' } },
            disabledMenus           : { type: 'object',     default: undefined, component: { name : 'code' } },
            uploadImageConfig       : { type: 'object',     default: undefined, component: { name : 'code' } },
            beforePreviewChange     : { type: 'function',   default: undefined, component: { name : 'code' } },
            codemirrorConfig        : { type: 'object',     default: undefined, component: { name : 'code' } },
            codemirrorStyleReset    : { type: 'boolean',    default: undefined, component: { name : 'switch' } },

            ...ClicColInterno,
            ...ClicHintInterno,

        },
        events : {
            change              : {type: 'string', component: { name : 'code' } },
            blur                : {type: 'string', component: { name : 'code' } },
            save                : {type: 'string', component: { name : 'code' } },
            imageClick          : {type: 'string', component: { name : 'code' } },
            fullscreenChange    : {type: 'string', component: { name : 'code' } },
            uploadImage         : {type: 'string', component: { name : 'code' } },
        },
    }
}
