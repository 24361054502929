<template>
  <ClicColInterno
    :cp="cp"
  >
    <component
      v-if="seriesType"
      v-bind:is="seriesType"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

import { Bar as BarSeries } from 'vue-chartjs/legacy';
import { Line as LineSeries } from 'vue-chartjs/legacy';
import { Bubble as BubbleSeries } from 'vue-chartjs/legacy';
import { Doughnut as DoughnutSeries } from 'vue-chartjs/legacy';
import { Pie as PieSeries } from 'vue-chartjs/legacy';
import { PolarArea as PolarAreaSeries } from 'vue-chartjs/legacy';
import { Radar as RadarSeries } from 'vue-chartjs/legacy';
import { Scatter as ScatterSeries } from 'vue-chartjs/legacy';

import {  
  Chart as ChartJS, 
  Title, 
  Tooltip, 
  Legend, 
  BarElement,
  LineElement,
  CategoryScale, 
  LinearScale,
  PointElement,
  ArcElement,
  RadialLinearScale, 
} from 'chart.js';

ChartJS.register( 
  Title, 
  Tooltip, 
  Legend, 
  BarElement, 
  LineElement,
  CategoryScale, 
  LinearScale,
  PointElement,
  ArcElement,
  RadialLinearScale
);

export default {
  name: 'ClicChart',
  mixins: [ComponentMixin],  
  components: { 
    BarSeries, 
    LineSeries, 
    BubbleSeries, 
    DoughnutSeries, 
    PieSeries, 
    PolarAreaSeries, 
    RadarSeries, 
    ScatterSeries
  },
  data() {
    return {

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      
    }
  }
}
</script>