import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,
        
        // text        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        name : {
            hintTitle: "Name",
            hintText:`
                Bibliotecas de ícones disponíveis: 
                <p>* Bootstrap Icons</p>
                <p>* Material Design Icons</p>
                <br>
                <a href='https://oh-vue-icons.js.org/' target="_blank">Clique para ver os ícones disponíveis</a>
            `
        },
        // scale       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // animation   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // speed       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hover       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // flip        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // fill        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // label       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // title       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // inverse     :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}









