import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // modalBody                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // modalFooter                  :{
        //     hintTitle: "",
        //     hintText:""
        // },

        // ariaLabel                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // autoFocusButton              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyBgVariant                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyClass                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // bodyTextVariant              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // busy                         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // buttonSize                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // cancelDisabled               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // cancelTitle                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // cancelTitleHtml              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // cancelVariant                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // centered                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // contentClass                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // dialogClass                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerBgVariant              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerBorderVariant          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerClass                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerTag                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // footerTextVariant            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerBgVariant              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerBorderVariant          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerClass                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerCloseContent           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerCloseLabel             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerCloseVariant           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerTag                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // headerTextVariant            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hideBackdrop                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hideFooter                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hideHeader                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hideHeaderClose              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // ignoreEnforceFocusSelector   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // lazy                         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // modalClass                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noCloseOnBackdrop            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noCloseOnEsc                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noEnforceFocus               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noFade                       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noStacking                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // okDisabled                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // okOnly                       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // okTitle                      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // okTitleHtml                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // okVariant                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // returnFocus                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // scrollable                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // size                         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // static                       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // title                        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // titleClass                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // titleHtml                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // titleSrOnly                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // titleTag                     :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // visible                      :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}