<template>

  <ClicColInterno
    :cp="cp"
    :key="componentKey"
  >
    <div class="form-group left-label">    
      <label v-if="label" class="form-label">
        {{ $storage.getTextoTraduzido(label) }}
      </label>
      <div class="form-control-wrap">
        <component 
          :style="style"
          :class="this.class"
          :is="tag" 
          v-html="text || value || ''"
        >
        </component>
      </div>
    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicText',
  mixins: [ComponentMixin],

  data() {
    return {
      value : eval(this.value)
    };
  },

};
</script>
