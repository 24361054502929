<template>
  <ClicColInterno
    :cp="cp"
    :key="componentKey"
    :id="componentKey"
  >
    <div class="form-group left-label">    
      <div class="d-flex flex-row align-items-start">
          <label class="form-label form-label-clic" v-if="labelHtml">
            <b v-if="required && (value == undefined || value === '')" class="text-danger">*</b>
            {{ $storage.getTextoTraduzido(label) }}
          </label>
      </div>

      <div class="form-control-wrap">
        
        <b-progress-bar
          :style="style"
          :label="label"
          :label-html="labelHtml"
          :value="value"
          :animated="animated"
          :height="height"
          :max="max"
          :precision="precision"
          :show-progress="showProgress"
          :show-value="showValue"
          :striped="striped"
          :variant="variant"
          :class="`form-control ${this.class}`"
        >
        
        </b-progress-bar>
      </div>
    </div>
  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicProgressBar',
  mixins: [ComponentMixin],

  data() {
    return {
      value       : undefined,
      oldValue    : undefined,
      fieldState  : undefined,          
      public:{
        isValid         : this.validateField,
        clearField      : this.clearField,
        undo            : this.undo,
        apply           : this.apply,
        isValueChanged  : this.isValueChanged,
      }
    }
  },

  // mounted() {
  //   this.oldValue = this.value;
  // },
  
  methods: {
  },

};

</script>

