
const componentInternalMixin = {
    data() {
      return {
      }
    },

    props: {
        cp : Object,
        parent : Object,
    },

    created() {
    },

    mounted() {      
    },

    updated() {
    },

    methods: {
      getProp(nome, def) {
        return this.lodash.get(
          this.$parent, nome,
          this.lodash.get(this.cp, `props.${nome}`,
          this.lodash.get(this.$attrs, nome,        
          def))
        );
      },
    }
};
 
   export default componentInternalMixin;