import ClicColInterno from '../clicGridLayout/ClicColInternoModel';
import ClicTableDocs from './ClicTableDocs';

export default {
    ClicTable : {
        config      : {icon:"md-switchright-outlined", isContainer: true, documentation: ClicTableDocs.documentation }, 
        component   : {type: 'string', component: { name : '' }, disabled:true },
        id          : {type: 'string', component: { name : '' }},
        props : {

            controlsTopMenu                 : {type: 'boolean',     default: true,      component: { name : 'switch' } },
            hidePagination                  : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            configAxiosContent              : {type: 'string',      default: undefined, component: { name : 'code' } },
            configAxiosHeaders              : {type: 'string',      default: undefined, component: { name : 'code' } },
            headers                         : {type: 'array',       default: undefined, component: { name : 'code' } },
            sortBy                          : {type: 'string',      default: undefined, component: { name : 'input' } },
            sortDesc                        : {type: 'boolean',     default: true,      component: { name : 'switch' } },
            itemsPerPage                    : {type: 'number',      default: 10,        component: { name : 'input' } },
            stacked                         : {type: 'string',      default: 'lg',      component: { name : 'input' } },
            borderless                      : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            hover                           : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            striped                         : {type: 'boolean',     default: true,      component: { name : 'switch' } },
            outlined                        : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            dark                            : {type: 'boolean',     default: false,     component: { name : 'switch' } },
            footClone                       : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            small                           : {type: 'boolean',     default: true,      component: { name : 'switch' } },
            headVariant                     : {type: 'string',      default: undefined, component: { name : 'input' } },
            dataRow                         : {type: 'array',       default: undefined, component: { name : 'code' } },
            pathItemsRows                   : {type: 'string',      default: undefined, component: { name : 'input' } },
            pathItemsHeaders                : {type: 'string',      default: undefined, component: { name : 'input' } },
            pathItemsRowsCounter            : {type: 'string',      default: undefined, component: { name : 'input' } },
            refreshIntervalSeconds          : {type: 'number',      default: undefined, component: { name : 'input' } },
            sortBy                          : {type: 'string',      default: undefined, component: { name : 'input' } },
            keysFilter                      : {type: 'array',       default: [],        component: { name : 'input' } },
            hasPerPageSelect                : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            selectable                      : {type: 'boolean',     default: undefined, component: { name : 'switch' } },
            selectMode                      : {type: 'string',      default: undefined, component: { name : 'select', values: ["multi", "single", "range"]}},
            noSelectOnClick                 : {type: 'boolean',     default: true,      component: { name : 'switch' } },

            ...ClicColInterno   
        },
        events : {
            rowClass           : {type: 'string', component: { name : 'code' } },
            rowSelected        : {type: 'string', component: { name : 'code' } },
        }
    }
}