<template>
    <ClicColInterno
        :cp="cp"
        :key="componentKey"
    >
        <v-icon 
            :name="name"
            :scale="scale"
            :animation="animation"
            :speed="speed"
            :hover="hover"
            :flip="flip"
            :fill="fill"
            :label="label"
            :title="title"
            :inverse="inverse"
            v-if="!text"
            :style="style"
            :class="this.class"
        />

        <b-avatar 
            variant="primary" 
            :text="text"
            v-if="text"
            :style="style"
            :class="this.class"
        >
        </b-avatar>

    </ClicColInterno>
</template>

<script>

    import ComponentMixin from '../../core/ComponentMixin';

    export default {
        name: 'ClicIcon',
        mixins: [ComponentMixin],
    }
    
</script>
