<template>
    <b-navbar-nav 
        class="ml-auto"
        :align="this.lodash.get(this.cp, 'props.align', 'start')"
        :fill="this.lodash.get(this.cp, 'props.fill', false)"
        :justified="this.lodash.get(this.cp, 'props.justified', false)"
        :small="this.lodash.get(this.cp, 'props.small', false)"
        :tag="this.lodash.get(this.cp, 'props.tag', 'ul' )"
    >

      <MotorS
        :cp="this.cp"
        :childrens="this.cp.childrens"
        @register="register"
        idTela="mainFramework"
      />

    </b-navbar-nav>
</template>
<script>

export default {
    props: {
        cp:{
            type : Object,
            default : function () { return {props : function () { return {} }}},
        },
        idTela:{
            type: String,
            default: "",
        },

    },
    data() {
        return {};
    },
  
    created() {
        this.$store.commit(`${this.idTela}/setPropriedades`, {[`${this.cp.id}`]: this.cp});
        
        // if(this.$navbar){
        //     this.navbar = JSON.parse(this.$navbar);
        // }

    },

    methods:{
      register() {
        if (!this.$store.hasModule(this.$route.name)) {
          this.$store.registerModule(`${this.$route.name}`, {
            namespaced: true,
            state(cp) {
              return {
                propriedades: {}
              };
            },
            getters: {
              getPropriedades(state) {
                return state.propriedades;
              }
            },
            mutations: {
              setPropriedades(state, propriedades) {
                if (propriedades) {
                  state.propriedades = { ...state.propriedades, ...propriedades };
                }
              }
            }
          });
        }
      }
    }

};
</script>

<style>

</style>