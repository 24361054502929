const insights = [
    "O cliente Google não teve interações registradas nos últimos Z dias. Recomenda-se entrar em contato para manter o relacionamento ativo e descobrir oportunidades de colaboração.",
    "Com base no histórico de compras, o cliente Microsoft tem preferência pela categoria A. Considere sugerir um produto complementar que aprimore ainda mais sua experiência.",
    "Após demonstrar interesse no produto B durante a última ligação, é uma boa ideia fazer um follow-up para entender suas necessidades e fornecer informações detalhadas.",
    "Dado o histórico de grandes compras no trimestre passado, convide o cliente Amazon para uma conversa. Discutam os resultados e explorar possíveis próximos passos para atender às suas necessidades.",
    "O cliente Facebook abriu recentemente um ticket de suporte. Certifique-se de que o problema foi resolvido e ofereça suporte contínuo para manter um bom relacionamento.",
    "O cliente Netflix demonstra interesse por produtos de alta qualidade. Apresente opções premium que atendam aos seus padrões.",
    "O cliente Tesla não interage há meses. Entre em contato para investigar possíveis problemas e reativá-lo com soluções adequadas.",
    "O cliente SpaceX tem abandonado o carrinho repetidamente. Considere oferecer um desconto mais atrativo para incentivar a conclusão da compra.",
    "O cliente Uber está interessado em treinamento personalizado. Planeje uma sessão de treinamento que se adapte às suas necessidades e objetivos.",
    "O cliente Airbnb comprou uma única vez. Crie uma campanha de retenção personalizada para incentivá-lo a retornar e explorar mais opções.",
    "O cliente Twitter está buscando informações sobre o produto X. Fornecer materiais extras para ajudar na tomada de decisão e aumentar a confiança.",
    "Com base no histórico diversificado de compras do cliente Adobe, identifique padrões para oferecer recomendações relevantes que atendam às suas preferências.",
    "O cliente IBM mencionou um projeto futuro. Agende uma reunião para explorar como nossos produtos podem contribuir e beneficiar seus planos.",
    "O cliente Oracle costuma participar de eventos do setor. Planejar uma reunião para compartilhar insights relevantes e fortalecer sua conexão.",
    "Ao saber que o cliente Coca-Cola participa de grupos profissionais online, prepare-se para discutir tópicos pertinentes e construir um relacionamento mais profundo.",
    "Respondendo à busca de informações sobre nossa política de devoluções pelo cliente Pepsi, explique detalhadamente os procedimentos para garantir sua satisfação contínua.",
    "Dado o interesse do cliente Samsung em um teste gratuito, forneça informações claras sobre como iniciar o período de avaliação e aproveitar ao máximo.",
    "O cliente LG está pesquisando sobre o produto Y. Prepare uma lista de benefícios para compartilhar e enfatize como atenderá às suas necessidades específicas.",
    "O cliente Intel demonstrou interesse em atualizações do produto. Mantenha-o informado sobre as últimas melhorias para mantê-lo engajado.",
    "Após abrir um ticket de suporte, verifique se o problema técnico do cliente AMD foi resolvido e ofereça assistência contínua para garantir sua satisfação.",
    "O cliente HP procura soluções para um desafio específico. Proponha uma conversa para discutir possíveis abordagens e oferecer uma solução adequada.",
    "Com base no interesse do cliente Dell em uma oferta especial, destaque os benefícios dessa promoção e como ela atende às suas necessidades.",
    "Dado o padrão de demora nas decisões de compra do cliente Cisco, ofereça um período de teste ou amostra para ajudar a agilizar o processo.",
    "Com o histórico sazonal de compras do cliente Intel, esteja preparado para oferecer uma oferta especial na próxima temporada de compras.",
    "Após mencionar preocupações com integração, explique como nosso suporte pode simplificar o processo para o cliente SAP.",
    "O cliente Salesforce está explorando produtos de renovação de contrato. Prepare informações detalhadas sobre as opções disponíveis para discussão.",
    "Destaque as vantagens dos recursos avançados para o cliente Oracle, demonstrando como eles podem beneficiar seus negócios.",
    "Com base na pesquisa do cliente Adobe sobre o produto Z, compartilhe uma lista de benefícios e vantagens para orientar sua decisão.",
    "O cliente IBM tem um alto índice de follow-up. Compartilhe sua abordagem eficaz com outros vendedores para melhorar a taxa de acompanhamento.",
    "Dado o padrão mensal de compras do cliente Coca-Cola, ofereça um programa de fidelidade para recompensar sua lealdade e incentivá-lo a continuar.",
    "O cliente Pepsi é ativo nas redes sociais. Engaje-se com ele nessas plataformas para fortalecer o relacionamento e entender suas necessidades.",
    "Após uma compra recente, sugira produtos complementares com base no histórico do cliente Samsung para melhorar sua experiência.",
    "Com um alto índice de conversão do cliente LG, compartilhe suas práticas bem-sucedidas com outros vendedores para aprimorar suas estratégias.",
    "Ao explorar opções de integração, explique como nossos produtos podem se integrar perfeitamente às necessidades do cliente Intel.",
    "Verifique se o problema técnico do cliente AMD foi resolvido após abrir um ticket de suporte e ofereça suporte contínuo para garantir sua satisfação.",
    "Dado o interesse do cliente HP no produto A, forneça materiais extras para auxiliar em sua tomada de decisão e garantir sua confiança.",
    "O cliente Dell não teve interações registradas recentemente. Entre em contato para manter o relacionamento ativo e descobrir novas oportunidades.",
    "O cliente Cisco demonstrou interesse em treinamento personalizado. Planeje uma sessão que atenda às suas necessidades, melhorando ainda mais sua experiência.",
    "Com base na preferência por produtos da categoria B do cliente Intel, sugira um produto complementar que agregue valor à sua compra.",
    "Após uma grande compra no trimestre passado, convide o cliente Salesforce para discutir os resultados e planejar os próximos passos para atender suas metas.",
    "Certifique-se de que o problema mencionado no recente ticket de suporte do cliente Oracle tenha sido resolvido e ofereça suporte contínio"
];

module.exports = insights;