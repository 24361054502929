import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
       
        ...GeneralProperties.documentation,

        // text              :{
        //     hintTitle: "",
        //     hintText:""
        // },
                    
        // active                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // activeClass           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // append                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // disabled              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // exact                 :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // exactActiveClass      :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // exactPath             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // href                  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // linkClass             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // noPrefetch            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // prefetch              :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // rel                   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // replace               :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // routerComponentName   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // target                :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // to                    :{
        //     hintTitle: "",
        //     hintText:""
        // },
        variant                 : {
            hintTitle:"Variant", 
            hintText:`
                - <b style="color:#007bff!important" >Primary</b>
                    <br>
                - <b style="color:#6c757d!important" >Secondary</b>
                    <br>
                - <b style="color:#28a745!important" >Success</b>
                    <br>
                - <b style="color:#dc3545!important" >Danger</b>
                    <br>
                - <b style="color:#f5f5fa!important" >Light</b>
                    <br>
                - <b style="color:#343a40!important" >Dark</b>
            `
        },

        // click             :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // clickPromise      :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}