import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {
        ...GeneralProperties.documentation,

        // activeNavItemClass  :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // activeTabClass      :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // align               :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // card                :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // contentClass        :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // end                 :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // fill                :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // justified           :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // lazy                :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // navClass            :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // navWrapperClass     :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // noFade              :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // noKeyNav            :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // noNavStyle          :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // pills               :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // small               :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // tag                 :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // value               :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // vertical            :{
        //     hintTitle: "",
        //     hintText:""
        // },    

        // changed             :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // activateTab         :{
        //     hintTitle: "",
        //     hintText:""
        // },    
        // input               :{
        //     hintTitle: "",
        //     hintText:""
        // },    
    }
}