import GeneralProperties from '../../core/ComponentDocs'

export default {
    documentation : {

        ...GeneralProperties.documentation,
            
        // label           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // hint            :{
        //     hintTitle: "",
        //     hintText:""
        // },
          
        // ariaLabel       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // ariaLabelledby  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // autofocus       :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // button          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // buttonVariant   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // checked         :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // disabled        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // form            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // indeterminate   :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // inline          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // name            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // plain           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // required        :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // size            :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // state           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // uncheckedValue  :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // value           :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // change          :{
        //     hintTitle: "",
        //     hintText:""
        // },
        // input           :{
        //     hintTitle: "",
        //     hintText:""
        // },
    }
}