<template>
    <b-row>
        <MotorS
        :cp="cp"
        :childrens="childrens"
        :idTela="idTela"
        :state="{...state}"
        />
    </b-row>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';

export default {
    name: 'ClicTableColumnCustom',
    mixins: [ComponentMixin],

}
</script>