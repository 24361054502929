import Vue from 'vue';
import { io } from 'socket.io-client';
import TokenService from "./tokenService";

class SocketTenantService {
  socketTenant;
  subdomain = Vue.prototype.$host.subdominio;

  constructor() {
    this.connectTenant();
  }

  connectTenant() {
    try {
      const userData = TokenService.getUser();
      const token = userData && userData.accessToken;
      this.socketTenant = io(`${process.env.VUE_APP_SOCKET_ENDPOINT}/${this.subdomain}`,
        {
          path: `/socket/server/`,
          auth: {
            token
          }
        }
      );
    } catch(e) {
      console.error(`[socket] falha ao criar e configurar a conexão tenant. Detalhes: `, e);
    } 
  }
  
}

export default new SocketTenantService();