
<template>
    <b-col>
        <div
            class="h-100 mt-5 d-flex align-items-center justify-content-center"
            v-if="!model.children"
        >
            <p class="aviso-subdominio">
                Nenhum registro foi encontrado
            </p>
        </div>

        <vue-tree-list
            :model="model"
            style="overflow: auto;"
            @click="click"
            @change-name="changeName"
            @delete-node="deleteNode"
            @add-node="addNode"
        >
            <template v-slot:leafNameDisplay="slotProps">
                <b-dropdown variant="transparent" dropright size="sm">
                    <template #button-content>
                        <ClicIconInterno 
                        iconName="md-morevert-twotone"
                        iconScale=".9"
                        iconTitle="Opções"
                        />
                    </template>
                <b-dropdown-item-button
                    class="text-left"
                    @click="deleteNode(slotProps.model)"
                    >
                    <b>Remover</b>
                    </b-dropdown-item-button>
                </b-dropdown>
                
                <span>{{ slotProps.model.name }}</span>
            </template>
            <span class="icon" slot="addTreeNodeIcon"></span>
            <span class="icon" slot="addLeafNodeIcon"></span>
            <span class="icon" slot="editNodeIcon"></span>
            <span class="icon" slot="delNodeIcon"></span>
            <span class="icon" slot="leafNodeIcon"></span>
            <span class="icon" slot="treeNodeIcon"></span>
        </vue-tree-list>

    </b-col>

</template>


<script>

import {VueTreeList, Tree} from 'vue-tree-list';

export default {
    name: 'TreeView',
    components:{
        'vue-tree-list' : VueTreeList
    },

    props: {
        axios: Object,
        atributeLabel: String,
        atributeChildren: String,
        atributeId: String,
        subdominio: String
    },

    data() {
        return {
            model : new Tree([]),
            parametrosConsulta : {},

            selectedNode : undefined,

            timeoutId : null,

            modalConfirmRemove: false
        }
    },
    
    created() {
        if  (!this.atributeLabel) {
            console.error(`[TreeView - Criador de telas]  não carregado. Verifique se a prop 'atributoLabel' foi informada`);
        };

        if  (!this.atributeChildren) {
            console.error(`[TreeView - Criador de telas]  não carregado. Verifique se a prop 'atributeChildren' foi informada`);
        };

        if  (!this.atributeId) {
            console.error(`[TreeView - Criador de telas]  não carregado. Verifique se a prop 'atributeId' foi informada`);
        };
    },

       
    mounted() {
        this.atualizarConsulta(this.axios);
    },


    methods: {

        getSelectedNode() {
            return this.selectedNode;
        },

        atualizarConsulta(parametros) {
            const parametrosAxios = parametros ? parametros : this.axios
            this.$axios(parametrosAxios).then((response) => {
                this.model = new Tree(this.converterJsonLista( this.lodash.get(response, 'data.dados.scripts', [])));
            });
        },

        converterJsonLista(listaDados) {
            let lista = [];
            for(let dado of listaDados){
                if  (dado[this.atributeLabel]) {
                    
                    if  (this.atributeId) {
                        dado.id = dado[this.atributeId];
                        dado.dragDisabled = true; // Dragable desativado
                    } else {
                        dado.id = this.$utils.getComponentID();
                    }
                    
                    dado.name = dado[this.atributeLabel];

                    if  (dado[this.atributeChildren]){
                        dado.children = this.converterJsonLista(dado[this.atributeChildren]);
                    }

                    if  (this.atributeChildren &&  (this.atributeChildren.toLowerCase() != 'children')) {
                        delete dado[this.atributeChildren];
                    }
                   
                    lista.push(dado);
                }
            };
            return lista;
        },

        deleteNode(node) {
            const h = this.$createElement
            const messageVNode = h('div', { class: ['foobar'] }, [
                h('p', { class: ['text-left'] }, [
                    'Deseja remover o script ',h('b', `${node.id}?`)
                    
                ])
            ]);
            this.$bvModal.msgBoxConfirm(messageVNode,{
                    title: 'Confirmar Exclusão',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            )
            .then(confirm => {
                if(confirm){
                    node.remove();
                    this.$emit('changedSimpleTreeValues',this.model); 
                };
            });  
        },

        changeName(params) {
            
        },

        addNode(params) {
            
        },

        click(node) {
            if  (!this.timeoutId) {
                this.timeoutId = setTimeout(() => {
                    this.selectedNode = node;
                    node.toggle();
                    this.timeoutId = null;
                    this.$emit('clickSimpleTree',this.selectedNode); 
                }, 200);
            } else {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
                this.selectedNode = node;
                node.toggle();
                this.$emit('doubleClickSimpleTree',this.selectedNode); 
            }
        },
        
    },
}

</script>

<style>
    .vtl-node-main {   
        font-weight: 400;
        font-size:14px
    }

    .vtl-node-main:hover {   
        cursor: pointer;
        font-weight: 600;
    }
</style>

