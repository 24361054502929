import ClicColInterno from '../clicGridLayout/ClicColInternoModel'

import ClicModalDocs from './ClicModalDocs'

export default {
    ClicModal : {
        config      : {isContainer:true, icon: "md-smartscreen-outlined", documentation: ClicModalDocs.documentation}, 
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {

            modalBody                   : {type: 'array',       default: [],            component: { name : 'code' } },
            modalFooter                 : {type: 'array',       default: [],            component: { name : 'code' } },

            ariaLabel                   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            autoFocusButton             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            bodyBgVariant               : {type: 'string',      default: undefined,     component: { name : 'input' } },
            bodyClass                   : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            bodyTextVariant             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            busy                        : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            buttonSize                  : {type: 'string',      default: undefined,     component: { name : 'input' } },
            cancelDisabled              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            cancelTitle                 : {type: 'string',      default: 'Cancelar',    component: { name : 'input' } },
            cancelTitleHtml             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            cancelVariant               : {type: 'string',      default: undefined,     component: { name : 'input' } },
            centered                    : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            contentClass                : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            dialogClass                 : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            footerBgVariant             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerBorderVariant         : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerClass                 : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            footerTag                   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            footerTextVariant           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerBgVariant             : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerBorderVariant         : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerClass                 : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            headerCloseContent          : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerCloseLabel            : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerCloseVariant          : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerTag                   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            headerTextVariant           : {type: 'string',      default: undefined,     component: { name : 'input' } },
            hideBackdrop                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            hideFooter                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            hideHeader                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            hideHeaderClose             : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            ignoreEnforceFocusSelector  : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            lazy                        : {type: 'boolean',     default: undefined,     component: { name : 'input' } },
            modalClass                  : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            noCloseOnBackdrop           : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            noCloseOnEsc                : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            noEnforceFocus              : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            noFade                      : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            noStacking                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            okDisabled                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            okOnly                      : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            okTitle                     : {type: 'string',      default: undefined,     component: { name : 'input' } },
            okTitleHtml                 : {type: 'string',      default: undefined,     component: { name : 'input' } },
            okVariant                   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            returnFocus                 : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            scrollable                  : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            size                        : {type: 'string',      default: undefined,     component: { name : 'input' } },
            static                      : {type: 'boolean',     default: true,          component: { name : 'switch' } },
            title                       : {type: 'string',      default: undefined,     component: { name : 'input' } },
            titleClass                  : {type: 'multiple',    default: undefined,     component: { name : 'input' } },
            titleHtml                   : {type: 'string',      default: undefined,     component: { name : 'input' } },
            titleSrOnly                 : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
            titleTag                    : {type: 'string',      default: undefined,     component: { name : 'input' } },
            visible                     : {type: 'boolean',     default: undefined,     component: { name : 'switch' } },
  
        },
        events : {
            onShow  : {type: 'string', component: { name : 'code' } },
            onHide  : {type: 'string', component: { name : 'code' } },
        },
    }
}